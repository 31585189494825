import {
  GL_COLORS,
  GLBox,
  GLFilterByDeviceGroup,
  GLModal,
  GLSelectMultiple,
  GLTypography,
} from '@group-link-one/grouplink-components';
import React from 'react';

import { alertsMap } from '../../../DeviceList/ModalDeviceDetails/TitleContent/useDeviceDetailsTitleContent';
import { useModalFilter } from './useModalFilter';

export function ModalFilter(): JSX.Element {
  const {
    features,
    t,
    healthCheckActions,
    healthCheckState,
    userGroupsFormatted,
    groupsChildrensAvailables,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
  } = useModalFilter();

  return (
    <GLModal
      open={healthCheckState.modalFilterIsOpen}
      type="create"
      title={t('deviceList.search.filterOptions.title')}
      position="right"
      description={t('deviceList.search.filterOptions.description')}
      hasButtonClose={false}
      hasOutsideClick={true}
      modalContentStyle={{
        justifyContent: 'flex-start',
      }}
      content={
        <GLBox direction="column" gap={15}>
          <GLBox
            height={2}
            width="100%"
            borderRadius={8}
            style={{
              background: GL_COLORS.BORDER_COLOR,
            }}
          />

          {features.healthCheck.filters.byAlert && (
            <GLBox direction="column">
              <GLTypography text="Devices" weight={600} />

              <GLSelectMultiple
                options={[
                  { id: 'all', text: 'Todos' },
                  {
                    id: 'with-alert',
                    text: 'Com alerta',
                    content: (
                      <GLSelectMultiple
                        hasInput={false}
                        canSelectMultiple={true}
                        options={Object.entries(alertsMap).map(
                          ([key, value]) => ({
                            id: key,
                            text: value,
                          }),
                        )}
                        optionsSelected={
                          healthCheckState.alertTypesSelected || []
                        }
                        boxOptions={{
                          width: 200,
                          position: 'bottom-right',
                        }}
                        onSelectedOptionsChange={(selectedOptions) => {
                          healthCheckActions.setAlertFilterValue([
                            {
                              id: 'with-alert',
                              text: 'Com alerta',
                            },
                          ]);

                          healthCheckActions.setAlertTypesSelected(
                            selectedOptions,
                          );
                        }}
                      />
                    ),
                  },
                  { id: 'without-alert', text: 'Sem alerta' },
                ]}
                optionsSelected={healthCheckState.alertFilterValue || []}
                rootOptions={{
                  style: {
                    minWidth: '100%',
                  },
                }}
                inputOptions={{
                  placeholder: 'Filtrar por alerta',
                }}
                boxOptions={{
                  width: '100%',
                  position: 'bottom-right',
                }}
                canSelectMultiple={false}
                hasCheckbox={false}
                isAsynchronous
                onSelectedOptionsChange={(selectedOptions) => {
                  if (!selectedOptions || selectedOptions?.length === 0) {
                    healthCheckActions.setAlertFilterValue([
                      { id: 'all', text: 'Todos' },
                    ]);
                    return;
                  }

                  const isAllOrWithoutAlert = selectedOptions.some(
                    (option) =>
                      option.id === 'all' || option.id === 'without-alert',
                  );
                  if (!isAllOrWithoutAlert) {
                    return;
                  }

                  healthCheckActions.setAlertTypesSelected([]);
                  healthCheckActions.setAlertFilterValue(selectedOptions);
                }}
              />

              {/*<GLSelectMultiple
                options={[
                  { id: "all", text: "Todos" },
                  {
                    id: "with-alert",
                    text: "Com Leitura",
                    content: (
                      <GLSelectMultiple
                        hasInput={false}
                        canSelectMultiple={false}
                        options={Object.entries(alertsMap).map(
                          ([key, value]) => ({
                            id: key,
                            text: value,
                          })
                        )}
                        optionsSelected={
                          healthCheckState.alertTypesSelected || []
                        }
                        boxOptions={{
                          width: 200,
                          position: "bottom-right",
                        }}
                        onSelectedOptionsChange={(selectedOptions) => {
                          if (
                            selectedOptions.length === 0 &&
                            (!healthCheckState?.alertTypesSelected ||
                              healthCheckState?.alertTypesSelected?.length === 0)
                          )
                            return;

                          healthCheckActions.setAlertTypesSelected(
                            selectedOptions
                          );
                          healthCheckActions.setAlertFilterValue(selectedOptions);
                        }}
                      />
                    ),
                  },
                  { id: "without-alert", text: "Sem leitura" },
                ]}
                optionsSelected={healthCheckState.alertFilterValue || []}
                rootOptions={{
                  style: {
                    minWidth: "100%",
                  },
                }}
                inputOptions={{
                  placeholder: "Filtrar por alerta",
                }}
                boxOptions={{
                  width: "100%",
                  position: "bottom-right",
                }}
                canSelectMultiple={false}
                hasCheckbox={false}
                isAsynchronous
                onSelectedOptionsChange={(selectedOptions) => {
                  if (selectedOptions.length === 0) {
                    healthCheckActions.setAlertFilterValue([
                      { id: "all", text: "Todos" },
                    ]);
                    return;
                  }

                  const isAllOrWithoutAlert = selectedOptions.some(
                    (option) =>
                      option.id === "all" || option.id === "without-alert"
                  );
                  if (!isAllOrWithoutAlert) return;

                  healthCheckActions.setAlertTypesSelected([]);
                  healthCheckActions.setAlertFilterValue(selectedOptions);
                }}
              /> */}
            </GLBox>
          )}

          {features.healthCheck.filters.byGroups && (
            <GLFilterByDeviceGroup
              userGroups={userGroupsFormatted}
              userGroupsChildren={groupsChildrensAvailables}
              onSelectUserGroup={onSelectUserGroup}
            />
          )}

          {features.healthCheck.filters.byTags && tagOptionsFormatted && tagOptionsFormatted?.length > 0 && (
            <GLBox direction="column">
              <GLSelectMultiple
                options={tagOptionsFormatted}
                optionsSelected={[]}
                canSelectMultiple={false}
                maxCharacters={20}
                hasCheckbox={false}
                hasSearch
                onSelectedOptionsChange={(selectedOptions) => {
                  if (onSelectTag && !!selectedOptions) onSelectTag(selectedOptions);
                }}
                labelOptions={{
                  text: t('deviceList.search.filterByTag'),
                }}
                inputOptions={{
                  width: '100%',
                  layout: 'size',
                  placeholder: t('common.select'),
                }}
                rootOptions={{
                  width: '100%',
                }}
              />
            </GLBox>
          )}

          {features.healthCheck.filters.byTags && tagValuesFormatted && tagValuesFormatted?.length > 0 && (
            <GLSelectMultiple
            options={tagValuesFormatted}
            optionsSelected={[]}
            canSelectMultiple={false}
            maxCharacters={20}
            hasCheckbox={false}
            hasSearch
            onSelectedOptionsChange={(selectedOptions) => {
              if (onSelectTagValue && !!selectedOptions) onSelectTagValue(selectedOptions);
            }}
            labelOptions={{
              text: t('deviceList.search.filterByTagValue'),
            }}
            inputOptions={{
              width: '100%',
              layout: 'size',
              placeholder: t('common.select'),
            }}
            rootOptions={{
              width: '100%',
            }}
          />
          )}
        </GLBox>
      }
      onClose={() => {
        healthCheckActions.setModalFilterIsOpen(false);
      }}
    />
  );
}
