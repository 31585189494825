import {
  GL_COLORS,
  GLBarChartDataProps,
  useI18n,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';

import { GetDevicesActivatedDailyReadingsRow } from '../../../../../Services/deviceListService/useDeviceListService.types';
import { convertDailyReading } from '../../../../../utils/convertDailyReading';
import {
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../../../store/details-device-store';
import { getOptionsByApplication } from '../../../utils/getOptionsByApplication';
import { useGetDailyReadings } from '../useGetDailyReadings';

type Bars = {
  fill: string;
  dataKey: string;
  name: string;
};

type Legends = {
  id: string;
  value: string;
  type: string;
  color: string;
};

interface IUseDailyConsumption {
  detailsDeviceState: DetailsDeviceStoreState;
  data: GLBarChartDataProps[];
  bars: Bars[];
  average: number;
  legends: Legends[];
  isLoading: boolean;
}

export const useDailyConsumption = (): IUseDailyConsumption => {
  const [data, setData] = useState<GLBarChartDataProps[]>([]);
  const [average, setAverage] = useState<number>(0);
  const { t } = useI18n();

  const { state: detailsDeviceState } = useDetailsDeviceStore();
  const { getAverage, formatReadings } = useGetDailyReadings();

  const bars = [
    {
      fill: GL_COLORS.PURPLE,
      dataKey: 'x',
      name: 'Daily consumption',
    },
  ];

  const legends = [
    {
      id: 'daily-consumption-history',
      value: t('deviceList.detailsModal.ut.graphs.two.legends.daily'),
      type: 'line',
      color: GL_COLORS.PURPLE,
    },
    {
      id: 'no-consumption-history',
      value: t('deviceList.detailsModal.ut.graphs.two.legends.noData'),
      type: 'line',
      color: GL_COLORS.DISABLED_FONT_COLOR,
    },
  ];

  if (average > 0) {
    legends.push({
      id: 'average-consumption-history',
      value: t('deviceList.detailsModal.ut.graphs.two.legends.average'),
      color: GL_COLORS.DANGER,
      type: 'line',
    });
  }

  function formatData(
    readings?: GetDevicesActivatedDailyReadingsRow[],
  ): never[] | void {
    if (
      !readings ||
      !detailsDeviceState.currentData ||
      !detailsDeviceState.range.from ||
      !detailsDeviceState.range.to
    ) {
      return [];
    }

    const { readingsFormatted, arrayDate } = formatReadings(readings);

    const dataInDailyReading = convertDailyReading(readingsFormatted);

    const formattedData: GLBarChartDataProps[] = arrayDate.map((reading) => {
      const hasDateIntersection = dataInDailyReading.find(
        (dailyReadingData) =>
          format(new Date(dailyReadingData.time), 'MM/dd/yyyy') ===
          reading.label,
      );

      if (hasDateIntersection) {
        return {
          x: hasDateIntersection.reading,
          label: format(new Date(reading.label), 'MM/dd/yyyy'),
          unit: getOptionsByApplication({
            type: detailsDeviceState.device?.application,
          }).unit,
          type: hasDateIntersection.reading === 0 ? 'disabled' : 'active',
        };
      } else {
        return {
          x: 0,
          label: format(new Date(reading.label), 'MM/dd/yyyy'),
          unit: getOptionsByApplication({
            type: detailsDeviceState.device?.application,
          }).unit,
          type: 'disabled',
        };
      }
    });

    setAverage(getAverage(formattedData));
    setData(formattedData);
  }

  useEffect(() => {
    formatData(detailsDeviceState.currentData);
  }, [detailsDeviceState.currentData]);

  return {
    detailsDeviceState,
    data,
    bars,
    average,
    legends,
    isLoading: detailsDeviceState.isLoading,
  };
};
