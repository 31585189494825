import { GLBox, GLButton, GLModal } from '@group-link-one/grouplink-components';
import { useModalUnassignTag } from './useModalUnassignTag';

export const ModalUnassignTag = (): JSX.Element => {
  const { deviceListActions, deviceListState, t, onUnassignTag } =
    useModalUnassignTag();

  return (
    <GLModal
      title={t('deviceList.detailsModal.sr.modalEdit.sure')}
      description={t(
        'deviceList.detailsModal.tags.modal.removingTag.description',
      )}
      type="remove"
      hasButtonClose
      hasOutsideClick
      open={deviceListState.modalUnassignTagIsOpen}
      content={
        <>
          <GLBox>
            <GLButton
              text={t('common.cancel')}
              variant="outline"
              size="primary"
              align="center"
              weight={600}
              border
              onClick={() => deviceListActions.setModalUnassignTagIsOpen(false)}
            />

            <GLButton
              text={t('common.yes') + ', ' + t('common.delete')}
              variant="danger_fill"
              weight={600}
              size="primary"
              align="center"
              border={false}
              //isLoading={deviceListState.isDecomissionLoading}
              //disabled={deviceListState.isDecomissionLoading}
              onClick={onUnassignTag}
            />
          </GLBox>
        </>
      }
      onClose={() => deviceListActions.setModalUnassignTagIsOpen(false)}
    />
  );
};
