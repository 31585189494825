import { useI18n } from '@group-link-one/grouplink-components';

export interface useTagsProps {
  t: (key: string) => string;
}

export const useTags = (): useTagsProps => {
  const { t } = useI18n();

  return {
    t,
  };
};
