import { EnergyCard } from './Energy/EnergyCard';
import { EventListCardsProps } from './EventListCards.types';
import { WaterCard } from './Water/WaterCard';

export function EventListCards({ events }: EventListCardsProps): JSX.Element {
  return (
    <>
      {events?.map((event, index) => {
        if ('directEnergy' in event) {
          return (
            <EnergyCard
              key={event.eventCreated + index + event.cfray + Math.random()}
              event={event}
            />
          );
        }

        if ('liters' in event) {
          return (
            <WaterCard
              key={event.eventCreated + index + event.cfray + Math.random()}
              event={event}
            />
          );
        }
      })}
    </>
  );
}
