import { useI18n } from '@group-link-one/grouplink-components';
import {
  DetailsDeviceStoreActions,
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../store/details-device-store';

interface ModalAddTag {
  t: (key: string) => string;
  deviceListState: DetailsDeviceStoreState;
  deviceListActions: DetailsDeviceStoreActions;
}

export const useModalAddTag = (): ModalAddTag => {
  const { t } = useI18n();
  const { state: deviceListState, actions: deviceListActions } =
    useDetailsDeviceStore();

  return {
    deviceListState,
    deviceListActions,
    t,
  };
};
