import { useI18n } from '@group-link-one/grouplink-components';

interface IUseEnergyColumns {
  columnName: string;
  hasActions?: boolean;
}

export const useEnergyColumns = (): IUseEnergyColumns[] => {
  const { t } = useI18n();

  return [
    {
      columnName: t('eventList.columns.created_at'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.sent'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.latency'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.device_id'),
    },
    {
      columnName: t('eventList.columns.direct_energy'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.reverse_energy'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.serial_number'),
      hasActions: false,
    },
  ];
};
