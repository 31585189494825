import { GLPaginationProvider } from '@group-link-one/grouplink-components';

// import { PageAnimated } from "../../utils/pageAnimated";
import { DeviceListBody } from './DeviceListBody';
import { useDeviceList } from './useDeviceList';

export function DeviceList(): JSX.Element {
  const { lastPageToken, tabs } = useDeviceList();

  return (
    <GLPaginationProvider
      lastNextPageToken={lastPageToken}
      tabs={tabs}
      isInfiniteScroll
    >
      <DeviceListBody />
    </GLPaginationProvider>
  );
}
