import { TabProps, useI18n } from '@group-link-one/grouplink-components';

interface IUseGroupList {
  tabs: TabProps[];
  lastPageToken: {
    'all-devices': {
      token: string | undefined;
      page: number;
    };
    'gas-devices': {
      token: string | undefined;
      page: number;
    };
    'water-devices': {
      token: string | undefined;
      page: number;
    };
    'pending-devices': {
      token: string | undefined;
      page: number;
    };
  };
}

export const useGroupList = (): IUseGroupList => {
  const { t } = useI18n();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: true,
      cacheKey: 'all-devices',
      count: undefined,
      name: t('groupList.tabs.ut.one'),
      onClick: () => {},
    },
    {
      id: 2,
      active: false,
      cacheKey: 'pending-devices',
      count: undefined,
      name: t('groupList.tabs.ut.two'),
      onClick: () => {},
    },
  ];

  const lastPageToken = {
    'all-devices': {
      token: undefined,
      page: 0,
    },
    'gas-devices': {
      token: undefined,
      page: 0,
    },
    'water-devices': {
      token: undefined,
      page: 0,
    },
    'pending-devices': {
      token: undefined,
      page: 0,
    },
  };

  return {
    tabs,
    lastPageToken,
  };
};
