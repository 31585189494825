import { useState } from 'react';
import {
  Session,
  useLoginSecurityServices,
} from '../../../Services/loginSecurityService/useLoginSecurityService';
import { useAuthServices } from '../../../Services/authService/useAuthServices';
import { useQuery } from '@tanstack/react-query';
import {
  ILoginSecurityData,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useAuth } from '@group-link-one/gl-utils';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { User } from '@group-link-one/gl-utils/dist/auth/types/user';

interface ILoginSecurity {
  generateCode: () => Promise<string>;
  updatePassword: ({
    password,
    code,
  }: Partial<ILoginSecurityData>) => Promise<void>;
  logOutDevice: (id: string) => Promise<void>;
  sessions: Session[];
  isLoading: boolean;
  sendCodeIsLoading: boolean;
  user: User | undefined;
}

export const useLoginSecurity = (): ILoginSecurity => {
  const { logEventAnalytics } = useFBAnalytics();
  const [token, setToken] = useState('');
  const { logOut } = useAuthServices();
  const { t } = useI18n();
  const { user } = useAuth();
  const { updatePasswordLogged, getUserHistory, sendCode } =
    useLoginSecurityServices();
  const { addToast } = useToast();
  const [sendCodeIsLoading, setSendCodeIsLoading] = useState(false);

  const generateCode = async (): Promise<string> => {
    setSendCodeIsLoading(true);
    const response = await sendCode({ email: user!.email });

    setSendCodeIsLoading(false);

    setToken(response.data);

    if (response.data) {
      addToast({
        title: t('loginAndSecurity.toast.email.title'),
        message: t('loginAndSecurity.toast.email.message'),
        type: 'success',
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.LOGIN_SECURITY_GENERATE_CODE,
        eventDescription: 'Start Login Security Code Generation',
      });
    }

    return response.data;
  };

  const updatePassword = async ({
    password,
    code,
  }: Partial<ILoginSecurityData>): Promise<void> => {
    return updatePasswordLogged({
      token,
      code: code!,
      password: password!,
    })
      .then((res) => {
        if (res.status === 200) {
          addToast({
            title: t('loginAndSecurity.toast.password.title'),
            message: t('loginAndSecurity.toast.password.message'),
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.LOGIN_SECURITY_UPDATE_PASSWORD,
            eventDescription: 'Update Password',
          });
        }
      })
      .catch(() => {
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });
      });
  };

  const { data: sessions = [], isLoading } = useQuery({
    queryKey: ['get-user-history'],
    queryFn: () => getUserHistory().then((res) => res.rows),
  });

  const logOutDevice = async (id: string): Promise<void> => {
    await logOut({ session_id: id });
  };

  return {
    generateCode,
    updatePassword,
    logOutDevice,
    sessions,
    isLoading,
    sendCodeIsLoading,
    user,
  };
};
