import { TabProps, useI18n } from '@group-link-one/grouplink-components';
import { useMemo } from 'react';

import { useGLFlags } from '@group-link-one/gl-utils';
import { usePrivileges } from '@/hooks/usePrivilleges';

type LastPageToken = {
  token: string | undefined;
  page: number;
};

interface IUseDeviceList {
  tabs: TabProps[];
  lastPageToken: {
    'all-devices': LastPageToken;
    'gas-devices': LastPageToken;
    'water-devices': LastPageToken;
    'pending-devices': LastPageToken;
  };
}

export const useDeviceList = (): IUseDeviceList => {
  const { t } = useI18n();
  const { flags } = useGLFlags();
  const { features } = usePrivileges();

  const tabs: TabProps[] = useMemo(() => {
    const Alltabs = [];

    if (features.deviceList.tabs.activatedDevices) {
      Alltabs.push({
        id: 1,
        active: true,
        cacheKey: 'all-devices',
        count: undefined,
        name: t('deviceList.tabs.ut.activated'),
        onClick: () => {},
      });
    }

    if (features.deviceList.tabs.withoutReadings) {
      Alltabs.push({
        id: 2,
        active: false,
        cacheKey: 'without-readings-devices',
        count: undefined,
        name: t('deviceList.tabs.ut.withoutReading'),
        onClick: () => {},
      });
    }

    if (features.deviceList.tabs.pendingDevices) {
      Alltabs.push({
        id: 3,
        active: false,
        cacheKey: 'pending-devices',
        count: undefined,
        name: t('deviceList.tabs.ut.pending'),
        onClick: () => {},
      });
    }

    return Alltabs;
  }, [flags]);

  const lastPageToken = {
    'all-devices': {
      token: undefined,
      page: 0,
    },
    'gas-devices': {
      token: undefined,
      page: 0,
    },
    'water-devices': {
      token: undefined,
      page: 0,
    },
    'pending-devices': {
      token: undefined,
      page: 0,
    },
  };

  return {
    tabs,
    lastPageToken,
  };
};
