import { GroupsDevicesStoreState, useGroupsDevicesStore } from '@group-link-one/grouplink-components';
import { parse } from 'csv-parse/browser/esm';
import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';

interface UseCsvTransfer {
  groupDevicesState: GroupsDevicesStoreState
  devicesID: number[];
  devicesIDChecked: number[];
  onDropCsv: (result: string) => void;
  onCheckDevice: (id: number, checked: boolean) => void;
}

export const useCsvTransfer = (): UseCsvTransfer => {
  const [devicesID, setDevicesID] = useState<number[]>([]);
  const [devicesIDChecked, setDevicesIDChecked] = useState<number[]>([]);

  const { state: groupDevicesState, actions: groupsDevicesActions } =
    useGroupsDevicesStore();

  function onDropCsv(result: string): void {
    if (
      typeof result === 'string' &&
      result.startsWith('data:text/csv;base64,')
    ) {
      // Remove o prefixo 'data:text/csv;base64,'
      const base64String = result.replace('data:text/csv;base64,', '');

      // Decodifica a string base64 para um ArrayBuffer
      const byteCharacters = atob(base64String);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Converte o ArrayBuffer para uma string de texto
      const decodedCsv = new TextDecoder().decode(byteArray);

      // Analisa o CSV
      parse(
        decodedCsv,
        { delimiter: ',', columns: false, trim: true },
        (err, data) => {
          if (err) {
            Sentry.captureException(err);
          } else {
            const dataWithoutDuplicated: number[] = Array.from(
              new Set(data.flat().map(Number)),
            );
            const dataFormatted: number[] = dataWithoutDuplicated
              .filter((deviceID) => !isNaN(deviceID))
              .filter((deviceID) => String(deviceID).length === 10);

            setDevicesID(dataFormatted);
            setDevicesIDChecked(dataFormatted);
          }
        },
      );
    } else {
      Sentry.captureException('Formato de arquivo não suportado.');
    }
  }

  function onCheckDevice(id: number, checked: boolean): void {
    if (checked) {
      setDevicesIDChecked([...devicesIDChecked, id]);

      return;
    }

    setDevicesIDChecked(devicesIDChecked.filter((deviceID) => deviceID !== id));
  }

  useEffect(() => {
    groupsDevicesActions.setDevicesIds(devicesIDChecked);
  }, [devicesIDChecked]);

  useEffect(() => {
    if (!groupDevicesState.openAddDevicesModal) {
      setDevicesID([]);
      setDevicesIDChecked([]);
    }
  }, [groupDevicesState.openAddDevicesModal])

  return {
    groupDevicesState,
    devicesID,
    devicesIDChecked,
    onDropCsv,
    onCheckDevice,
  };
};
