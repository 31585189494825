import { PaginateResponseProps } from '@group-link-one/grouplink-components';
import { IRequestParams } from '../../Pages/Audit/types/types';
import useAxiosHTTPRequest from '../useAxiosHTTPRequest';

interface IAuditService {
  getAudits: (
    params: IRequestParams,
  ) => Promise<PaginateResponseProps<Audit[]>>;
}

export interface Audit {
  id: number;
  customer_id: number;
  created_at: string;
  category: string;
  resource: string;
  log: Record<string, unknown>;
}

export const useAuditService = (): IAuditService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getAudits = async ({
    ...params
  }: IRequestParams): Promise<PaginateResponseProps<Audit[]>> => {
    const response = await httpPrivate.get('/customer-audit', {
      params,
    });

    return response.data;
  };

  return {
    getAudits,
  };
};
