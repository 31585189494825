import { GLSelectMultipleOptions } from '@group-link-one/grouplink-components';
import { create } from 'zustand';
import { useCasesMap } from '../../DeviceList/useDeviceListBody';

export interface ImpersonateContentState {
  organizationId: string | number;
  textToSearchOrg: string;
  orgErrorMessage: string;

  textToSearchUser: string;
  userId: string | number;
  userErrorMessage: string;

  useCaseOptionsAvailables: GLSelectMultipleOptions[] | undefined;
  useCaseOptionsSelecteds: GLSelectMultipleOptions[] | undefined;
  useCasePreSelected: GLSelectMultipleOptions[] | undefined;

  rootToken: string;
}

export interface ImpersonateContentActions {
  setOrganizationId: (organizationId: string | number) => void;
  setOrganizationTextToSearch: (text: string) => void;
  setOrgErrorMessage: (errorMessage: string) => void;

  setUseCaseOptions: (
    useCaseOptions: GLSelectMultipleOptions[] | undefined,
  ) => void;
  setUseCaseOptionsSelecteds: (
    useCaseOptionsSelecteds: GLSelectMultipleOptions[] | undefined,
  ) => void;

  setUseCasePreSelected: (
    useCasePreSelected: GLSelectMultipleOptions[] | undefined,
  ) => void;

  setUserTextToSearch: (text: string) => void;
  setUserId: (userId: string | number) => void;
  setUserErrorMessage: (errorMessage: string) => void;

  setRootToken: (rootToken: string) => void;

  resetAll: () => void;
}

interface ImpersonateStore {
  state: ImpersonateContentState;
  actions: ImpersonateContentActions;
}

export const useImpersonateContentStore = create<ImpersonateStore>((set) => ({
  state: {
    organizationId: 0,
    textToSearchOrg: '',
    orgErrorMessage: '',

    userId: '',
    userErrorMessage: '',
    textToSearchUser: '',

    useCaseOptionsAvailables: undefined,
    useCaseOptionsSelecteds: undefined,
    useCasePreSelected: [
      useCasesMap.find((useCase) => useCase.id === 'water')!,
    ],

    rootToken: '',
  },
  actions: {
    setOrganizationId: (organizationId: string | number) => {
      set((state) => ({
        state: {
          ...state.state,
          organizationId: organizationId,
        },
      }));
    },

    setUseCaseOptions: (useCaseOptionsAvailables) =>
      set((state) => ({
        state: {
          ...state.state,
          useCaseOptionsAvailables,
        },
      })),

    setUseCaseOptionsSelecteds: (useCaseOptionsSelecteds) =>
      set((state) => ({
        state: {
          ...state.state,
          useCaseOptionsSelecteds,
        },
      })),

    setUseCasePreSelected: (useCasePreSelected) =>
      set((state) => ({
        state: {
          ...state.state,
          useCasePreSelected,
        },
      })),

    setOrganizationTextToSearch: (text: string) => {
      set((state) => ({
        state: {
          ...state.state,
          textToSearchOrg: text,
        },
      }));
    },

    setUserTextToSearch: (text: string) => {
      set((state) => ({
        state: {
          ...state.state,
          textToSearchUser: text,
        },
      }));
    },

    setUserId: (userId: string | number) => {
      set((state) => ({
        state: {
          ...state.state,
          userId: userId,
        },
      }));
    },

    setRootToken: (rootToken: string) => {
      set((state) => ({
        state: {
          ...state.state,
          rootToken: rootToken,
        },
      }));
    },

    setOrgErrorMessage: (errorMessage: string) => {
      set((state) => ({
        state: {
          ...state.state,
          orgErrorMessage: errorMessage,
        },
      }));
    },

    setUserErrorMessage: (errorMessage: string) => {
      set((state) => ({
        state: {
          ...state.state,
          userErrorMessage: errorMessage,
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          organizationId: 0,
          textToSearchOrg: '',

          userId: 0,
        },
      }));
    },
  },
}));
