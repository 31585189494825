import { useRegisterDeviceStore } from '@group-link-one/grouplink-components';
import { useMediaQuery } from 'usehooks-ts';
import { useExtractURLSearch } from '../../../hooks/useExtractURLSearch';
import { Features, usePrivileges } from '../../../hooks/usePrivilleges';
import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import { GetDevicesActivatedLastReadingsResponse } from '../../../Services/deviceListService/useDeviceListService.types';
import { useDetailsDeviceStore } from '../store/details-device-store';
import { useDeviceListStore } from '../store/device-list-store';

import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';

interface IUseDeviceListCard {
  features: Features;
  isMobile: boolean;
  onAddressClick: (lat: number, lng: number) => void;
  onDetailsClick: (
    item: GetDevicesActivatedLastReadingsResponse,
  ) => Promise<void>;
  onEditClick: (item: GetDevicesActivatedLastReadingsResponse) => Promise<void>;
  onRegisterDeviceClick: () => void;
  onClickDecomission: (item: GetDevicesActivatedLastReadingsResponse) => void;
}

export const useDeviceListCard = (): IUseDeviceListCard => {
  const { logEventAnalytics } = useFBAnalytics();
  const { getDeviceInfo } = useDeviceListService();
  const { actions: detailsDeviceActions } = useDetailsDeviceStore();
  const { actions: registerDeviceActions } = useRegisterDeviceStore();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const { setURLParams } = useExtractURLSearch();
  const { actions: deviceListActions } = useDeviceListStore();
  const { features } = usePrivileges();

  function onAddressClick(lat: number, lng: number): void {
    const googleMapsUrl = `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
  }

  async function onDetailsClick(
    item: GetDevicesActivatedLastReadingsResponse,
  ): Promise<void> {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_SEE_DETAILS,
      eventDescription: 'Start See Details Device',
    });
    const response = await getDeviceInfo({
      device_id: item.device_id,
    });

    detailsDeviceActions.setDevice({
      ...item,
      tags: response?.tags,
    });
    detailsDeviceActions.setModalIsOpen(true);

    setURLParams({
      q: String(item.device_id),
    });
  }

  async function onEditClick(
    item: GetDevicesActivatedLastReadingsResponse,
  ): Promise<void> {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_EDIT,
      eventDescription: 'Start Edit Device',
    });

    deviceListActions.setModalType('edit');

    const response = await getDeviceInfo({ device_id: item.device_id });

    registerDeviceActions.setDeviceId(response.device_id);
    registerDeviceActions.setDeviceName(item?.channels[0]?.name || '');
    registerDeviceActions.setRemoteId(item?.channels[0]?.remote_id || '');
    registerDeviceActions.setDeviceMeasurementCategory(
      item.meta.device_measurement_category,
    );
    registerDeviceActions.setAddressSelectedInfo({
      address:
        response.gps_location.address ||
        response.gps_location.display_address ||
        '',
      lat: response.gps_location.lat,
      lng: response.gps_location.long,
      cep: response.gps_location.zipcode,
      country: response.gps_location.country || '',
      city: response.gps_location.city || '',
      state: response.gps_location.state || '',
      number: response.gps_location.number || '',
    });
  }

  function onRegisterDeviceClick(): void {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_REGISTER,
      eventDescription: 'Start Register Device',
    });
    deviceListActions.setModalType('register');
  }

  function onClickDecomission(
    item: GetDevicesActivatedLastReadingsResponse,
  ): void {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.DEVICE_LIST_DECOMISSION,
      eventDescription: 'Start Decomission Device',
    });
    deviceListActions.setDeviceId(item.device_id);
    deviceListActions.setModalDecomissionIsOpen(true);
  }

  return {
    features,
    isMobile,
    onAddressClick,
    onDetailsClick,
    onEditClick,
    onRegisterDeviceClick,
    onClickDecomission,
  };
};
