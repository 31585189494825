import {
  IGroupsDevice,
  PaginateResponseProps,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import {
  GetOrgDeviceGroupIdResponse,
  IBatchCreateGroup,
  ICreateGroup,
  IEditGroups,
  IGroupListService,
  OrgDeviceGroupPostBatchResponse,
  OrgDeviceGroupPostResponse,
} from './useGroupListService.types';

export const useGroupListService = (): IGroupListService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getOrgDeviceGroup = async (): Promise<
    PaginateResponseProps<IGroupsDevice[]>
  > => {
    const response = await httpPrivate.get('/org-device-group');
    return response.data;
  };

  const getOrgDeviceGroupId = async (
    id: number,
  ): Promise<GetOrgDeviceGroupIdResponse> => {
    const response = await httpPrivate.get(`/org-device-group/${id}`);
    return response.data;
  };

  const patchOrgDeviceGroupId = async ({
    id,
    data,
  }: {
    id: number;
    data: Partial<IEditGroups>;
  }): Promise<void> => {
    const response = await httpPrivate.patch(`/org-device-group/${id}`, data);
    return response.data;
  };

  const deleteOrgDeviceGroupId = async (id: number): Promise<void> => {
    const response = await httpPrivate.delete(`/org-device-group/${id}`);
    return response.data;
  };

  const postOrgDeviceGroup = async (
    data: Partial<ICreateGroup>,
  ): Promise<OrgDeviceGroupPostResponse> => {
    const response = await httpPrivate.post('/org-device-group', data);
    return response.data;
  };

  const postOrgDeviceGroupBatch = async (
    params: IBatchCreateGroup,
  ): Promise<OrgDeviceGroupPostBatchResponse> => {
    const response = await httpPrivate.post('/org-device-group/batch', params);
    return response.data;
  };

  return {
    getOrgDeviceGroup,
    getOrgDeviceGroupId,
    patchOrgDeviceGroupId,
    deleteOrgDeviceGroupId,
    postOrgDeviceGroup,
    postOrgDeviceGroupBatch,
  };
};
