import { IForgotData, useI18n } from '@group-link-one/grouplink-components';
import { useToast } from '@group-link-one/grouplink-components';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { useForgotPasswordService } from '../../../Services/forgotPasswordService/useForgotServices';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

interface IUseForgotPassword {
  sendForm: ({ email }: IForgotData) => Promise<void>;
  redirectToLogin: () => void;
  disabled: boolean;
}

export const useForgotPassword = (): IUseForgotPassword => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { sendResetPasswordLink } = useForgotPasswordService();
  const { addToast } = useToast();
  const [disabled] = useState(false);
  const navigate = useNavigate();

  const sendForm = async ({ email }: IForgotData): Promise<void> => {
    await sendResetPasswordLink({
      email,
      link_config: 'util',
    });

    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.FORGOT_PASSWORD,
      eventDescription: 'End Forgot Password',
    });

    addToast({
      message: t('forgot.toast.title'),
      title: t('forgot.toast.message'),
      type: 'success',
    });
  };

  const redirectToLogin = (): void => {
    navigate('/');
  };

  return {
    sendForm,
    redirectToLogin,
    disabled,
  };
};
