import { useAuth } from '@group-link-one/gl-utils';
import { MeasurementCategory } from '@group-link-one/gl-utils/dist/auth/types/user';
import {
  IOrganizationReqParams,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import {
  IOrganizationDataResponse,
  useOrganizationsService,
} from '../../../../Services/organizationsService/useOrganizations';
import { useCasesMap } from '../../../DeviceList/useDeviceListBody';
import {
  ImpersonateContentActions,
  ImpersonateContentState,
  useImpersonateContentStore,
} from '../../store/impersonateContent.store';
import { useImpersonateStore } from '../../store/impersonateModal.store';

interface IUseOrganizationContent {
  data: IOrganizationDataResponse[] | undefined;
  isLoading: boolean;
  onSelectOrganization: (orgId: string | number) => Promise<void>;
  impersonateContentState: ImpersonateContentState;
  impersonateContentActions: ImpersonateContentActions;
  isMobile: boolean;
  t: (key: string) => string;
}

export const useOrganizationContent = (): IUseOrganizationContent => {
  const { getOrganizations } = useOrganizationsService();
  const { state } = useImpersonateStore();

  const { t } = useI18n();
  const { user } = useAuth();

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();

  const useCaseSelected = useMemo(() => {
    if (
      !impersonateContentState.useCaseOptionsSelecteds ||
      !(impersonateContentState.useCaseOptionsSelecteds.length > 0)
    ) {
      return 'util_water';
    }

    const useCasesToApiMap: Record<
      MeasurementCategory,
      IOrganizationReqParams['use_case']
    > = {
      energy: 'util_kwh',
      gas: 'util_water',
      water: 'util_water',
      light: 'util_light',
    };

    const useCaseActive = impersonateContentState.useCaseOptionsSelecteds[0];

    if (!useCaseActive) {
      return 'util_water';
    }

    return useCasesToApiMap[useCaseActive.id as MeasurementCategory];
  }, [impersonateContentState.useCaseOptionsSelecteds]);

  const { data, isLoading } = useQuery({
    queryKey: [
      'get-organizations',
      state.modalSteperContentIsOpen,
      impersonateContentState.textToSearchOrg,
      useCaseSelected,
    ],
    queryFn: async () => {
      if (!state.modalSteperContentIsOpen) {
        return;
      }

      const response = await getOrganizations({
        use_case: useCaseSelected,
        org_name: impersonateContentState.textToSearchOrg,
      });

      return response.rows;
    },
  });

  const onSelectOrganization = async (
    orgId: string | number,
  ): Promise<void> => {
    if (orgId === impersonateContentState.organizationId) {
      impersonateContentActions.setOrganizationId(0);
      return;
    }
    impersonateContentActions.setOrganizationId(orgId);
  };

  useEffect(() => {
    impersonateContentActions.setUseCaseOptions(useCasesMap);
  }, [user]);

  return {
    data,
    isLoading,
    onSelectOrganization,
    impersonateContentState,
    impersonateContentActions,
    isMobile,
    t,
  };
};
