import { createRoot } from 'react-dom/client';
import App from './App.jsx';
import 'react-day-picker/style.css';

import { SmartRetailTheme } from '@group-link-one/grouplink-components';
// import {
//   // AuthProvider,
//   createStore,
//   persistedReducer,
//   persistStore,
// } from '@group-link-one/gl-utils';
import { env } from './env.js';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './Services/queryClient.js';
import { BrowserRouter } from 'react-router-dom';
import { initializeProducts } from './Context/FBProvider.jsx';

import 'react-day-picker/style.css';
import { SentryProvider, AuthProvider } from '@group-link-one/gl-utils';

// const store = createStore(persistedReducer(`@utilities-${env.VITE_NODE_ENV}`));
// const persistor = persistStore(store);

initializeProducts();

createRoot(document.getElementById('root')!).render(
  <SentryProvider
    config={{
      environment: "production",
      dsn: "https://883cb9b6d1280bb8ff61467bfc19293a@o4508245855633408.ingest.us.sentry.io/4508325296078848",
      appVersion: "1.1.11"
    }}
  >
    <QueryClientProvider client={queryClient}>
      <AuthProvider
        baseURL={env.VITE_REACT_APP_API_URL}
        keyStorage={`@utilities-${env.VITE_NODE_ENV}`}
      >
        <BrowserRouter>
          <SmartRetailTheme />
          <App />
        </BrowserRouter>

        <ReactQueryDevtools />
      </AuthProvider>
    </QueryClientProvider>
  </SentryProvider>,
);
