import {
  GL_COLORS,
  GL_TAG,
  GLEmptyList,
  GLList,
} from '@group-link-one/grouplink-components';

import { TagCard } from '../Card/TagCard';
import { useTagsContent } from './useTagsContent';

export const TagsContent = (): JSX.Element => {
  const { data = [], tabs, tagsIsLoading, t } = useTagsContent();

  return (
    <>
      {data?.length > 0 && (
        <GLList
          tabActive={t('tags.title')}
          tabs={tabs}
          contentStyle={{
            display: 'flex',
            flexDirection: 'column',
            gap: 10,
          }}
          type="list"
          mode="default"
          hasFixedHeaderAnimation={true}
          hasListHeader={true}
          isLoading={tagsIsLoading}
          content={
            <>
              {data?.map((tag) => (
                <TagCard
                  key={tag?.id}
                  key_name={tag?.key}
                  id={tag?.id}
                  description={tag.description}
                  label={tag.label}
                  is_strict={tag.is_strict!}
                />
              ))}
            </>
          }
        />
      )}

      {data?.length === 0 && !tagsIsLoading && (
        <GLEmptyList
          title={t('tags.content.list.emptyList.title')}
          description={t('tags.content.list.emptyList.description')}
          icon={<GL_TAG size={30} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
          hasBorder
        />
      )}

      {tagsIsLoading && (
        <GLList isLoading={tagsIsLoading} type="list" mode="default" />
      )}
    </>
  );
};
