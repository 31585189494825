import { FirebaseApp } from 'firebase/app';
import { getRemoteConfig, RemoteConfig } from 'firebase/remote-config';

let remoteConfig: RemoteConfig | null = null;

function initializeRemoteConfig(FBApp: FirebaseApp): RemoteConfig {
  remoteConfig = getRemoteConfig(FBApp);
  // Only for Development
  remoteConfig.settings.minimumFetchIntervalMillis = 20000;
  return remoteConfig;
}

export { initializeRemoteConfig, remoteConfig };
