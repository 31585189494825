import {
  GL_COLORS,
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  GLSelectMultipleOptions,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useMediaQuery } from 'usehooks-ts';

import {
  HealthCheckStoreActions,
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../../store/health-check-store';
import { useHealthCheckMap } from '../useHealthCheckMap';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';

interface IUseModalFilter {
  relativeFilterTimeOptions: GLRelativeFilterTimeProps;
  healthCheckState: HealthCheckStoreState;
  healthCheckActions: HealthCheckStoreActions;
  userGroupsFormatted: GLSelectMultipleOptions[] | undefined;
  groupsChildrensAvailables: GLSelectMultipleOptions[] | undefined;
  features: Features;
  t: (key: string) => string;
  onSelectUserGroup: (userGroupsSelecteds: GLSelectMultipleOptions[]) => void;
  tagOptionsFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectTag: (tagSelecteds: GLSelectMultipleOptions[]) => void;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectTagValue: (tagValuesSelecteds: GLSelectMultipleOptions[]) => void;
}

export const useModalFilter = (): IUseModalFilter => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const {
    userGroupsFormatted,
    groupsChildrensAvailables,
    onSelectUserGroup,
    tagOptionsFormatted,
    onSelectTag,
    onSelectTagValue,
    tagValuesFormatted
  } = useHealthCheckMap();
  const { features } = usePrivileges();

  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? 'fit-content' : '100%',
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? 'transparent' : 'inherit',
        alignItems: isMobile ? 'flex-end' : 'initial',
      },
    },
    inputOptions: {
      width: 'fit-content',
    },
    boxOptions: {
      position: 'bottom-right',
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    currentRelativeTime: healthCheckState.currentRelativeTime,
    currentDateRange: healthCheckState.currentDateRange,
    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_RELATIVE_DATE_FILTER,
        eventDescription: 'Start health check filter relative date',
        param1: JSON.stringify(option),
      });
      healthCheckActions.setCurrentRelativeTime(option);
    },
    setCurrentDateRange: (range?: GLDateRangeType) => {
      healthCheckActions.setCurrentDateRange(range);
    },
    onRangeChange: async (range?: GLDateRangeType) => {
      if (!range) {
        return;
      }
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_DATE_RANGE_FILTER,
        eventDescription: 'Start health check filter date range',
        param1: String(range.from),
        param2: String(range.to),
      });
      healthCheckActions.setRange(range);

      // onBoundsChanged();
    },
  };

  return {
    t,
    features,
    groupsChildrensAvailables,
    relativeFilterTimeOptions,
    healthCheckState,
    healthCheckActions,
    userGroupsFormatted,
    onSelectUserGroup,
    tagOptionsFormatted,
    onSelectTag,
    onSelectTagValue,
    tagValuesFormatted,
  };
};
