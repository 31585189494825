import { GLPaginationProvider } from '@group-link-one/grouplink-components';

// import { PageAnimated } from "../../utils/pageAnimated";
import { GroupListBody } from './GroupListBody';
import { useGroupList } from './useGroupList';

export function GroupList(): JSX.Element {
  const { lastPageToken, tabs } = useGroupList();

  return (
    <GLPaginationProvider
      lastNextPageToken={lastPageToken}
      tabs={tabs}
      isInfiniteScroll
    >
      <GroupListBody />
    </GLPaginationProvider>
  );
}
