import { GLModal } from '@group-link-one/grouplink-components';
import { ModalAddTagContent } from './Content/ModalAddTagContent';
import { useModalAddTag } from './useModalAddTag';

export const ModalAddTag = (): JSX.Element => {
  const { deviceListActions, deviceListState, t } = useModalAddTag();

  return (
    <GLModal
      title={t('deviceList.detailsModal.tags.addTag')}
      type="create"
      position="center"
      headerAlign="flex-start"
      maxWidth="100%"
      content={<ModalAddTagContent />}
      hasButtonClose
      open={deviceListState.modalAddTagIsOpen}
      onClose={() => {
        deviceListActions.setModalAddTagIsOpen(
          !deviceListState.modalAddTagIsOpen,
        )

        deviceListActions.setTagSelected({
          id: '',
          key: '',
          value: '',
          is_strict: false,
        });
      }
      }
    />
  );
};
