import { useAuth, User } from '@group-link-one/gl-utils';
// import parsePhoneNumber from 'libphonenumber-js';
import {
  countryList,
  useI18n,
  useMyAccountStore,
  parsePhoneNumber,
  useToast,
} from '@group-link-one/grouplink-components';
import { useEffect } from 'react';

import {
  MyAccForm,
  useMyAccountService,
} from '../../../Services/myAccountService/useMyAccountService';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

interface MyAccountProps {
  handleUpdateAccountInformation: (data: MyAccForm) => Promise<void>;
  user: User | undefined;
}

export function useMyAccount(): MyAccountProps {
  const { logEventAnalytics } = useFBAnalytics();
  const { user, setUser } = useAuth();
  const { addToast } = useToast();
  const { t } = useI18n();
  const { updateMyAccount, getMyAccount } = useMyAccountService();

  const { state: myAccountState, actions: myAccountStoreActions } =
    useMyAccountStore();

  function formatPhoneNumber(phone?: string | null): string {
    if (!phone || !myAccountState.countryActive) {
      return '';
    }

    const phoneWithDDD = `${myAccountState.countryActive.phone}${phone}`;

    return '+' + phoneWithDDD.replace(/\D/g, '');
  }

  const handleUpdateAccountInformation = async (
    data: MyAccForm,
  ): Promise<void> => {
    myAccountStoreActions.setIsSubmitting(true);

    const phoneFormatted = formatPhoneNumber(data.phone);

    const options = {
      ...data,
      phone: phoneFormatted,
    };

    updateMyAccount(options)
      .then((res) => {
        if (res.status === 204) {
          addToast({
            title: t('myAccount.toast.title'),
            message: t('myAccount.toast.message'),
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.MY_ACCOUNT_UPDATE,
            eventDescription: 'Update My Account',
          });
        }
        getMyAccount().then((res_) => {
          setUser(res_.session);
        });
      })
      .catch(() => {
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.MY_ACCOUNT_UPDATE,
          eventDescription: 'Error on Update My Account',
        });
      })
      .finally(() => {
        myAccountStoreActions.setIsSubmitting(false);
      });
  };

  useEffect(() => {
    if (!user?.phone) return;
    const BR = countryList.find((country) => country.code === 'BR');
    const phoneNumber = parsePhoneNumber(`+${user?.phone}`);

    if (!phoneNumber) {
      return myAccountStoreActions.setCountryActive(BR);
    }

    const ddd = phoneNumber.countryCallingCode.toString();
    const countryActive = countryList.find(
      (country) => String(country.phone) === ddd,
    );

    countryActive
      ? myAccountStoreActions.setCountryActive(countryActive)
      : myAccountStoreActions.setCountryActive(BR);
  }, []);

  return {
    handleUpdateAccountInformation,
    user,
  };
}
