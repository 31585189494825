import { GLBox } from '@group-link-one/grouplink-components';
import styled from 'styled-components';

export const BigNumbersContainer = styled(GLBox).attrs({
  gap: 20,
  animation: 'fade-in-down',
  transition: {
    delay: 0.4,
    duration: 1.4,
  },
})`
  display: flex;
  flex-wrap: wrap;

  // each big number card
  > div {
    min-width: 250px;
    flex: 1;
  }
`;

export const ChartsContainer = styled(GLBox).attrs({
  gap: 20,
  animation: 'fade-in-down',
  direction: 'row',
  transition: {
    delay: 0.5,
    duration: 1.4,
  },
})`
  @media (max-width: 1350px) {
    flex-direction: column !important;
  }
`;
