import { GLDeviceList } from '@group-link-one/grouplink-components';

import { DeviceListContent } from './Content/DeviceListContent';
import { ModalDecomission } from './ModalDecomission/ModalDecomission';
import { ModalDeviceDetails } from './ModalDeviceDetails/ModalDeviceDetails';
import { ModalEditDevice } from './ModalEditDevice/ModalEditDevice';
import { useDeviceListBody } from './useDeviceListBody';
import { ModalAddTag } from './ModalAddTag/ModalAddTag';
import { ModalUnassignTag } from './ModalUnassignTag/ModalUnassignTag';
import { ModalSureToAddValue } from './ModalSureToAddValue/ModalSureToAddValue';

export function DeviceListBody(): JSX.Element {
  const {
    requests,
    features,
    deviceListStateGL,
    userGroupsFormatted,
    groupsChildrensAvailables,
    bigNumbers,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUseCase,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
  } = useDeviceListBody();

  return (
    <GLDeviceList
      useCaseOptions={deviceListStateGL.useCaseOptionsAvailables}
      userGroups={userGroupsFormatted}
      userGroupsChildren={groupsChildrensAvailables}
      tagOptions={tagOptionsFormatted}
      tagValuesOptions={tagValuesFormatted}
      allDevicesOptions={{
        deviceListContent: <DeviceListContent />,
      }}
      flagsOptions={{
        search: features.deviceList.filters.search,
        filters: {
          button: features.deviceList.filters.button,
          byActivationDate: features.deviceList.filters.byActivationDate,
          byGroups: features.deviceList.filters.byGroups,
          byTags: features.deviceList.filters.byTags,
          byUseCase: features.deviceList.filters.byUseCase,
        },
      }}
      bigNumbersOptions={{
        bigNumbers: bigNumbers.length > 0 ? bigNumbers : undefined,
      }}
      modalDetails={<ModalDeviceDetails />}
      modalRegister={<ModalEditDevice />}
      modalDecommission={<ModalDecomission />}
      modalAddTag={<ModalAddTag />}
      modalUnassignTag={<ModalUnassignTag />}
      modalSureToAddValue={<ModalSureToAddValue />}
      hasPermissionToShowBigNumbers={requests.getBignumberCountsDeviceList}
      isOnTopHeaderContent={!requests.getBignumberCountsDeviceList}
      onSelectUseCase={(useCaseOptionsSelecteds) =>
        onSelectUseCase(useCaseOptionsSelecteds)
      }
      onSelectUserGroup={(userGroupSelecteds, type) =>
        onSelectUserGroup(userGroupSelecteds, type)
      }
      onSelectTag={(tagSelecteds) => onSelectTag(tagSelecteds)}
      onSelectTagValue={(tagValueSelecteds) =>
        onSelectTagValue(tagValueSelecteds)
      }
    />
  );
}
