import { useAuth } from '@group-link-one/gl-utils';
import { useGLModalLogout } from '@group-link-one/grouplink-components';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { env } from '../env';
import { onRequestError } from '../Errors/onError';
import {
  LOCAL_STORAGE_KEY_EXTERNAL_TIME,
  useCheckTimeManipulation,
} from '../hooks/useCheckTimeManipulation';

interface PendingRequest {
  resolve: (timestamp: number) => void;
  reject: (err: AxiosError) => void;
}

let isRefreshingTimeStamp = false;
let pendingRequestsQueue: PendingRequest[] = [];

interface AxiosHTTPRequest {
  http: AxiosInstance;
  httpPrivate: AxiosInstance;
}

const useAxiosHTTPRequest = (): AxiosHTTPRequest => {
  const navigate = useNavigate();
  const { accessToken, accessTokenIsValid } = useAuth();
  const { handleOpenModalLogout } = useGLModalLogout();
  const { getExternalTime } = useCheckTimeManipulation();

  const api = axios.create({
    baseURL: env.VITE_REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const apiPrivate = axios.create({
    baseURL: env.VITE_REACT_APP_API_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: accessToken,
    },
  });

  useEffect(() => {
    apiPrivate.interceptors.request.use((config) => {
      if (!accessTokenIsValid) {
        const source = axios.CancelToken.source();
        config.cancelToken = source.token;
        source.cancel('Access token is invalid');
      }

      const externalTime = localStorage.getItem(
        LOCAL_STORAGE_KEY_EXTERNAL_TIME,
      );
      const externalTimeMilliseconds = externalTime
        ? new Date(externalTime).getTime()
        : new Date().getTime();

      config.headers.Authorization = accessToken;
      config.headers['X-Timestamp'] = externalTimeMilliseconds;
      // config.headers["X-Timestamp"] = new Date().getTime()
      // config.headers["X-Diff-Time"] = new Date().getTime() - externalTimeMilliseconds
      return config;
    });

    apiPrivate.interceptors.response.use(
      async (response) => {
        return response;
      },
      async (error) => {
        onRequestError(error);

        if (
          error.response?.status === 401 &&
          !error.response?.data?.message?.includes('privilégios')
        ) {
          handleOpenModalLogout();
          localStorage.removeItem('isImpersonated');
          return;
        }

        if (error.response?.data?.message?.includes('privilégios')) {
          navigate('/');
          return;
        }

        if (error.response?.data?.error === 'INVALID_TIMESTAMP') {
          const originalConfig = error.config;

          if (!isRefreshingTimeStamp) {
            isRefreshingTimeStamp = true;

            getExternalTime()
              .then((res) => {
                localStorage.setItem(LOCAL_STORAGE_KEY_EXTERNAL_TIME, res.iso);
                pendingRequestsQueue.forEach((request) =>
                  request.resolve(res.milliseconds),
                );
                pendingRequestsQueue = [];
              })
              .catch((err) => {
                pendingRequestsQueue.forEach((request) => request.reject(err));
                pendingRequestsQueue = [];
              })
              .finally(() => {
                isRefreshingTimeStamp = false;
              });
          }

          return new Promise((resolve, reject) => {
            pendingRequestsQueue.push({
              resolve: (timestamp: number) => {
                originalConfig.headers['X-Timestamp'] = timestamp;
                // originalConfig.headers["X-Timestamp"] = new Date().getTime()
                // originalConfig.headers["X-Diff-Time"] = new Date().getTime() - timestamp
                resolve(apiPrivate(originalConfig));
              },
              reject: (err: AxiosError) => {
                reject(err);
              },
            });
          });
        }

        return Promise.reject(error);
      },
    );
  }, [accessToken, apiPrivate, handleOpenModalLogout]);

  return {
    http: api,
    httpPrivate: apiPrivate,
  };
};

export default useAxiosHTTPRequest;
