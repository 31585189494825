import { GL_COLORS } from '@group-link-one/grouplink-components';

import { getColorByVar } from '../../utils/getColorByVar';

export const useWaterIcon = (type: 'red' | 'green'): string => {
  const varColor = getColorByVar(GL_COLORS.ACCENT_COLOR);
  const fontColor = getColorByVar(GL_COLORS.FONT_COLOR_VARIANT);

  return `<svg width="66px" height="72px" viewBox="0 0 66 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Untitled</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-11348" transform="translate(0.5005, 0.5004)" fill="${varColor}" fill-rule="nonzero" stroke="${varColor}" stroke-opacity="0">
            <g id="icon-pin" opacity="0.3" transform="translate(0, 54.0021)">
                <path d="M30.9995,16.9995 L52.9195,12.0205 C65.0255,9.2705 65.0255,4.8125 52.9195,2.0625 L52.9195,2.0625 C40.8135,-0.6875 21.1855,-0.6875 9.0795,2.0625 L9.0795,2.0625 C-3.0265,4.8125 -3.0265,9.2705 9.0795,12.0205 L30.9995,16.9995 Z" id="Subtraction_1"></path>
            </g>
            <g id="icon-pin-2" transform="translate(0.0001, 0)">
                <path d="M30.9994048,69.0006259 L52.9194048,52.8566259 C58.7326282,47.0534671 61.9993105,39.1766516 61.9993105,30.9626259 C61.9993105,22.7486002 58.7326282,14.8717847 52.9194048,9.06862593 L52.9194048,9.06862593 C40.8072873,-3.02287531 21.1915223,-3.02287531 9.0794048,9.06862593 L9.0794048,9.06862593 C3.26648776,14.8717444 -8.73866951e-16,22.7483455 -8.73866951e-16,30.9621259 C-8.73866951e-16,39.1759064 3.26648776,47.0525074 9.0794048,52.8556259 L30.9994048,69.0006259 Z" id="Subtraction_1-2"></path>
            </g>
        </g>
        <g id="icon-water" transform="translate(17, 17)">
            <rect id="Retângulo_5054" x="0" y="0" width="30" height="30"></rect>
            <g id="Grupo_5143" transform="translate(2.9998, 0.0035)" fill="${fontColor}" fill-rule="nonzero">
                <g id="Grupo_11442" transform="translate(-0, 0)">
                    <path d="M12.1431844,29.9964503 C9.82898969,30.0317465 7.55086863,29.4204841 5.56518444,28.2314503 C3.81186111,27.1574614 2.37872766,25.6328146 1.41518444,23.8164503 C-0.892598176,19.4679789 -0.342576497,14.1558443 2.80718444,10.3724503 C5.97118444,6.5724503 8.61518444,2.9394503 9.90718444,1.1134503 C10.1122614,0.82515432 10.3747621,0.582400788 10.6781844,0.4004503 C11.1201922,0.136063132 11.6261466,-0.00227026469 12.1411844,0.000220640338 C13.0233578,-0.0109851815 13.856519,0.405222792 14.3771844,1.1174503 C15.4131844,2.5804503 18.1771844,6.4114503 21.4771844,10.3764503 C24.6267706,14.1595299 25.1767941,19.4712872 22.8691844,23.8194503 C21.9055455,25.6360095 20.4724535,27.1609575 18.7191844,28.2354503 C16.7336896,29.422773 14.4563243,30.0326332 12.1431844,29.9964503 L12.1431844,29.9964503 Z M12.1431844,2.2524503 C10.7241844,4.2354503 7.85118444,8.1374503 4.91718444,11.6634503 C1.91718444,15.3294503 1.64718444,19.2094503 3.41718444,22.5344503 C5.24953112,25.6317077 8.54573975,27.5691652 12.1431844,27.6634503 C15.7758723,27.5720617 19.1127865,25.6396881 21.0001844,22.5344503 C22.6761987,18.9548126 22.1584106,14.7321017 19.6671844,11.6634503 C16.6511844,8.0474503 13.4071844,4.0184503 12.1431844,2.2474503 L12.1431844,2.2524503 Z" id="Path_15430"></path>
                </g>
            </g>
        </g>
        <g id="Group-11349" transform="translate(44, 42)" fill="${type === 'green' ? '#00FFAA' : '#E12C57'}" fill-rule="nonzero" stroke="${type === 'green' ? '#00FFAA' : '#E12C57'}" stroke-opacity="0.200000003" stroke-width="8">
            <circle id="Ellipse_2765" cx="9" cy="9" r="9"></circle>
        </g>
    </g>
    </svg>`;
};
