import { useAuth } from '@group-link-one/gl-utils';
import { useLocation } from 'react-router-dom';

import { useAuthServices } from '../../../Services/authService/useAuthServices';

interface IUseCheckStatus {
  sendCheckStatus: () => Promise<boolean>;
}

export const useCheckStatus = (): IUseCheckStatus => {
  const { setAccessToken, setAccessTokenIsValid } = useAuth();
  const { checkStatus } = useAuthServices();
  const { search } = useLocation();

  function extractTokenFromSearch(): string {
    const params = new URLSearchParams(search);
    return params.get('tmp_session') || '';
  }

  async function sendCheckStatus(): Promise<boolean> {
    const tmp_session = extractTokenFromSearch();

    const response = await checkStatus(tmp_session);

    if (response?.status === 'complete') {
      setAccessToken(`Bearer ${response.access_token}`);
      setAccessTokenIsValid(true);
      // manipulateReduxValues(
      //   'SET_ACCESS_TOKEN',
      //   `Bearer ${response.access_token}`,
      // );
      // manipulateReduxValues('SET_ACCESS_TOKEN_IS_VALID', true);

      return true;
    }

    return false;
  }

  return {
    sendCheckStatus,
  };
};
