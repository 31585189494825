import { TabProps, useI18n } from '@group-link-one/grouplink-components';

export const useReportList = (): {
  tabs: TabProps[];
  t: (key: string) => string;
} => {
  const { t } = useI18n();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: true,
      cacheKey: 'reports-created',
      count: 0,
      name: t('reports.tabsList.created'),
      onClick: () => {},
    },
    {
      id: 2,
      active: false,
      cacheKey: 'reports-processing',
      count: 0,
      name: t('reports.tabsList.processing'),
      onClick: () => {},
    },
    // {
    //   id: 3,
    //   active: false,
    //   cacheKey: "reports-errored",
    //   count: 0,
    //   name: t("reports.tabsList.errored"),
    //   onClick: () => {},
    // },
  ];

  return { tabs, t };
};
