import { GLTextSwitcherOptions } from '@group-link-one/grouplink-components';
import { ReactElement, useState } from 'react';

import { CsvTransfer } from '../CsvTransfer/CsvTransfer';
import { GroupDevicesTransferList } from '../TransferList/GroupDevicesTransferList';
import { useGLFlags } from '@group-link-one/gl-utils';

interface AddDevicesBodyProps {
  tabActive: number;
  setTabActive: (tab: number) => void;
  tabOptions: GLTextSwitcherOptions[];
  contentToShowByTab: Record<string, ReactElement>;
  isToShowCsvTransfer: boolean;
}

export const useAddDevicesBody = (): AddDevicesBodyProps => {
  const [tabActive, setTabActive] = useState(1);
  const { showFeature } = useGLFlags();

  const isToShowCsvTransfer = showFeature("show_button_csv_transfer")

  const tabOptions: GLTextSwitcherOptions[] = [
    {
      id: 1,
      text: 'Transfer list',
    },
    {
      id: 2,
      text: 'Import CSV',
    },
  ];

  const contentToShowByTab: Record<string, ReactElement> = {
    'tab-1': <GroupDevicesTransferList />,
    'tab-2': <CsvTransfer />,
  };

  return {
    tabActive,
    setTabActive,
    tabOptions,
    contentToShowByTab,
    isToShowCsvTransfer
  };
};
