import { useAuth } from '@group-link-one/gl-utils';
import { OnSubmitParams, useToast } from '@group-link-one/grouplink-components';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ErrorType, SmartRetailErrors } from '../../../Errors/errors';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useGLFlags } from '@group-link-one/gl-utils';

import * as Sentry from '@sentry/react';

interface IUseLogin {
  sendForm: (data: OnSubmitParams) => Promise<void>;
  redirectToForgotPassword: () => void;
  microsoftToken: () => Promise<void>;
  showFeature: (featureName: string) => boolean;
  loginErrorMessage: string | boolean;
}

export const useLogin = (): IUseLogin => {
  const { showFeature } = useGLFlags();
  const { logEventAnalytics } = useFBAnalytics();
  const navigate = useNavigate();
  const { verifyUserAPI, initMicrosoftToken } = useAuth();
  const { addToast } = useToast();
  const [loginErrorMessage, setLoginErrorMessage] = useState<string | boolean>(
    '',
  );

  const sendForm = async (data: OnSubmitParams): Promise<void> => {
    try {
      const response = await verifyUserAPI(data);

      if (response.error) {
        setLoginErrorMessage(true);
        throw new AxiosError(response.error.data.error);
      }

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.LOGIN,
        eventDescription: 'login',
        param1: data.email,
      });

      navigate('/');
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof AxiosError) {
        const typeError = error.message as ErrorType;

        const { title, message } = new SmartRetailErrors({
          error: typeError,
        }).getError();

        addToast({
          title,
          message,
          type: 'error',
        });
      }
    }
  };

  async function microsoftToken(): Promise<void> {
    await initMicrosoftToken(37);

    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.LOGIN_WITH_MICROSOFT,
      eventDescription: 'Login with Microsoft',
    });
  }

  const redirectToForgotPassword = (): void => {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.FORGOT_PASSWORD,
      eventDescription: 'Start Forgot Password',
    });
    navigate('/forgot-password');
  };

  return {
    sendForm,
    redirectToForgotPassword,
    microsoftToken,
    showFeature,
    loginErrorMessage,
  };
};
