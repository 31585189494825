import { GLOnboardTemplate } from '@group-link-one/grouplink-components';
import { Outlet, useLocation } from 'react-router-dom';

import LogoDarkSmartRetail from '../images/Common/main-logo-dark.svg';
import LogoLightSmartRetail from '../images/Common/main-logo-light.svg';
import SmartRetailArtMob from '../images/Onboard/art-bar-mob-smart-retail.svg';
import SmartRetailArt from '../images/Onboard/art-login-smart-retail.svg';

export const OnboardLayout = (): JSX.Element => {
  const { pathname } = useLocation();

  const pagesToSetScroll = ['/new-password', '/join-organization'];

  return (
    <GLOnboardTemplate
      content={<Outlet />}
      debug={false}
      hasScroll={pagesToSetScroll.includes(pathname)}
      images={{
        leftImageDesk: SmartRetailArt,
        leftImageMob: SmartRetailArtMob,
        logoLight: LogoLightSmartRetail,
        logoDark: LogoDarkSmartRetail,
      }}
    />
  );
};
