import { useQueryClient } from '@tanstack/react-query';

import { useGroupListService } from '../../Services/groupListService/useGroupListService';
import { GetOrgDeviceGroupIdResponse } from '@/Services/groupListService/useGroupListService.types';

interface IUseDeviceGroupsUtils {
  getDeviceGroup: (id: number) => Promise<GetOrgDeviceGroupIdResponse>;
}

export const useDeviceGroupsUtils = (): IUseDeviceGroupsUtils => {
  const { getOrgDeviceGroupId } = useGroupListService();

  const queryClient = useQueryClient();

  async function getDeviceGroup(
    id: number,
  ): Promise<GetOrgDeviceGroupIdResponse> {
    const response = await queryClient.fetchQuery({
      queryKey: ['get-device-group', id],
      queryFn: () => getOrgDeviceGroupId(id),
    });

    return response;
  }

  return {
    getDeviceGroup,
  };
};
