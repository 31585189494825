import { TabProps, useI18n } from '@group-link-one/grouplink-components';

interface IUsePagination {
  tabs: TabProps[];
  lastPageToken: {
    users: {
      token: string | undefined;
      page: number | undefined;
    };
  };
}

export const usePagination = (): IUsePagination => {
  const { t } = useI18n();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: false,
      cacheKey: 'users',
      count: undefined,
      name: t('impersonate.modal.steps.user.tabs.users'),
      onClick: () => {},
    },
  ];

  const lastPageToken = {
    users: {
      token: undefined,
      page: undefined,
    },
  };

  return {
    tabs,
    lastPageToken,
  };
};
