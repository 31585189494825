import {
  DataGroup,
  GL_COLORS,
  GL_GLStation,
  GLTransferListSideOptions,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';

import { useDeviceListService } from '../../../../../Services/deviceListService/useDeviceListService';
import { GetDevicesActivatedLastReadingsResponse } from '../../../../../Services/deviceListService/useDeviceListService.types';
import { useTagsService } from '../../../../../Services/tagsService/useTagsService';
import {
  GroupsDevicesStoreState,
  useGroupsDevicesStore,
} from '../../../stores/devices.store';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../../../stores/tags.store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

const IPP_DEVICES = 50;

interface IUseDevicesSteps {
  t: (key: string) => string;
  availableDevicesSearchIsCorrect: boolean;
  devicesAvailables: DataGroup[];
  devicesAddeds: DataGroup[];
  leftSideOptions: GLTransferListSideOptions;
  rightSideOptions: GLTransferListSideOptions;
  groupDevicesState: GroupsDevicesStoreState;
  onTransferItem: (items: DataGroup[], type: 'left' | 'right') => void;
  onSearch: (value: string, side: 'left' | 'right') => void;
  tagsStoreState: TagsStoreState;
  tagsStoreActions: TagsStoreActions;
  onAssignDeviceToTag: () => void;
}

export const useDevicesSteps = (): IUseDevicesSteps => {
  const { logEventAnalytics } = useFBAnalytics();
  const { assignDeviceToTag } = useTagsService();
  const { addToast } = useToast();
  const { state: tagsStoreState, actions: tagsStoreActions } = useTagsStore();
  const [devicesAvailables, setDevicesAvailables] = useState<DataGroup[]>([]);
  const [devicesAvailablesData, setDevicesAvailablesData] = useState<
    GetDevicesActivatedLastReadingsResponse[]
  >([]);

  const [devicesAddeds, setDevicesAddeds] = useState<DataGroup[]>([]);
  const [devicesAddedsData, setDevicesAddedsData] = useState<number[]>([]);

  const [availableDevicesSearchIsCorrect, setAvailableDevicesSearchIsCorrect] =
    useState(true);

  const { t } = useI18n();

  const { getDevicesActivatedLastReadings } = useDeviceListService();

  const { state: groupDevicesState, actions: groupDevicesActions } =
    useGroupsDevicesStore();

  function formatDevicesAvailables(
    currentDevicesAvailables: GetDevicesActivatedLastReadingsResponse[],
  ): DataGroup[] {
    if (!currentDevicesAvailables) {
      return [];
    }

    const devicesFormatted: DataGroup[] = currentDevicesAvailables?.map(
      (device) => {
        return {
          id: String(device.device_id),
          title: device.channels[0].name,
          body: `ID: ${device.device_id}`,
          icon: <GL_GLStation fill={GL_COLORS.FONT_COLOR_VARIANT} />,
        };
      },
    );

    return devicesFormatted;
  }

  const isEditing = false;
  const queryClient = useQueryClient();

  const { mutateAsync: onAssignDeviceToTag } = useMutation({
    mutationFn: async () => {
      if (devicesAddedsData.length === 0) {
        return;
      }

      if (
        !tagsStoreState?.activeTag?.key ||
        !tagsStoreState.selectedTagValue?.value
      ) {
        return;
      }

      await assignDeviceToTag({
        device_ids: devicesAddedsData,
        operations: [
          {
            key: String(tagsStoreState.activeTag?.key),
            value: tagsStoreState.selectedTagValue!.value,
          },
        ],
        preserve_existing_tags: tagsStoreState.preserveExistingTags,
        strict: false,
      });
    },
    onSuccess: () => {
      if (devicesAddedsData.length === 0) {
        return;
      }

      if (
        !tagsStoreState.activeTag?.key ||
        !tagsStoreState.selectedTagValue?.value
      ) {
        return;
      }
      addToast({
        type: 'success',
        title: 'Associated tag',
        message: 'Tag successfully associated with the device',
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_ASSIGN_TO_DEVICES,
        eventDescription: 'End assign tag to devices',
      });
      tagsStoreActions.setModalAssignToDeviceIsOpen(false);
    },
    onError: () => {
      addToast({
        type: 'error',
        title: 'Something went wrong',
        message: 'An error occurred while associating the tag with the device',
      });
    },
  });

  const searchDeviceIDAvailablesDevices = useMemo(() => {
    if (
      groupDevicesState.availableDevicesSearch.length >= 0 &&
      groupDevicesState.availableDevicesSearch.length < 10
    ) {
      return undefined;
    }

    return isNaN(Number(groupDevicesState.availableDevicesSearch))
      ? undefined
      : [Number(groupDevicesState.availableDevicesSearch)];
  }, [groupDevicesState.availableDevicesSearch]);

  const valueTosearchDeviceIDAvailablesDevices = (
    search: string,
  ): number[] | undefined => {
    if (search.length >= 0 && search.length < 10) {
      return undefined;
    }

    return isNaN(Number(search)) ? undefined : [Number(search)];
  };

  async function getMoreDevicesAvailables(): Promise<void> {
    if (
      groupDevicesState.isFetchingMoreAvailableDevices ||
      !groupDevicesState.nextPageTokenAvailableDevices
    ) {
      return;
    }

    groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

    const response = await getDevicesActivatedLastReadings({
      next_page_token: groupDevicesState.nextPageTokenAvailableDevices,
      ipp: IPP_DEVICES,
      device_id: searchDeviceIDAvailablesDevices,
    });

    setDevicesAvailablesData([...devicesAvailablesData, ...response.rows]);

    groupDevicesActions.setIsFetchingMoreAvailableDevices(false);
    groupDevicesActions.setNextPageTokenAvailableDevices(
      response.next_page_token,
    );

    const currentDevicesAvailables:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData(['get-devices-availables']);

    const newDevicesAvailables = response.rows.filter(
      (device) =>
        groupDevicesState.devices_ids.map(Number).indexOf(device.device_id) ===
          -1 && device.groups.length === 0,
    );

    if (currentDevicesAvailables) {
      const currentDevicesAvailablesWihoutAddeds =
        currentDevicesAvailables.filter(
          (device) =>
            groupDevicesState.devices_ids
              .map(Number)
              .indexOf(device.device_id) === -1,
        );

      const newDevicesAvailablesFormatted: DataGroup[] =
        formatDevicesAvailables(
          currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables),
        );

      setDevicesAvailables(newDevicesAvailablesFormatted);

      queryClient.setQueryData(
        ['get-devices-availables'],
        currentDevicesAvailablesWihoutAddeds.concat(newDevicesAvailables),
      );
    }
  }

  const leftSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-availables-transfer-list',
          count: undefined,
          id: 1,
          name: t(
            'groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne',
          ),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.ACCENT_COLOR,
      listName: t(
        'groupsDevices.addDevicesModal.transferList.leftSide.tabs.tabOne',
      ),
      onScrollCallback: async () => {
        getMoreDevicesAvailables();
      },
    };
  }, [
    groupDevicesState.devices_ids,
    groupDevicesState.isFetchingMoreAvailableDevices,
    groupDevicesState.nextPageTokenAvailableDevices,
  ]);

  const rightSideOptions: GLTransferListSideOptions = useMemo(() => {
    return {
      tabs: [
        {
          active: true,
          cacheKey: 'users-in-group-transfer-list',
          count: devicesAddeds.length,
          id: 1,
          name: t(
            'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
          ),
          onClick: () => {},
        },
      ],
      color: GL_COLORS.LIGHT_GREEN,
      listName: t(
        'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
      ),
      onScrollCallback: async () => {},
    };
  }, [devicesAddeds]);

  async function getDevicesAvailables(
    search?: string,
  ): Promise<GetDevicesActivatedLastReadingsResponse[]> {
    return await queryClient.fetchQuery({
      queryKey: ['get-devices-availables'],
      queryFn: async () => {
        groupDevicesActions.setIsFetchingMoreAvailableDevices(true);

        const availableDevices = await getDevicesActivatedLastReadings({
          next_page_token: undefined,
          ipp: IPP_DEVICES,
          device_id: valueTosearchDeviceIDAvailablesDevices(search || ''),
          group_ids: [],
        });

        groupDevicesActions.setIsFetchingMoreAvailableDevices(false);

        if (availableDevices.has_more && availableDevices.next_page_token) {
          groupDevicesActions.setNextPageTokenAvailableDevices(
            availableDevices.next_page_token,
          );
        }

        const devicesFiltereds = availableDevices.rows.filter(
          (device) =>
            groupDevicesState.devices_ids.indexOf(device.device_id) === -1,
        );

        const devicesFormatted: DataGroup[] =
          formatDevicesAvailables(devicesFiltereds);

        setDevicesAvailablesData(devicesFiltereds);
        setDevicesAvailables(devicesFormatted);

        return devicesFiltereds;
      },
    });
  }

  function filterDevicesAddedsBySearch(search: string): void {
    const devicesAddedsCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData([
      'get-devices-in-group',
      groupDevicesState.groupsDevicesCardInfo.id,
    ]);

    if (!devicesAddedsCached) {
      return;
    }

    if (search.length > 0) {
      const devicesFiltered = devicesAddedsCached.filter((device) => {
        return (
          String(device.device_id).includes(search) ||
          device.channels[0].name.toLocaleLowerCase().includes(search)
        );
      });

      const devicesFormatted: DataGroup[] = devicesFiltered.map((device) => {
        return {
          id: String(device.device_id),
          title: device.channels[0].name,
          body: `ID: ${device.device_id}`,
        };
      });

      setDevicesAddeds(devicesFormatted);
    } else {
      const devicesFormatted: DataGroup[] = devicesAddedsCached.map(
        (device) => {
          return {
            id: String(device.device_id),
            title: device.channels[0].name,
            body: `ID: ${device.device_id}`,
          };
        },
      );

      setDevicesAddeds(devicesFormatted);
    }
  }

  function onTransferItem(items: DataGroup[], type: 'left' | 'right'): void {
    const queryKeyDevicesAvailables = ['get-devices-availables'];

    const devicesAvailablesCached:
      | GetDevicesActivatedLastReadingsResponse[]
      | undefined = queryClient.getQueryData(queryKeyDevicesAvailables);

    if (!devicesAvailablesCached) {
      return;
    }

    if (type === 'right') {
      const devicesIds = items.map((device) => Number(device.id));

      if (groupDevicesState.devicesInGroupSearch.length === 0) {
        groupDevicesActions.setDevicesIds(devicesIds);

        const devicesAddedsDataFormatted: number[] = items.map((device) =>
          Number(device.id),
        );

        setDevicesAddedsData(devicesAddedsDataFormatted);
      } else {
        const allDevicesID = Array.from(
          new Set(devicesAddedsData.concat(devicesIds)),
        );
        setDevicesAddedsData(allDevicesID);
      }

      setDevicesAddeds(items);
    }

    if (type === 'left') {
      setDevicesAvailables(items);

      const currentDevicesSorted = items
        .map((item) => {
          return devicesAvailablesCached.find(
            (device) => device.device_id === Number(item.id),
          );
        })
        .filter((item) => item);

      const devicesThatWillBeToAddeds = devicesAvailablesCached.filter(
        (device) =>
          currentDevicesSorted
            .map((item) => item?.device_id)
            .indexOf(device.device_id) === -1,
      );

      if (devicesThatWillBeToAddeds.length) {
        // const allDevicesAddeds = [...devicesThatWillBeToAddeds];
        // const allDevicesAddedsMap = new Map(
        //   allDevicesAddeds.map((device) => [device.device_id, device]),
        // );
      }

      queryClient.setQueryData(
        ['get-devices-availables'],
        currentDevicesSorted,
      );

      if (groupDevicesState.devicesInGroupSearch.length > 0) {
        const lastItemFromAvailables = items.slice(-1)[0];

        const newDevicesAddedsData = devicesAddedsData.filter(
          (device) => device !== Number(lastItemFromAvailables.id),
        );

        groupDevicesActions.setDevicesIds(
          newDevicesAddedsData.map((device) => device),
        );

        setDevicesAddedsData(newDevicesAddedsData);
      }
    }
  }

  function onSearch(value: string, side: 'left' | 'right'): void {
    if (side === 'left') {
      groupDevicesActions.setAvailableDevicesSearch(value);

      if (value.length === 0 || value.length >= 10) {
        getDevicesAvailables(value);
        setAvailableDevicesSearchIsCorrect(true);
      } else {
        setAvailableDevicesSearchIsCorrect(false);
      }
    }

    if (side === 'right') {
      groupDevicesActions.setDevicesInGroupSearch(value);
      filterDevicesAddedsBySearch(value.toLocaleLowerCase());
    }
  }

  async function initOnOpenModal(): Promise<void> {
    groupDevicesActions.setIsFetchingMoreDevicesInGroup(true);

    await getDevicesAvailables();
    setDevicesAddeds([]);

    groupDevicesActions.setIsFetchingMoreDevicesInGroup(false);
  }

  useEffect(() => {
    if (tagsStoreState.modalAssignToDeviceIsOpen) {
      initOnOpenModal();
    }

    if (!tagsStoreState.modalAssignToDeviceIsOpen) {
      setDevicesAddeds([]);
      groupDevicesActions.setAvailableDevicesSearch('');
      groupDevicesActions.setDevicesInGroupSearch('');
    }
  }, [tagsStoreState.modalAssignToDeviceIsOpen, isEditing]);

  useEffect(() => {
    groupDevicesActions.setDevicesIds(devicesAddedsData);
  }, [devicesAddedsData]);

  return {
    t,
    availableDevicesSearchIsCorrect,
    devicesAvailables,
    devicesAddeds,
    leftSideOptions,
    rightSideOptions,
    groupDevicesState,
    onTransferItem,
    onSearch,
    tagsStoreState,
    tagsStoreActions,
    onAssignDeviceToTag,
  };
};
