import { GLModal } from '@group-link-one/grouplink-components';

import { ModalCreateTagContent } from './Content/ModalCreateTagContent';
import { useModalCreateTag } from './useModalCreateTag';

export const ModalCreateTag = (): JSX.Element => {
  const { state, actions, verifyButtonText } = useModalCreateTag();

  return (
    <GLModal
      maxWidth="100%"
      open={state.modalCreateTagIsOpen}
      onClose={() => {
        actions.setModalCreateTagIsOpen(false);
        actions.reset();
      }}
      content={<ModalCreateTagContent />}
      title={verifyButtonText()}
      hasButtonClose
    />
  );
};
