import {
  GL_CHEVRON_LEFT,
  GL_COLORS,
  GLBigNumberCard,
} from '@group-link-one/grouplink-components';
import React from 'react';

import { HealthCheckBigNumbersContainer } from './HealthCheckBigNumbersStyle';
import { useHealthCheckBigNumbers } from './useHealthCheckBigNumbers';

interface IHealthCheckBigNumbers {
  local: 'map' | 'overview';
}

export function HealthCheckBigNumbers({
  local,
}: IHealthCheckBigNumbers): JSX.Element {
  const {
    bigNumbers,
    healthCheckState,
    isLoading,
    isSM,
    onClickHideShowBigNumbers,
  } = useHealthCheckBigNumbers();

  return (
    <HealthCheckBigNumbersContainer
      direction={local === 'map' ? 'column' : 'row'}
      gap={isSM ? 15 : 20}
      height="100%"
      justify="center"
    >
      {bigNumbers &&
        bigNumbers.map((bigNumber, index) => (
          <GLBigNumberCard
            bigNumber={bigNumber}
            isLoading={isLoading}
            key={index}
          />
        ))}

      <button
        className={`health-check-button-hide-show ${healthCheckState.bigNumbersIsOpen ? 'inside' : 'outside'}`}
        onClick={onClickHideShowBigNumbers}
      >
        <GL_CHEVRON_LEFT fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
      </button>
    </HealthCheckBigNumbersContainer>
  );
}
