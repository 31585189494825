import {
  GL_COLORS,
  GLBox,
  GLCardTemplate,
} from '@group-link-one/grouplink-components';
import styled, { keyframes } from 'styled-components';

export const ANIMATION_CONTENT_DELAY = 0.6;

export const WIDTH_AFTER_ELEMENT = 38;

const ANIMATION_BORDER_LEFT_DELAY = ANIMATION_CONTENT_DELAY + 0.25;
const ANIMATION_BORDER_LEFT_TIME = 0.7;

const calculateRightDelay = (leftTime: number): number => {
  // y=0.45x+0.85
  return 0.45 * leftTime + 0.85;
};

const ANIMATION_BORDER_RIGHT_TIME = ANIMATION_BORDER_LEFT_TIME / 2;
const ANIMATION_BORDER_RIGHT_DELAY = calculateRightDelay(
  ANIMATION_BORDER_LEFT_TIME,
);

const ANIMATION_BORDER_LEFT_PSEUDOELEMENT_TIME = 0.5;
const ANIMATION_BORDER_LEFT_PSEUDOELEMENT_DELAY =
  ANIMATION_BORDER_LEFT_DELAY + ANIMATION_BORDER_LEFT_TIME;

const animationWidth = keyframes`
  from {
    width: 0;
  }

  to {
    width: ${WIDTH_AFTER_ELEMENT}px;
  }
`;

const animationBorder = keyframes`
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  50% {
    width: 0;
    height: calc(100% + 8px);
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: ${GL_COLORS.ACCENT_COLOR};
    border-right-color: transparent;
  }
  100% {
    width: calc(100% + 8px);
    height: calc(100% + 8px);
    border-top-color: ${GL_COLORS.ACCENT_COLOR};
    border-bottom-color: transparent;
    border-left-color: ${GL_COLORS.ACCENT_COLOR};
    border-right-color: transparent;
  }
`;

const animationHeight = keyframes`
  to {
    height: 50%;
  }
`;

const animationBorderRight = keyframes`
  0% {
    width: 0;
    height: 0;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
  }
  100% {
    width: calc(100% + 8px);
    height: 2%;
    border-top-color: transparent;
    border-bottom-color: ${GL_COLORS.ACCENT_COLOR};
    border-left-color: transparent;
    border-right-color: transparent;
  }
`;

export const TagCardContainer = styled(GLCardTemplate.Root)`
  cursor: pointer;
  transition: all 0.3s;

  &.tag-card-active {
    border-color: ${GL_COLORS.ACCENT_COLOR};
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateX(calc(100% + 9px)) translateY(-50%);

      right: 0px;

      height: 1px;
      background-color: ${GL_COLORS.ACCENT_COLOR};
      animation: ${animationWidth} 0.3s forwards 0.5s;
    }

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%) translateX(100%);

      right: 0px;

      border-top: 10px solid transparent;
      border-bottom: 10px solid transparent;
      border-left: 10px solid ${GL_COLORS.ACCENT_COLOR};
      border-right: 10px solid transparent;
    }
  }
`;

export interface ButtonStyledProps {
  width?: string;
  height?: string;
  hoverColor?: string;
}

export const ButtonStyled = styled.button<ButtonStyledProps>`
  border: none;
  border-radius: 10px;
  transition: all 0.4s;
  background-color: transparent;
  padding: 10px;

  width: ${({ width }) => width || 'fit-content'};
  height: ${({ height }) => height || 'fit-content'};

  cursor: pointer;

  &:hover {
    background-color: ${({ hoverColor }) =>
      hoverColor || GL_COLORS.HOVER_COLOR_PRIMARY};
  }
`;

export const TagContentContainer = styled(GLBox)`
  position: relative;

  .tags-content-overlay {
    position: absolute;
    border: 3px solid transparent;
    background-color: transparent;
    z-index: -1;
    border-radius: 8px;
    left: -3px;

    opacity: 1;
    transition: all 0.4s;
  }

  &.tag-content-active {
    .tags-content-overlay.left {
      top: 50%;
      transform: translateY(-50%);
      animation: ${animationBorder} ${ANIMATION_BORDER_LEFT_TIME + 's'} forwards
        ${ANIMATION_BORDER_LEFT_DELAY + 's'};

      &::after {
        content: '';
        position: absolute;
        right: 0px;
        top: 0px;
        width: 3px;
        height: 0%;
        border-radius: 8px;
        background-color: ${GL_COLORS.ACCENT_COLOR};
        animation: ${animationHeight}
          ${ANIMATION_BORDER_LEFT_PSEUDOELEMENT_TIME + 's'} forwards
          ${ANIMATION_BORDER_LEFT_PSEUDOELEMENT_DELAY + 's'};
      }

      &::before {
        content: '';
        position: absolute;
        right: 0px;
        bottom: 0px;
        width: 3px;
        height: 0%;
        border-radius: 8px;
        background-color: ${GL_COLORS.ACCENT_COLOR};
        animation: ${animationHeight}
          ${ANIMATION_BORDER_LEFT_PSEUDOELEMENT_TIME + 's'} forwards
          ${ANIMATION_BORDER_LEFT_PSEUDOELEMENT_DELAY + 's'};
      }
    }

    .tags-content-overlay.right {
      bottom: -3px;
      animation: ${animationBorderRight} ${ANIMATION_BORDER_RIGHT_TIME + 's'}
        forwards ${ANIMATION_BORDER_RIGHT_DELAY + 's'};
    }
  }
`;
