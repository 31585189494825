import {
  GLBox,
  GLButton,
  GLCheckbox,
  GLTextField,
  GLTooltip,
} from '@group-link-one/grouplink-components';

import { useModalCreateTagContent } from './useModalCreateTagContent';

export const ModalCreateTagContent = (): JSX.Element => {
  const {
    state,
    actions,
    isLoading,
    t,
    errors,
    isMobile,
    onSubmit,
    verifyButtonText,
    handleSubmit,
    register,
    setValue,
  } = useModalCreateTagContent();

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{
        minWidth: !isMobile ? 500 : '100%',
        maxWidth: 500,
      }}
    >
      <GLBox direction="column">
        {state.modalType === 'creation' && (
          <GLTextField
            title={t('tags.content.modal.createNewTag.keyName')}
            placeholder={t(
              'tags.content.modal.createNewTag.placeholder.keyName',
            )}
            {...register('keyName', {
              onChange: (e) => {
                const newValue = e.target.value.replace(/ /g, '_');

                setValue('keyName', newValue, { shouldValidate: true });
              },
            })}
            isRequired
            failure={!!errors.keyName?.message}
            failureMessage={errors.keyName?.message}
            textFieldContainerStyle={{
              height: 100,
            }}
          />
        )}

        <GLTextField
          title={t('tags.content.modal.createNewTag.label')}
          placeholder={t('tags.content.modal.createNewTag.placeholder.label')}
          defaultValue={
            state.modalType === 'edition' ? state.activeTag?.key?.label : ''
          }
          {...register('label')}
          isRequired
          failure={!!errors.label?.message}
          failureMessage={errors.label?.message}
          textFieldContainerStyle={{
            height: 100,
          }}
        />

        <GLTextField
          title={t('tags.content.modal.createNewTag.description')}
          placeholder={t(
            'tags.content.modal.createNewTag.placeholder.description',
          )}
          defaultValue={
            state.modalType === 'edition'
              ? state?.activeTag?.key?.description
              : ''
          }
          {...register('description')}
          textFieldContainerStyle={{
            height: 100,
          }}
        />

        <GLTooltip
          text={t('tags.content.modal.createNewTag.tooltip')}
          position="top-right"
          width={150}
          tooltipBoxStyle={{
            zIndex: 9999,
          }}
          tooltipTextStyle={{
            zIndex: 9999,
          }}
        >
          <GLCheckbox
            iconType="check"
            text={t('tags.content.modal.createNewTag.isStrict')}
            checked={
              state.modalType === 'edition'
                ? state?.activeTag?.is_strict
                : state?.isStrict
            }
            onChange={() => {
              if (state.modalType === 'edition') {
                if (state.activeTag?.key) {
                  return actions.setActiveTag({
                    ...state.activeTag,
                    is_strict: !state.activeTag.is_strict,
                  });
                }
              }
              actions.setIsStrict(!state.isStrict);
            }}
          />
        </GLTooltip>

        <GLButton
          text={verifyButtonText()}
          align="center"
          type="submit"
          variant="fill"
          weight={600}
          isLoading={isLoading}
          disabled={isLoading}
          style={{
            marginTop: 30,
          }}
        />
      </GLBox>

      {/*<GLTipsCard icon={<GL_TAG />} title="" />*/}
    </form>
  );
};
