import {
  GL_BAR_CHART,
  GL_GLStation,
  GL_HEART,
  GL_SETTINGS,
  GL_SHARE,
  GLAsideItemProps,
  useGLMenuMobile,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { usePrivileges } from '../../hooks/usePrivilleges';
import { verifyIfPathIsActives } from '../../utils/verifyIfPathIsActive';
import { useAuth, useGLFlags } from '@group-link-one/gl-utils';

export enum MainRoutes {
  SETTINGS = '/settings',
  REPORTS = '/reports',
  DEVICE_LIST = '/devices',
  GROUP_LIST = '/devices/groups',

  HEALTH_CHECK = '/health-check',
  HEALTH_CHECK_MAP = '/health-check/map',
  DEVICE_TAGS = '/devices/tags',

  EVENT_LIST = '/network/event-list',
}

interface MainLayout {
  navListTop: GLAsideItemProps[];
  navListBottom: GLAsideItemProps[];
}

export const useMainLayout = (): MainLayout => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { handleOpenMenuMobile, isMenuMobileOpen } = useGLMenuMobile();
  const { flags } = useGLFlags();
  const { pages } = usePrivileges();
  const { user } = useAuth();

  const navListTop: GLAsideItemProps[] = useMemo(() => {
    const allPages: GLAsideItemProps[] = [
      // Reports Page
      {
        id: 1,
        Icon: GL_BAR_CHART,
        text: t('pages.reports.title'),
        isToAppear: pages.reports,
        active: verifyIfPathIsActives(MainRoutes.REPORTS),
        dropdownItems: [],
        onClick: () => {
          navigate(MainRoutes.REPORTS);
        },
      },
      // Devices Page
      {
        id: 2,
        Icon: GL_GLStation,
        text: t('deviceList.title'),
        active: verifyIfPathIsActives(MainRoutes.DEVICE_LIST),
        isToAppear:
          pages.deviceList || pages.deviceGroupList || pages.deviceTags,
        dropdownItems: [
          // Device List Page
          {
            id: 1,
            text: t('deviceList.subtitle'),
            isToAppear: pages.deviceList,
            active: verifyIfPathIsActives(MainRoutes.DEVICE_LIST),
            onClick: () => {
              navigate(MainRoutes.DEVICE_LIST);
            },
          },
          // Device Group List Page
          {
            id: 2,
            text: t('groupList.subtitle'),
            isToAppear: pages.deviceGroupList,
            active: verifyIfPathIsActives(MainRoutes.GROUP_LIST),
            onClick: () => {
              navigate(MainRoutes.GROUP_LIST);
            },
          },
          // Device Tags Page
          {
            id: 3,
            text: t('Tags'),
            isToAppear: pages.deviceTags,
            active: verifyIfPathIsActives(MainRoutes.DEVICE_TAGS),
            onClick: () => {
              navigate(MainRoutes.DEVICE_TAGS);
            },
          },
        ],
        onClick: () => {},
      },
      // Health Check Page
      {
        id: 3,
        Icon: GL_HEART,
        text: t('healthCheck.title'),
        active: verifyIfPathIsActives(MainRoutes.HEALTH_CHECK),
        isToAppear: pages.healthCheck,
        onClick: () => {
          navigate(MainRoutes.HEALTH_CHECK);
        },
      },
      // Event List Page
      {
        id: 4,
        Icon: GL_SHARE,
        text: t('eventList.subtitle'),
        isToAppear: pages.eventList,
        active: verifyIfPathIsActives(MainRoutes.EVENT_LIST, false),
        dropdownItems: [],
        onClick: () => navigate(MainRoutes.EVENT_LIST),
      },
    ];

    return allPages
      .filter((item) => {
        if (item.isToAppear) {
          return item;
        }
      })
      .map((item) => {
        if (item.dropdownItems?.length) {
          const newItem = {
            ...item,
            dropdownItems: item.dropdownItems.filter((dropdownItem) => {
              if (dropdownItem.isToAppear) {
                return dropdownItem;
              }
            }),
          };

          return newItem;
        }

        return item;
      });
  }, [
    flags, // para quando as flags carregarem
    user, // para quando realizar o impersonate
    pages, // ao realizar impersonate, os privilégios mudam
  ]);

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SETTINGS,
      text: t('pages.settings.title'),
      active: verifyIfPathIsActives(MainRoutes.SETTINGS, false),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  const removeStyle = (): void => {
    const container = document.querySelector('.page-animated');
    if (container) {
      container.removeAttribute('style');
    }
  };

  useEffect(() => {
    if (isMenuMobileOpen) {
      handleOpenMenuMobile();
    }

    removeStyle();
  }, [navigate]);

  return { navListTop, navListBottom };
};
