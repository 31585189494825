import { GLLogin } from '@group-link-one/grouplink-components';

import { useLogin } from './useLogin';

export const Login = (): JSX.Element => {
  const {
    loginErrorMessage,
    redirectToForgotPassword,
    sendForm,
    microsoftToken,
    showFeature,
  } = useLogin();

  return (
    <GLLogin
      loginErrorMessage={String(loginErrorMessage)}
      onRedirectToForgotPassword={redirectToForgotPassword}
      onSubmit={sendForm}
      onRedirectToMicrosoftLogin={microsoftToken}
      platform="utilities"
      hasPrivilege={showFeature('action_btn_login_microsoft')}
    />
  );
};
