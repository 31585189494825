import { User } from '@group-link-one/gl-utils';
import useAxiosHTTPRequest from '../useAxiosHTTPRequest';

export type MyAccForm = {
  name?: string | undefined;
  last_name?: string | undefined | null;
  email?: string | undefined;
  phone?: string | undefined | null;
  job_title?: string | undefined | null;
  organization?: string | undefined;
  mask?: string | undefined;
};

interface IMyAccountData {
  session: User;
}

type MyAccountResponse = {
  status: number;
};

interface IMyAccountService {
  updateMyAccount: (data?: MyAccForm) => Promise<MyAccountResponse>;
  getMyAccount: () => Promise<IMyAccountData>;
}

export const useMyAccountService = (): IMyAccountService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const updateMyAccount = async (
    data?: MyAccForm,
  ): Promise<MyAccountResponse> => {
    return await httpPrivate.patch('/my-account', data);
  };

  const getMyAccount = async (): Promise<IMyAccountData> => {
    return (await httpPrivate.get('/my-account')).data;
  };

  return {
    updateMyAccount,
    getMyAccount,
  };
};
