import {
  ICodeParams,
  ILoginSecurityData,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';

export interface Session {
  id: string;
  ip: string;
  user_agent: string;
  expired_at: string;
  created_at: string;
  can_logout: boolean;
}

export type Sessions<TData> = {
  has_more: boolean;
  next_page_token: string | null;
  count?: number;
  rows: TData[];
};

type SendCodeResponse = {
  data: string;
};

type UpdatePasswordLoggedResponse = {
  status: number;
};

interface UseLoginSecurityServices {
  sendCode: (data: ICodeParams) => Promise<SendCodeResponse>;
  updatePasswordLogged: (
    body: ILoginSecurityData,
  ) => Promise<UpdatePasswordLoggedResponse>;
  getUserHistory: () => Promise<Sessions<Session>>;
}

export const useLoginSecurityServices = (): UseLoginSecurityServices => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const sendCode = async (data: ICodeParams): Promise<SendCodeResponse> => {
    return await httpPrivate.post('/send-code', data);
  };

  const updatePasswordLogged = async (
    body: ILoginSecurityData,
  ): Promise<UpdatePasswordLoggedResponse> => {
    return await httpPrivate.patch('/reset-password-logged', body, {
      method: 'PATCH',
    });
  };

  const getUserHistory = async (): Promise<Sessions<Session>> => {
    const response = await httpPrivate.get('/my-account/sessions?ipp=3');

    return response.data;
  };

  return {
    sendCode,
    updatePasswordLogged,
    getUserHistory,
  };
};
