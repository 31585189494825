import { GLMainTemplate } from '@group-link-one/grouplink-components';
import { Outlet } from 'react-router-dom';

import GLLogoDark from '../../images/Common/logo-glutilities-dark.svg';
import GLLogo from '../../images/Common/logo-glutilities-light.svg';
import { useMainLayout } from './useMainLayout';

export function MainLayout(): JSX.Element {
  const { navListTop, navListBottom } = useMainLayout();
  return (
    <GLMainTemplate
      asideOptions={{
        navListTop,
        navListBottom,
        logoOptions: {
          src: {
            logoLight: GLLogo,
            logoDark: GLLogoDark,
          },
          alt: 'Logo GL Utilities',
          clipPath: 27,
          position: 67,
        },
      }}
    >
      <Outlet />
    </GLMainTemplate>
  );
}
