import { GLModalRegisterDevice } from '@group-link-one/grouplink-components';

import { useDeviceListStore } from '../store/device-list-store';
import { useModalEditDevice } from './useModalEditDevice';

export function ModalEditDevice(): JSX.Element {
  const { onPlaceSelect, onEditDeviceFn } = useModalEditDevice();

  const { state: deviceListState } = useDeviceListStore();

  return (
    <GLModalRegisterDevice
      modalType={deviceListState.modalType}
      application="utilities"
      onRegisterDevice={(deviceData) => onEditDeviceFn(deviceData)}
      onPlaceSelect={(placeId) => onPlaceSelect(placeId)}
    />
  );
}
