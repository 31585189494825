export default `<svg width="67px" height="73px" viewBox="0 0 67 73" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>sl-on</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="sl-on" transform="translate(1, 1)">
            <g id="Group-11348" fill="#E2E8EF" fill-rule="nonzero" stroke="#E2E8EF" stroke-opacity="0">
                <g id="icon-pin" opacity="0.3" transform="translate(0, 54.0021)">
                    <path d="M30.9995,16.9995 L52.9195,12.0205 C65.0255,9.2705 65.0255,4.8125 52.9195,2.0625 L52.9195,2.0625 C40.8135,-0.6875 21.1855,-0.6875 9.0795,2.0625 L9.0795,2.0625 C-3.0265,4.8125 -3.0265,9.2705 9.0795,12.0205 L30.9995,16.9995 Z" id="Subtraction_1"></path>
                </g>
                <g id="icon-pin-2" transform="translate(0.0001, 0)">
                    <path d="M30.9994048,69.0006259 L52.9194048,52.8566259 C58.7326282,47.0534671 61.9993105,39.1766516 61.9993105,30.9626259 C61.9993105,22.7486002 58.7326282,14.8717847 52.9194048,9.06862593 L52.9194048,9.06862593 C40.8072873,-3.02287531 21.1915223,-3.02287531 9.0794048,9.06862593 L9.0794048,9.06862593 C3.26648776,14.8717444 -8.73866951e-16,22.7483455 -8.73866951e-16,30.9621259 C-8.73866951e-16,39.1759064 3.26648776,47.0525074 9.0794048,52.8556259 L30.9994048,69.0006259 Z" id="Subtraction_1-2"></path>
                </g>
            </g>
            <g id="icon-idea" transform="translate(16.001, 18)">
                <rect id="Retângulo_4897" x="0" y="0" width="30" height="30"></rect>
                <path d="M13.945,29.997 C12.0002771,29.9959042 10.4229593,28.4217169 10.418,26.477 L10.418,23.395 C10.418,21.235 9.428,19.936 8.17,18.29 C6.67,16.325 4.97,14.097 4.957,10.071 L4.957,10.007 C5.03477472,4.47610207 9.54055527,0.0332357198 15.072,0.0332357198 C20.6034447,0.0332357198 25.1092253,4.47610207 25.187,10.007 L25.187,10.071 C25.167,14.096 23.468,16.325 21.968,18.29 C20.714,19.936 19.726,21.235 19.726,23.395 L19.726,26.477 C19.719941,28.42204 18.1420492,29.9959077 16.197,29.997 L13.945,29.997 Z M12.769,26.477 C12.7712038,27.1249242 13.297072,27.649 13.945,27.649 L16.197,27.649 C16.8442777,27.649 17.369,27.1242777 17.369,26.477 L17.369,24.194 L12.769,24.194 L12.769,26.477 Z M7.308,10.043 C7.318,13.297 8.644,15.035 10.042,16.871 C11.3065183,18.2890681 12.1967971,20.0005347 12.632,21.85 L17.505,21.85 C17.9418342,20.0011405 18.8319143,18.290056 20.095,16.871 C21.495,15.035 22.825,13.297 22.833,10.043 C22.7847302,5.79025557 19.3235184,2.36810647 15.0705,2.36810647 C10.8174816,2.36810647 7.3562698,5.79025557 7.308,10.043 L7.308,10.043 Z" id="União_25" fill="#333366" fill-rule="nonzero"></path>
            </g>
            <g id="Group-11349" transform="translate(43.4995, 41.4996)" fill="#00FFAA" fill-rule="nonzero" stroke="#00FFAA" stroke-opacity="0.200000003" stroke-width="8">
                <circle id="Ellipse_2765" cx="9" cy="9" r="9"></circle>
            </g>
        </g>
    </g>
</svg>`;
