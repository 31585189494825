import {
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GLBox,
  GLButton,
  GLCheckbox,
  GLDropdown,
  GLTransferList,
  GLTypography,
} from '@group-link-one/grouplink-components';

import { useDevicesSteps } from './useDevicesStep';

export const DevicesStep = (): JSX.Element => {
  const {
    t,
    devicesAddeds,
    availableDevicesSearchIsCorrect,
    devicesAvailables,
    groupDevicesState,
    leftSideOptions,
    rightSideOptions,
    tagsStoreActions,
    tagsStoreState,
    onSearch,
    onTransferItem,
    onAssignDeviceToTag,
  } = useDevicesSteps();

  return (
    <GLBox direction="column">
      <GLBox
        direction={'row'}
        style={{
          marginTop: 20,
        }}
      >
        <GLBox direction={'column'}>
          <GLTypography
            text={t(
              'tags.content.modal.assignTagToDevice.form.selectValue.label',
            )}
            weight={600}
          />

          <GLDropdown.Root width="50%">
            <GLDropdown.Input
              icon={<GL_CHEVRON_DOWN fill={GL_COLORS.FONT_COLOR} size={16} />}
              value={tagsStoreState.selectedTagValue?.value}
              placeholder={t(
                'tags.content.modal.assignTagToDevice.form.selectValue.placeholder',
              )}
            />

            {tagsStoreState?.activeTagValues?.length !== 0 ? (
              <GLDropdown.BoxOptions>
                {tagsStoreState.activeTagValues.map(({ id, value }) => (
                  <GLDropdown.Option
                    text={value}
                    hasToShowSelected={
                      id === tagsStoreState.selectedTagValue?.id
                    }
                    onClick={() =>
                      tagsStoreActions.setSelectedTagValue({
                        id,
                        value,
                      })
                    }
                    key={id}
                  />
                ))}
              </GLDropdown.BoxOptions>
            ) : (
              <GLDropdown.BoxOptions
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: 20,
                }}
              >
                <GLTypography text="Essa tag não possui nenhum valor" />
              </GLDropdown.BoxOptions>
            )}
          </GLDropdown.Root>
          <GLBox
            direction={'column'}
            style={{
              marginTop: 20,
            }}
          >
            <GLBox direction={'column'}>
              <GLTypography
                text={t(
                  'tags.content.modal.assignTagToDevice.form.keepExistingTags.label',
                )}
                weight={600}
              />

              <GLCheckbox
                iconType="check"
                checked={tagsStoreState.preserveExistingTags}
                onChange={() =>
                  tagsStoreActions.setPreserveExistingTags(
                    !tagsStoreState.preserveExistingTags,
                  )
                }
              />
            </GLBox>
          </GLBox>
        </GLBox>
      </GLBox>

      <GLBox
        direction={'column'}
        style={{
          marginTop: 20,
        }}
      >
        <GLBox direction={'column'}>
          <GLTypography
            text={t('tags.content.modal.assignTagToDevice.form.devices.label')}
            weight={600}
          />
          <GLTransferList
            leftSideList={devicesAvailables}
            leftSideOptions={leftSideOptions}
            leftSideTooltipIsOpen={!availableDevicesSearchIsCorrect}
            leftSideTooltipText={t('deviceList.search.tooltip')}
            leftSideIsFetching={
              groupDevicesState.isFetchingMoreAvailableDevices
            }
            leftSideSearchHasFailure={true}
            leftSideTexts={{
              search: t(
                'groupsDevices.addDevicesModal.transferList.leftSide.search',
              ),
              emptyTitle:
                groupDevicesState.availableDevicesSearch.length === 0
                  ? t(
                      'tags.content.modal.assignTagToDevice.form.devices.emptyAvailablesDevices.title',
                    )
                  : t(
                      'groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.title',
                    ),
              emptyBody:
                groupDevicesState.availableDevicesSearch.length === 0
                  ? t('')
                  : t(
                      'groupsDevices.addDevicesModal.transferList.leftSide.emptyStateFromSearch.description',
                    ),
            }}
            rightSideList={devicesAddeds}
            rightSideOptions={rightSideOptions}
            rightSideTooltipIsOpen={false}
            rightSideTooltipText={undefined}
            rightSideIsFetching={groupDevicesState.isFetchingMoreDevicesInGroup}
            rightSideTexts={{
              search: t(
                'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
              ),
              emptyTitle:
                groupDevicesState.devicesInGroupSearch.length === 0
                  ? t(
                      'tags.content.modal.assignTagToDevice.form.devices.emptyAddedDevice.title',
                    )
                  : t(
                      'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
                    ),
              emptyBody:
                groupDevicesState.devicesInGroupSearch.length === 0
                  ? t(
                      'tags.content.modal.assignTagToDevice.form.devices.emptyAddedDevice.description',
                    )
                  : t(
                      'tags.content.modal.assignTagToDevice.form.transferList.rightContentTitle',
                    ),
            }}
            onTransferItemToLeft={(items) => onTransferItem(items, 'left')}
            onTransferItemToRight={(items) => onTransferItem(items, 'right')}
            onSearch={(value, side) => onSearch(value, side)}
          />
        </GLBox>
      </GLBox>

      <GLBox
        width={'100%'}
        justify={'flex-end'}
        style={{
          paddingBottom: 100,
        }}
      >
        <GLButton
          variant="outline"
          text={t('tags.content.modal.assignTagToDevice.form.btnCancel')}
          align="center"
          style={{
            width: 'fit-content',
          }}
          weight={600}
          padding
          border
          onClick={() => {
            tagsStoreActions.setModalAssignToDeviceIsOpen(false);
          }}
        />

        <GLButton
          variant="fill"
          text={t('tags.content.modal.assignTagToDevice.form.btnAssign')}
          align="center"
          style={{
            width: 'fit-content',
          }}
          weight={600}
          padding
          onClick={() => onAssignDeviceToTag()}
        />
      </GLBox>
    </GLBox>
  );
};
