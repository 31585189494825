import { GLSettingsGroupsUsers } from '@group-link-one/grouplink-components';

import { GroupUsersTransferList } from './TransferList/GroupUsersTransferList';
import { useOrganizationGroups } from './useOrganizationGroups';

export const GroupsUsersPage = (): JSX.Element => {
  const {
    groups,
    features,
    listGroups,
    showFeature,
    createGroup,
    updateGroup,
    deleteGroup,
  } = useOrganizationGroups();

  return (
    <GLSettingsGroupsUsers
      flagsOptions={{
        showFilterSearch: features.userGroup.showFilterSearch,
        showBtnCreate: features.userGroup.showBtnCreate,
        showBtnSetDefault: features.userGroup.showBtnSetDefault,
        showBtnEdit: features.userGroup.showBtnEdit,
      }}
      groups={groups}
      transferListContent={<GroupUsersTransferList />}
      onListGroups={listGroups}
      onCreateGroup={createGroup}
      onUpdateGroup={updateGroup}
      onDeleteGroupById={deleteGroup}
    />
  );
};
