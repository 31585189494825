import {
  GL_COLORS,
  GL_FILTER,
  GLBox,
  GLButton,
} from '@group-link-one/grouplink-components';

import { useRightFilters } from './useRightFilters';

export function RightFilters(): JSX.Element {
  const { t, is500px, features, onClickFilter } = useRightFilters();

  return (
    <GLBox
      width="fit-content"
      justify="center"
      align="center"
      style={{
        position: 'absolute',
        top: !is500px ? 40 : 100,
        right: 40,
      }}
    >
      {features.healthCheck.filters.button && (
        <GLButton
          variant="outline"
          icon={<GL_FILTER size={18} fill={GL_COLORS.NAVY} />}
          border
          colorIconDynamically
          text={t('deviceList.search.filter')}
          align="center"
          iconPosition="right"
          weight={600}
          style={{
            width: 'fit-content',
            paddingInline: 20,
          }}
          onClick={onClickFilter}
        />
      )}
    </GLBox>
  );
}
