import {
  GLBox,
  GLExtendedTextField,
  GLSelectMultiple,
  GLSelectMultipleOptions,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useCasesMap } from '../../../DeviceList/useDeviceListBody';
import { useImpersonateContentStore } from '../../store/impersonateContent.store';
import { memo } from 'react';

function OrganizationFilterMemo(): JSX.Element {
  const { state: impersonateContentState, actions: impersonateContentActions } =
    useImpersonateContentStore();

  function onSelectUseCase(
    useCaseOptionsSelecteds: GLSelectMultipleOptions[],
  ): void {
    impersonateContentActions.setUseCaseOptionsSelecteds(
      useCaseOptionsSelecteds,
    );
  }

  const { t } = useI18n();

  return (
    <GLBox>
      {impersonateContentState.useCaseOptionsAvailables && (
        <GLSelectMultiple
          options={impersonateContentState.useCaseOptionsAvailables}
          optionsSelected={impersonateContentState.useCasePreSelected || []}
          canSelectMultiple={false}
          hasCheckbox={false}
          isAsynchronous
          onSelectedOptionsChange={(selectedOptions) => {
            if (onSelectUseCase && selectedOptions.length > 0) {
              setTimeout(() => {
                onSelectUseCase(selectedOptions);
              }, 300);
            } else {
              impersonateContentActions.setUseCaseOptionsSelecteds([
                useCasesMap.find((useCase) => useCase.id === 'water')!,
              ]);
              impersonateContentActions.setUseCasePreSelected([
                useCasesMap.find((useCase) => useCase.id === 'water')!,
              ]);
            }
          }}
          inputOptions={{
            width: 'fit-content',
            layout: 'size',
            style: {
              minWidth: '240px',
              height: '46.25px',
            },
            placeholder: t('deviceList.selectUseCase.placeholder'),
          }}
          rootOptions={{
            width: 'fit-content',
          }}
        />
      )}

      <GLExtendedTextField
        value={impersonateContentState.textToSearchOrg}
        onChange={(e) =>
          impersonateContentActions.setOrganizationTextToSearch(e.target.value)
        }
      />
    </GLBox>
  );
}

export const OrganizationFilter = memo(OrganizationFilterMemo);
