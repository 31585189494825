import {
  IGroupsUser,
  useGLPagination,
  useGroupsUsersStore,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useOrganizationGroupsService } from '../../../Services/organizationGroupsService/useOrganizationGroups';
import { ICreateGroups, IEditGroups } from './types/types';
import { useMemo } from 'react';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import * as Sentry from '@sentry/react';
import { useGLFlags } from '@group-link-one/gl-utils';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';

interface IOrganizationProps {
  groups: IGroupsUser[] | undefined;
  features: Features;
  listGroups: () => Promise<void>;
  createGroup: ({
    name,
    description,
    customer_ids,
    is_default,
  }: ICreateGroups) => Promise<void>;
  updateGroup: (
    id: number,
    { name, description, customer_ids, is_default }: Partial<IEditGroups>,
  ) => Promise<void>;
  deleteGroup: (id: number) => Promise<void>;
  showFeature: (feature: string) => boolean;
}

export const useOrganizationGroups = (): IOrganizationProps => {
  const { logEventAnalytics } = useFBAnalytics();
  const {
    getOrganizationGroups,
    createOrganizationGroup,
    updateOrganizationGroup,
    deleteOrganizationGroup,
  } = useOrganizationGroupsService();

  const { showFeature } = useGLFlags();

  const { addToast } = useToast();
  const { t } = useI18n();
  const { actions: groupsUsersStoreActions } = useGroupsUsersStore();
  const queryClient = useQueryClient();

  const { state: paginationStoreState, actions: paginationStoreActions } =
    useGLPagination();

  const { features } = usePrivileges();

  const refetchGroups = (): void => {
    queryClient.refetchQueries({
      queryKey: ['get-groups-users'],
    });
  };

  const listGroups = async (): Promise<void> => {
    await getOrganizationGroups({})
      .then(() => {})
      .catch((err) => {
        Sentry.captureException(err);
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });

        return err;
      });
  };

  const updateGroup = async (
    id: number,
    { name, description, customer_ids, is_default }: Partial<IEditGroups>,
  ): Promise<void> => {
    groupsUsersStoreActions.setIsLoading(true);
    if (!id) {
      return;
    }
    updateOrganizationGroup(id, {
      name,
      description,
      customer_ids,
      is_default,
    })
      .then(() => {
        addToast({
          title: t('groupsUsers.toast.successUpdate.title'),
          message: t('groupsUsers.toast.successUpdate.message'),
          type: 'success',
        });

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.GROUP_USERS_EDIT,
          eventDescription: 'Group users edit',
        });

        refetchGroups();
        groupsUsersStoreActions.setOpenMakeDefaultModal(false);
        groupsUsersStoreActions.setOpenModal(false);
      })
      .catch(() => {
        addToast({
          title: t('groupsUsers.toast.errorUpdate.title'),
          message: t('groupsUsers.toast.errorUpdate.message'),
          type: 'error',
        });
        return;
      })
      .finally(() => {
        groupsUsersStoreActions.setIsLoading(false);
      });
  };

  const deleteGroup = async (id: number): Promise<void> => {
    deleteOrganizationGroup(id)
      .then(() => {
        addToast({
          title: t('groupsUsers.toast.successDelete.title'),
          message: t('groupsUsers.toast.successDelete.message'),
          type: 'success',
        });

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.GROUP_USERS_DELETE,
          eventDescription: 'Group users delete',
        });

        refetchGroups();
        groupsUsersStoreActions.setOpenDeleteModal(false);
        groupsUsersStoreActions.setOpenModal(false);
      })
      .catch((err) => {
        Sentry.captureException(err);
        addToast({
          title: t('groupsUsers.toast.errorDelete.title'),
          message: t('groupsUsers.toast.errorDelete.message'),
          type: 'error',
        });

        return err;
      })
      .finally(() => {
        groupsUsersStoreActions.setIsLoading(false);
      });
  };

  const createGroup = async ({
    name,
    description,
    customer_ids,
    is_default,
  }: ICreateGroups): Promise<void> => {
    groupsUsersStoreActions.setIsLoading(true);

    createOrganizationGroup({
      name,
      description,
      customer_ids,
      is_default,
    })
      .then((res) => {
        groupsUsersStoreActions.setOpenModal(false);
        if (res?.status === 201) {
          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.GROUP_USERS_CREATE,
            eventDescription: 'Group users create',
          });

          addToast({
            title: t('groupsUsers.toast.success.title'),
            message: t('groupsUsers.toast.success.message'),
            type: 'success',
          });
          refetchGroups();
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        if (err?.response?.status === 400) {
          addToast({
            title: t('groupsUsers.toast.error.title'),
            message: t('groupsUsers.toast.error.messageOne'),
            type: 'error',
          });
          return;
        }
        addToast({
          title: t('groupsUsers.toast.error.title'),
          message: t('groupsUsers.toast.error.message'),
          type: 'error',
        });
      })
      .finally(() => {
        groupsUsersStoreActions.setIsLoading(false);
      });
  };

  const groupUsersSearch = useMemo(() => {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.GROUP_USERS_SEARCH,
      eventDescription: 'Group users search',
      param1: paginationStoreState.search,
    });

    return paginationStoreState.search;
  }, [paginationStoreState.search]);

  const { data: groups } = useQuery({
    queryKey: [
      'get-groups-users',
      groupUsersSearch,
      paginationStoreState.currentPage,
    ],
    queryFn: async () => {
      const { optionsToStoreNextPageToken } =
        paginationStoreActions.getNextPageToken();

      const getGroupsData = await getOrganizationGroups({
        name: groupUsersSearch,
      });

      paginationStoreActions.setNextPageToken({
        hasMore: getGroupsData.has_more,
        nextPageToken: getGroupsData.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return getGroupsData.rows;
    },
    staleTime: 1000 * 60 * 10,
  });

  return {
    groups,
    features,
    showFeature,
    listGroups,
    createGroup,
    updateGroup,
    deleteGroup,
  };
};
