import {
  GL_COLORS,
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
} from '@group-link-one/grouplink-components';
import { useMediaQuery } from 'usehooks-ts';

import { useExtractURLSearch } from '../../../hooks/useExtractURLSearch';
import {
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../store/details-device-store';
import { useGetDailyReadings } from './Graphs/useGetDailyReadings';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

interface IUseModalDeviceDetails {
  isOpen: boolean;
  detailsDeviceState: DetailsDeviceStoreState;
  relativeFilterTimeOptions: GLRelativeFilterTimeProps;
  onClose: () => void;
}

export const useModalDeviceDetails = (): IUseModalDeviceDetails => {
  const { logEventAnalytics } = useFBAnalytics();
  const { state: detailsDeviceState, actions: detailsDeviceActions } =
    useDetailsDeviceStore();

  const { getDailyReadings } = useGetDailyReadings();
  const { setURLParams } = useExtractURLSearch();

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    rootOptions: {
      width: !isMobile ? 'fit-content' : '100%',
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? 'transparent' : 'inherit',
        alignItems: isMobile ? 'flex-end' : 'initial',
      },
    },
    inputOptions: {
      width: 'fit-content',
    },
    boxOptions: {
      position: 'bottom-right',
      style: {
        boxShadow: `0px 4px 4px ${GL_COLORS.SHADOW_COLOR}`,
      },
    },
    currentRelativeTime: detailsDeviceState.currentRelativeTime,
    currentDateRange: detailsDeviceState.currentDateRange,

    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_LIST_RELATIVE_DATE_FILTER,
        eventDescription: 'Relative Date Filter',
        param1: JSON.stringify(option),
      });
      detailsDeviceActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: GLDateRangeType) => {
      detailsDeviceActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: GLDateRangeType) => {
      if (!detailsDeviceState.device) {
        return;
      }

      const { device_id } = detailsDeviceState.device;
      if (!range?.from || !range?.to || !device_id) {
        return;
      }

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_LIST_DATE_RANGE_FILTER,
        eventDescription: 'Date Range Filter',
        param1: JSON.stringify(range.from),
        param2: JSON.stringify(range.to),
      });

      detailsDeviceActions.setRange(range);
      detailsDeviceActions.setIsLoading(true);

      await Promise.all([
        getDailyReadings({ device_id, range }),
        // getDowReadings({ device_id, range }),
      ]);

      detailsDeviceActions.setIsLoading(false);
    },
  };

  function onClose(): void {
    detailsDeviceActions.resetAll();

    setURLParams({
      q: '',
    });
  }

  return {
    isOpen: detailsDeviceState.modalIsOpen,
    detailsDeviceState,
    relativeFilterTimeOptions,
    onClose,
  };
};
