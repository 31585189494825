import { GLModalDeviceDetails } from '@group-link-one/grouplink-components';

import { DeviceDetailsContent } from './Content/DeviceDetailsContent';
import { DeviceDetailsTitleContent } from './TitleContent/DeviceDetailsTitleContent';
import { useModalDeviceDetails } from './useModalDeviceDetails';

export function ModalDeviceDetails(): JSX.Element {
  const { relativeFilterTimeOptions, isOpen, onClose } =
    useModalDeviceDetails();

  return (
    <GLModalDeviceDetails
      titleContentLeft={<DeviceDetailsTitleContent />}
      content={<DeviceDetailsContent />}
      relativeFilterTimeOptions={relativeFilterTimeOptions}
      isOpen={isOpen}
      onClose={onClose}
    />
  );
}
