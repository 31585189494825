import {
  GLBox,
  GLBoxChart,
  useI18n,
} from '@group-link-one/grouplink-components';
import { GoogleMap, Marker } from '@react-google-maps/api';

import { useDeviceOnlyMap } from './useDeviceOnlyMap';

const containerStyle = {
  width: '100%',
  height: '100%',
};

export function DeviceOnlyMap(): JSX.Element {
  const {
    center,
    mapStyle,
    isLoaded,
    markerIcon,
    onLoad,
    onUnmount,
    onAddressClick,
  } = useDeviceOnlyMap();

  const { t } = useI18n();

  return isLoaded ? (
    <GLBoxChart
      title={t('deviceList.detailsModal.ut.map.title')}
      textSwitcherId="device-location"
      chart={
        <GLBox height={300} style={{ marginTop: -30 }}>
          <GoogleMap
            mapContainerStyle={{
              ...containerStyle,
              borderRadius: 8,
            }}
            options={{
              styles: mapStyle,
              disableDefaultUI: true,
              draggableCursor: 'default',
              draggingCursor: 'arrow',
              zoomControl: false,
              minZoom: 15,
              maxZoom: 15,
              gestureHandling: 'none',
              scrollwheel: false,
            }}
            center={center}
            zoom={5}
            onLoad={onLoad}
            onUnmount={onUnmount}
          >
            <Marker
              position={center}
              icon={markerIcon}
              onClick={onAddressClick}
            />
          </GoogleMap>
        </GLBox>
      }
    />
  ) : (
    <></>
  );
}
