import { useEffect, useRef } from 'react';
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts';

import { TagsStoreActions, useTagsStore } from '../stores/tags.store';
import { useI18n } from '@group-link-one/grouplink-components';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEvent } from '@/Context/FBAnalytics/types/FBAnalyticsEvent.types';

interface TagsRightContentProps {
  isMobile: boolean;
  actions: TagsStoreActions;
  inputSearchRef: React.RefObject<HTMLInputElement>;
  logEventAnalytics: (event: FBAnalyticsEvent) => void;
  t: (key: string) => string;
}

export const useTagsRightContent = (): TagsRightContentProps => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { actions } = useTagsStore();
  const isMobile = useMediaQuery('(max-width: 1024px)');

  const inputSearchRef = useRef<HTMLInputElement>(null);

  const handleInputChange = useDebounceCallback(
    async (event: KeyboardEvent) => {
      const value = (event.target as HTMLInputElement).value;
      actions.setSearch(value);
    },
    500,
  );

  useEffect(() => {
    if (!inputSearchRef.current) {
      return;
    }

    inputSearchRef.current.addEventListener('keyup', handleInputChange);

    return () => {
      if (!inputSearchRef.current) {
        return;
      }

      inputSearchRef.current.removeEventListener('keyup', handleInputChange);
    };
  }, []);

  return {
    isMobile,
    actions,
    inputSearchRef,
    logEventAnalytics,
    t,
  };
};
