import { getPerformance, FirebasePerformance } from 'firebase/performance';
import { FirebaseApp } from 'firebase/app';

let performance: FirebasePerformance | null = null;

function initializePerformance(FBApp: FirebaseApp): FirebasePerformance {
  performance = getPerformance(FBApp);
  return performance;
}

export { initializePerformance };
