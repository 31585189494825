import { GLPaginationProvider } from '@group-link-one/grouplink-components';

import { useReportList } from './hooks/useReportList';
import { ReportsListContent } from './ReportListContent';

export const Reports = (): JSX.Element => {
  const { tabs } = useReportList();

  return (
    <GLPaginationProvider tabs={tabs}>
      <ReportsListContent />
    </GLPaginationProvider>
  );
};
