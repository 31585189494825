import { useAuth } from '@group-link-one/gl-utils';
import {
  GetCustomRoleRow,
  useGLPagination,
  useUsersStore,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo } from 'react';

import { useOrganizationUsersService } from '../../../../Services/organizationUsersService/useOrganizationUsers';
import { IOptions } from '../types/types';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

export interface TabProps {
  id: number;
  name: string;
  active: boolean | undefined;
  count: number | undefined;
  onClick: () => void;
  cacheKey: string;
}

const IPP = 15;

export const useUsersContent = (): { options: IOptions } => {
  const { logEventAnalytics } = useFBAnalytics();
  const { user } = useAuth();
  const { actions, state } = useGLPagination();
  const { getUsers, getUsersCount, getInvites, getCustomRoles, getUserById } =
    useOrganizationUsersService();

  const { state: usersState, actions: usersActions } = useUsersStore();

  const activeTab = useMemo(() => {
    return actions.getActiveTabById(state.activeTabId);
  }, [state.activeTabId, state.tabs]);

  const usersSearch = useMemo(() => {
    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.USERS_SEARCH,
      eventDescription: 'User searched for users',
      param1: state.search,
    });

    return state.search;
  }, [state.search]);

  useQuery({
    queryKey: ['total-users-count', usersSearch],
    queryFn: async () => {
      const totalUsersCountResponse = await getUsersCount({
        search: usersSearch,
        ipp: IPP,
      });

      actions.tabsActions.setCount(
        state.tabs[0],
        totalUsersCountResponse.count,
      );

      return totalUsersCountResponse.count;
    },
    // staleTime: 1000 * 60 * 5,
  });

  useQuery({
    queryKey: ['total-active-users-count', usersSearch],
    queryFn: async () => {
      const totalActiveUsersCountResponse = await getUsersCount({
        search: usersSearch,
        ipp: IPP,
        active: true,
      });

      actions.tabsActions.setCount(
        state.tabs[1],
        totalActiveUsersCountResponse.count,
      );

      return totalActiveUsersCountResponse.count;
    },
    // staleTime: 1000 * 60 * 5,
  });

  useQuery({
    queryKey: ['total-inactive-users-count', usersSearch],
    queryFn: async () => {
      const totalInactiveUsersCountResponse = await getUsersCount({
        search: usersSearch,
        ipp: IPP,
        active: false,
      });

      actions.tabsActions.setCount(
        state.tabs[2],
        totalInactiveUsersCountResponse.count,
      );

      return totalInactiveUsersCountResponse.count;
    },
    // staleTime: 1000 * 60 * 5,
  });

  const { data: allCustomRoles } = useQuery({
    queryKey: ['custom-roles'],
    queryFn: async () => {
      const customRolesData = await getCustomRoles();
      usersActions.setCustomRolesAvailables(customRolesData.rows);
      return customRolesData.rows;
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { data: users, isLoading } = useQuery({
    queryKey: ['get-users', usersSearch, state.currentPage, activeTab?.active],
    queryFn: async () => {
      const { nextPageToken, optionsToStoreNextPageToken } =
        actions.getNextPageToken();

      const getUsersData = await getUsers({
        search: usersSearch,
        ipp: IPP,
        next_page_token: nextPageToken,
        active: activeTab?.active,
      });

      actions.setNextPageToken({
        hasMore: getUsersData.has_more,
        nextPageToken: getUsersData.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return getUsersData.rows;
    },
    staleTime: 1000 * 60 * 10,
  });

  const {
    data: invites,
    refetch,
    isLoading: invitesIsLoading,
  } = useQuery({
    queryKey: ['invites'],
    queryFn: async () => {
      const getInvitesData = await getInvites();

      const invitesFiltered = getInvitesData.rows.filter((invite) =>
        ['pending', 'pending_with_visit', 'expired'].includes(invite.status),
      );

      actions.tabsActions.setCount(state.tabs[3], invitesFiltered.length);

      return invitesFiltered;
    },
    // staleTime: 1000 * 60 * 5,
  });

  function resetPagination(): void {
    actions.setCurrentPage(0);
  }

  useEffect(() => {
    actions.tabsActions.setOnClick(state.tabs[0], () => {
      resetPagination();
      actions.setActiveTab(1);
    });

    actions.tabsActions.setOnClick(state.tabs[1], () => {
      resetPagination();
      actions.setActiveTab(2);
    });

    actions.tabsActions.setOnClick(state.tabs[2], () => {
      resetPagination();
      actions.setActiveTab(3);
    });

    actions.tabsActions.setOnClick(state.tabs[3], () => {
      resetPagination();
      actions.setActiveTab(4);
    });
  }, []);

  const options: IOptions = {
    user,
    users,
    invitesIsLoading,
    isLoading,
    setCurrentPage: actions.setCurrentPage,
    currentPage: state.currentPage,
    tabs: state.tabs,
    activeTab,
    invites,
    state,
    refetch,
  };

  async function getUserInfo(id: number): Promise<void> {
    const userInfo = await getUserById(id);

    if (userInfo && allCustomRoles) {
      const rolesSelected = userInfo.api_profile_access_ids
        .map((role) => {
          return allCustomRoles.find((customRole) => customRole.id === role);
        })
        .filter((role) => role) as GetCustomRoleRow[];

      usersActions.setCustomRolesSelected(rolesSelected);
      usersActions.setCurrentRoleCard(userInfo.current_external_role);
      usersActions.setRoleToUpdate(userInfo.current_external_role);
    }
  }

  useEffect(() => {
    if (usersState.userCardInfo.id && usersState.openRoleModal) {
      getUserInfo(usersState.userCardInfo.id);
    }
  }, [usersState.userCardInfo.id, usersState.openRoleModal]);

  return {
    options,
  };
};
