import { useAuth } from '@group-link-one/gl-utils';
import {
  GLSelectSearchOptions,
  RegisterDeviceStoreState,
  RegisteredDevice,
  useDeviceListStore as useGLDeviceListStore,
  useI18n,
  useRegisterDeviceStore,
  useToast,
} from '@group-link-one/grouplink-components';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';

import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import {
  ChannelData,
  EditDeviceBody,
} from '../../../Services/deviceListService/useDeviceListService.types';
import { useGoogleMapsService } from '../../../Services/googleMapsService/useGoogleMapsService';
import { useDeviceListStore } from '../store/device-list-store';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import * as Sentry from '@sentry/react';

interface IUseModalEditDevice {
  registerDeviceState: RegisterDeviceStoreState;
  onPlaceSelect: (placeId?: string) => Promise<void>;
  onEditDeviceFn: UseMutateAsyncFunction<
    | {
        ok: boolean;
      }
    | undefined,
    Error,
    RegisteredDevice,
    unknown
  >;
  autoCompleteOnEdit: () => Promise<never[] | undefined>;
}

const ZIP_CODE_REGEX = /^\d{5}-\d{3}$/;

export const useModalEditDevice = (): IUseModalEditDevice => {
  const { user } = useAuth();
  const { logEventAnalytics } = useFBAnalytics();
  const { state: registerDeviceState, actions: registerDeviceActions } =
    useRegisterDeviceStore();

  const { state: deviceListState } = useDeviceListStore();

  const { googleMapsIsLoaded, getSuggestions, getPlaceDetails } =
    useGoogleMapsService();

  const { state: deviceListStoreState } = useGLDeviceListStore();

  const { updateDevice, registerDevice } = useDeviceListService();
  const { addToast } = useToast();

  const { t } = useI18n();

  const queryClient = useQueryClient();

  function refetchDeviceListActivated(): void {
    queryClient.invalidateQueries({
      queryKey: ['devices-activated-last-readings'],
    });

    queryClient.invalidateQueries({
      queryKey: ['devices-pendings-last-readings'],
    });

    queryClient.invalidateQueries({
      queryKey: ['bignumbers-device-list'],
    });
  }

  async function onEditDevice(
    newData: RegisteredDevice,
  ): Promise<{ ok: boolean } | undefined> {
    try {
      if (!registerDeviceState.deviceId) {
        return;
      }

      const channelData: ChannelData = {
        channel: 0,
        channel_name: newData.deviceName || '',
        remote_id: newData.remoteId || '',
        current_reading: '0',
        pulse_factor_reference: 'null',
        read_whitelabel: '00000000000000',
      };

      const body: EditDeviceBody = {
        channel_data: [channelData],
        // device_measurement_category: registerDeviceState.deviceMeasurementCategory,
        device_id: Number(registerDeviceState.deviceId),
        gps_location: {
          address: newData.address || '',
          city: newData.city || '',
          state: newData.state || '',
          number: newData.complement || '',
          country: registerDeviceState.addressSelectedInfo.country || '',
          country_code: '',
          neighborhood: newData.state || '',
          zipcode: newData.cep.replace('-', '') || '',
          display_address: newData.address || '',
          lat: registerDeviceState.addressSelectedInfo.lat,
          long: registerDeviceState.addressSelectedInfo.lng,
          acc: 0,
        },
        is_draft: false,
        // read_device_type_hex: "0x08",
        //read_device_use_case_hex: "0x03"
      };

      if (deviceListState.modalType === 'register') {
        body.device_measurement_category = 'energy';
        body.is_web_activation = true;
        body.device_measurement_category =
          deviceListStoreState?.useCaseOptionsSelecteds?.[0]?.id ||
          user?.device_measurement_categories?.[0] ||
          '';
        body.read_device_type_hex = '0x08';
        body.read_device_use_case_hex = '0x03';
      }

      if (deviceListState.modalType === 'edit') {
        body.device_location = {
          display_address: newData.address || '',
          address: newData.address || '',
          number: newData.complement || '',
          neighborhood: newData.state || '',
          city: newData.city || '',
          state: newData.state || '',
          country: registerDeviceState.addressSelectedInfo.country || '',
          zipcode:
            newData.cep.replace('-', '') ||
            registerDeviceState.addressSelectedInfo.cep ||
            '',
          lat: registerDeviceState.addressSelectedInfo.lat,
          long: registerDeviceState.addressSelectedInfo.lng,
          acc: 0,
        };
      }

      deviceListState.modalType === 'register'
        ? await registerDevice(body)
        : await updateDevice(body);

      if (deviceListState.modalType === 'register') {
        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.DEVICE_LIST_REGISTER,
          eventDescription: 'End Register Device',
        });
      }

      if (deviceListState.modalType === 'edit') {
        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.DEVICE_LIST_EDIT,
          eventDescription: 'End Edit Device',
        });
      }

      addToast({
        title: t('deviceList.modalEdit.onSuccess.title'),
        message: t('deviceList.modalEdit.onSuccess.description'),
        type: 'success',
      });

      refetchDeviceListActivated();

      return { ok: true };
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof AxiosError) {
        const errorMessage = error.response?.data?.message || error.message;
        addToast({
          title: t('deviceList.modalEdit.onError.title'),
          message: errorMessage,
          type: 'error',
        });
      }

      return { ok: false };
    }
  }

  const { mutateAsync: onEditDeviceFn, isPending } = useMutation({
    mutationFn: onEditDevice,
  });

  async function onPlaceSelect(placeId?: string): Promise<void> {
    if (!placeId) {
      return;
    }

    const placeDetails = await getPlaceDetails(placeId);

    if (!placeDetails) {
      return;
    }

    const addressOptions = {
      address: placeDetails.formatted_address || '',

      cep:
        placeDetails.address_components?.find((component) =>
          component.types.includes("postal_code")
        )?.long_name || registerDeviceState.addressSelectedInfo.cep || '',

      country:
        placeDetails.address_components?.find((component) =>
          component.types.includes('country'),
        )?.long_name || '',

      city:
        placeDetails.address_components?.find((component) =>
          component.types.includes('administrative_area_level_2'),
        )?.long_name || '',

      state:
        placeDetails.address_components?.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.short_name || '',

      lat: placeDetails.geometry?.location?.lat() || 0,
      lng: placeDetails.geometry?.location?.lng() || 0,
    };

    registerDeviceActions.setAddressSelectedInfo(addressOptions);
  }

  const { data: filteredAddresses, isLoading } = useQuery({
    queryKey: ['filtered-addresses', registerDeviceState.addressSearch],
    queryFn: async () => {
      if (!registerDeviceState.addressSearch || !googleMapsIsLoaded) {
        return [];
      }

      const predictions = await getSuggestions(
        registerDeviceState.addressSearch,
      );

      if (predictions.length === 0) {
        registerDeviceActions.setAddressSelectedInfo({
          address: '',
          country: '',
          cep: '',
          city: '',
          state: '',
          lat: 0,
          lng: 0,
        });

        return [];
      }

      onPlaceSelect(predictions[0].place_id);

      return predictions;
    },
  });

  function formatAddress(
    filteredAddressesToFormat: google.maps.places.AutocompletePrediction[],
  ): GLSelectSearchOptions[] {
    const options: GLSelectSearchOptions[] = filteredAddressesToFormat.map(
      (address) => {
        return {
          text:
            address.description.length > 60
              ? address.description.slice(0, 60) + '...'
              : address.description,
          id: String(address.place_id),
        };
      },
    );

    registerDeviceActions.setFilteredAddressesFormatted(options);

    return options;
  }

  async function autoCompleteOnEdit(
    zipCodeSearch?: string,
  ): Promise<never[] | undefined> {
    if (!googleMapsIsLoaded || !zipCodeSearch) return []

    registerDeviceActions.setAddressIsLoading(true);

    const predictions = await getSuggestions(zipCodeSearch);
    const predictionsFormatted = formatAddress(predictions);

    if (zipCodeSearch) {
      onPlaceSelect(predictionsFormatted[0]?.id);
    }

    registerDeviceActions.setAddressIsLoading(false);
  }

  useEffect(() => {
    if (filteredAddresses) {
      formatAddress(filteredAddresses);
    }
  }, [filteredAddresses]);

  useEffect(() => {
    registerDeviceActions.setAddressIsLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    registerDeviceActions.setIsLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    const zipCodeSearch = registerDeviceState.zipCodeSearch
    const isValidZipCode = ZIP_CODE_REGEX.test(zipCodeSearch || '');

    if (zipCodeSearch && isValidZipCode) {
      const zipCodeFormatted = zipCodeSearch.replace('-', '');
      autoCompleteOnEdit(zipCodeFormatted);
    }

  }, [registerDeviceState.zipCodeSearch]);

  return {
    registerDeviceState,
    onPlaceSelect,
    onEditDeviceFn,
    autoCompleteOnEdit,
  };
};
