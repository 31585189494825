import { useAuth } from '@group-link-one/gl-utils';
import CryptoJS from 'crypto-js';
import {
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  getFirestore,
  updateDoc,
  setDoc
} from 'firebase/firestore';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import React from 'react';
import { FirestoreConfig } from './Interfaces/FirestoreConfig';
import { FirestoreProviderProps } from './Interfaces/FirestoreProviderProps';
import { UserCustomMessageCollection } from './Interfaces/UserCustomMessageCollection';
import { FirebaseApp } from 'firebase/app';
import * as Sentry from '@sentry/react';
import { UsersCollection } from './Interfaces/UsersColection';

let firestore: Firestore | null = null;

function initializeFirestore(FBApp: FirebaseApp): Firestore {
  firestore = getFirestore(FBApp);
  return firestore;
}

const FireStoreContext = createContext<FirestoreConfig>({} as FirestoreConfig);

/**
 * FireStoreProvider component.
 *
 * @param children - ReactNode as children components.
 * @returns JSX.Element
 */
const FirestoreProvider: React.FC<FirestoreProviderProps> = ({
  children,
}: FirestoreProviderProps): JSX.Element => {
  const [allUsersStoraged, setAllUsersStoraged] = useState<
    UsersCollection[] | undefined
  >(undefined);
  const { user, accessTokenIsValid } = useAuth();
  const userEmail = user?.email || '';

  const userEmailEncrypted = CryptoJS.SHA256(userEmail).toString();

  const hasToShowOnboard = useMemo(() => {
    return allUsersStoraged?.find((userStoraged) => {
      return userStoraged.id === userEmailEncrypted;
    })?.show_onboard;
  }, [allUsersStoraged, userEmail]);

  const hasToShowWelcome = useMemo(() => {
    return allUsersStoraged?.find((userStoraged) => {
      return userStoraged.id === userEmailEncrypted;
    })?.show_welcome;
  }, [allUsersStoraged, userEmail]);

  const hasToShowCustomMessage = useMemo(() => {
    return allUsersStoraged?.find((userStoraged) => {
      return userStoraged.id === userEmailEncrypted;
    })?.custom_message.show;
  }, [allUsersStoraged, userEmail]);

  const customMessageOptions: UserCustomMessageCollection = useMemo(() => {
    const customMessageString = allUsersStoraged?.find((userStoraged) => {
      return userStoraged.id === userEmailEncrypted;
    })?.custom_message.options;

    if (!customMessageString) {
      return {};
    }

    return JSON.parse(customMessageString);
  }, [allUsersStoraged, userEmail]);

  async function fetchAllUsers(): Promise<void> {
    try {
      if (firestore === null) {
        return;
      }
      const usersCollectionRef = collection(firestore, 'users');
      const querySnapshot = await getDocs(usersCollectionRef);
      const users = querySnapshot.docs.map((docStoraged) => ({
        id: docStoraged.id,
        ...docStoraged.data(),
      })) as UsersCollection[];

      setAllUsersStoraged(users);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  function getUserByEmail(): UsersCollection | undefined {
    return allUsersStoraged?.find((userStoraged) => {
      return userStoraged.id === userEmailEncrypted;
    });
  }

  function resetAllUsersStoraged(): void {
    setAllUsersStoraged(undefined);
  }

  async function updateUserOnboard(
    type: 'onboard' | 'welcome' | 'custom-message',
  ): Promise<void> {
    if (firestore === null) {
      return;
    }
    try {
      const userRef = doc(firestore, 'users', userEmailEncrypted);
      const userDoc = await getDoc(userRef);

      let updatedUser = {};

      if (type === 'onboard') {
        updatedUser = {
          show_welcome: true,
        };
      }

      if (type === 'custom-message') {
        updatedUser = {
          custom_message: {
            show: false,
            options: JSON.stringify({
              title: '',
              description: '',
              options: [],
            }),
          },
        };
      }

      if (type === 'welcome') {
        updatedUser = {
          show_welcome: false,
        };
      }

      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (!userData.userId) {
          updatedUser = {
            ...updatedUser,
            userId: userEmailEncrypted,
          };
        }
      }

      await updateDoc(userRef, updatedUser);

      const updatedUsers = allUsersStoraged?.map((userStoraged) => {
        if (userStoraged.id === userEmailEncrypted) {
          return {
            ...userStoraged,
            ...updatedUser,
          };
        }
        return userStoraged;
      });

      setAllUsersStoraged(updatedUsers);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function createUserOnboard(): Promise<void> {
    try {
      if (firestore === null) {
        return;
      }

      const userRef = doc(firestore, "users", userEmailEncrypted);

      await setDoc(userRef, {
        userId: userEmailEncrypted,
        show_onboard: false,
        show_welcome: true,
        custom_message: {
          show: false,
          options: JSON.stringify({
            title: "",
            description: "",
            options: []
          })
        }
      }, { merge: true });

      const newUser: UsersCollection = {
        id: userEmailEncrypted,
        show_onboard: false,
        show_welcome: true,
        custom_message: {
          show: false,
          options: JSON.stringify({
            title: "",
            description: "",
            options: []
          })
        }
      }

      setAllUsersStoraged([...allUsersStoraged || [], newUser]);
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  async function getDocument<T>(documentName: string): Promise<T[]> {
    if (firestore === null) {
      return [];
    }
    const document = await getDocs(collection(firestore, documentName));
    return document.docs.map((docParam) => docParam.data() as T);
  }

  useEffect(() => {
    if (!accessTokenIsValid) {
      return;
    }
    fetchAllUsers();
  }, [accessTokenIsValid]);

  useEffect(() => {
    if (!allUsersStoraged || !accessTokenIsValid) {
      return;
    }
    const userStoraged = getUserByEmail();

    if (!userStoraged && user?.email) {
      createUserOnboard();
    }
  }, [user?.email, allUsersStoraged]);

  return (
    <FireStoreContext.Provider
      value={{
        customMessageOptions,
        hasToShowOnboard,
        hasToShowWelcome,
        hasToShowCustomMessage,
        getUserByEmail,
        getDocument,
        updateUserOnboard,
        resetAllUsersStoraged,
      }}
    >
      {children}
    </FireStoreContext.Provider>
  );
};

const useFirestore = (): FirestoreConfig => {
  const context = useContext(FireStoreContext);

  if (!context) {
    throw new Error('useFireStore must be used within an FireStoreProvider');
  }

  return context;
};

export { initializeFirestore, FirestoreProvider, useFirestore };
