import {
  GetReportRow,
  useDeleteReportStore,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import {
  UseMutateAsyncFunction,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';

import { useReportListService } from '../../../Services/reportListService/useReportListService';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

interface IUseModalDeleteReport {
  isPending: boolean;
  onDelete: UseMutateAsyncFunction<void, Error, void, unknown>;
}

export const useModalDeleteReport = (): IUseModalDeleteReport => {
  const { logEventAnalytics } = useFBAnalytics();
  const { deleteReport } = useReportListService();
  const { addToast } = useToast();
  const { t } = useI18n();

  const queryClient = useQueryClient();

  const { state: deleteReportState, actions: deleteReportActions } =
    useDeleteReportStore();

  function updateReportList(): void {
    const reportsCached: GetReportRow[] | undefined = queryClient.getQueryData([
      'get-reports',
    ]);

    if (reportsCached) {
      queryClient.setQueryData(
        ['get-reports'],

        reportsCached.filter(
          (report) => report.id !== deleteReportState.reportId,
        ),
      );
    }
  }

  const { mutateAsync: onDelete, isPending } = useMutation({
    mutationFn: async () => {
      if (!deleteReportState.reportId) {
        return;
      }

      await deleteReport({
        id: deleteReportState.reportId,
      });
    },
    onSuccess: () => {
      addToast({
        title: t('reports.toast.deleted.success.title'),
        message: t('reports.toast.deleted.success.message', {
          reportName: deleteReportState.reportName,
        }),
        type: 'success',
      });
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.REPORT_LIST_DELETE,
        eventDescription: 'End report deletion',
      });
      updateReportList();

      deleteReportActions.setModalIsOpen(false);
      deleteReportActions.setReportName(undefined);
      deleteReportActions.setReportId(undefined);
    },
  });

  return {
    isPending,
    onDelete,
  };
};
