export default `<svg width="66px" height="72px" viewBox="0 0 66 72" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <title>Untitled</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-11348" transform="translate(0.5005, 0.5004)" fill="#7523D7" fill-rule="nonzero" stroke="#7523D7" stroke-opacity="0">
            <g id="icon-pin" opacity="0.3" transform="translate(0, 54.0021)">
                <path d="M30.9995,16.9995 L52.9195,12.0205 C65.0255,9.2705 65.0255,4.8125 52.9195,2.0625 L52.9195,2.0625 C40.8135,-0.6875 21.1855,-0.6875 9.0795,2.0625 L9.0795,2.0625 C-3.0265,4.8125 -3.0265,9.2705 9.0795,12.0205 L30.9995,16.9995 Z" id="Subtraction_1"></path>
            </g>
            <g id="icon-pin-2" transform="translate(0.0001, 0)">
                <path d="M30.9994048,69.0006259 L52.9194048,52.8566259 C58.7326282,47.0534671 61.9993105,39.1766516 61.9993105,30.9626259 C61.9993105,22.7486002 58.7326282,14.8717847 52.9194048,9.06862593 L52.9194048,9.06862593 C40.8072873,-3.02287531 21.1915223,-3.02287531 9.0794048,9.06862593 L9.0794048,9.06862593 C3.26648776,14.8717444 -8.73866951e-16,22.7483455 -8.73866951e-16,30.9621259 C-8.73866951e-16,39.1759064 3.26648776,47.0525074 9.0794048,52.8556259 L30.9994048,69.0006259 Z" id="Subtraction_1-2"></path>
            </g>
        </g>
        <g id="icon-thunderbolt" transform="translate(17, 17)">
            <rect id="Retângulo_4918" x="0" y="0" width="30" height="30"></rect>
            <g id="Grupo_1997" transform="translate(4, 0)" fill="#FFFFFF" fill-rule="nonzero">
                <path d="M21.096,11.431 L12.208,11.352 L15.46,0 L0,18.461 L9.206,18.543 L6.909,30 L21.096,11.431 Z M4.95,16.177 L10.199,9.909 L9.126,13.653 L16.419,13.718 L11.17,20.588 L12.041,16.24 L4.95,16.177 Z" id="Caminho_89"></path>
            </g>
        </g>
        <g id="Group-11349" transform="translate(44, 42)" fill="#00FFAA" fill-rule="nonzero" stroke="#00FFAA" stroke-opacity="0.200000003" stroke-width="8">
            <circle id="Ellipse_2765" cx="9" cy="9" r="9"></circle>
        </g>
    </g>
</svg>`;
