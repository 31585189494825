import {
  IForgotData,
  IRewritePassword,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';

export interface IForgotPasswordService {
  rewritePassword: (data: IRewritePassword) => Promise<string>;
  sendCode: () => void; // Atualize caso implemente a funcionalidade no futuro
  sendResetPasswordLink: (data: IForgotData) => Promise<{}>;
}

export const useForgotPasswordService = (): IForgotPasswordService => {
  const { http } = useAxiosHTTPRequest();

  const rewritePassword = async (data: IRewritePassword): Promise<string> => {
    return http.post('/rewrite-password', data);
  };

  const sendCode = (): void => {};

  const sendResetPasswordLink = async (data: IForgotData): Promise<{}> => {
    const response = http.post('/reset-password', data, {
      method: 'POST',
    });

    return response;
  };

  return {
    sendCode,
    sendResetPasswordLink,
    rewritePassword,
  };
};
