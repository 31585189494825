import { useAuth, User } from '@group-link-one/gl-utils';
import {
  GroupsDevicesStoreState,
  IGroupsDevice,
  IGroupsUser,
  useGLPagination,
  useGroupsDevicesStore,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useEffect } from 'react';

// import { useEffect } from "react";
import { useGroupListService } from '../../Services/groupListService/useGroupListService';
import {
  GetOrgDeviceGroupIdResponse,
  ICreateGroup,
  IEditGroups,
  OrgDeviceGroupPostResponse,
} from '../../Services/groupListService/useGroupListService.types';
import { useGroupService } from '../../Services/groupService/useGroupService';
import { useDeviceGroupsUtils } from './useDeviceGroupsUtils';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import * as Sentry from '@sentry/react';
import { useGLFlags } from '@group-link-one/gl-utils';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';
// import { useDeviceGroupsUtils } from "./useDeviceGroupsUtils";

const transformOptions = (
  options: IGroupsUser[],
): { id: string; text: string }[] => {
  return options.map((option) => ({
    id: String(option.id),
    text: option.name,
  }));
};

interface IUseGroupMutations {
  createMutation: UseMutationResult<
    OrgDeviceGroupPostResponse,
    Error,
    Partial<ICreateGroup>,
    unknown
  >;
  deleteMutation: UseMutationResult<void, Error, number>;
  updateMutation: UseMutationResult<
    void,
    Error,
    { id: number; data: Partial<IEditGroups> },
    unknown
  >;
  addDevicesToGroup: ({
    id,
    data,
  }: {
    id: number;
    data: Partial<IEditGroups>;
  }) => Promise<void | unknown>;
}

const useGroupMutations = (): IUseGroupMutations => {
  const { logEventAnalytics } = useFBAnalytics();
  const queryClient = useQueryClient();
  const { addToast } = useToast();
  const { t } = useI18n();

  const { actions: groupsDevicesActions } = useGroupsDevicesStore();

  const { deleteOrgDeviceGroupId, patchOrgDeviceGroupId } =
    useGroupListService();

  const createMutation = useMutation({
    mutationFn: useGroupListService().postOrgDeviceGroup,
    onSuccess: () => {
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_GROUP_CREATE,
        eventDescription: 'End create device group',
      });
      queryClient.invalidateQueries({ queryKey: ['orgdevicegroup'] });
    },
  });

  const deleteMutation = useMutation<void, Error, number>({
    mutationFn: async (groupId: number) => {
      try {
        await deleteOrgDeviceGroupId(groupId);

        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.DEVICE_GROUP_DELETE,
          eventDescription: 'End delete device',
        });

        addToast({
          title: t('groupsDevices.toast.successDelete.title'),
          message: t('groupsDevices.toast.successDelete.message'),
          type: 'success',
        });
      } catch (error: unknown) {
        Sentry.captureException(error);
        if (error instanceof AxiosError) {
          const errorMessage = error.response?.data?.error;

          if (errorMessage === 'HAS_CHILD_GROUPS') {
            addToast({
              title: t('toast.error.title'),
              message: t('toast.error.cannotDeleteWithChildren'),
              type: 'error',
            });
          } else {
            addToast({
              title: t('toast.error.title'),
              message: t('toast.error.message'),
              type: 'error',
            });
          }
        }
      }
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['orgdevicegroup'] });
    },
  });

  const updateMutation = useMutation<
    void,
    Error,
    { id: number; data: Partial<IEditGroups> }
  >({
    mutationFn: async ({ id, data }) => {
      await patchOrgDeviceGroupId({ id, data });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ['orgdevicegroup'] });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_GROUP_EDIT,
        eventDescription: 'End edit device group',
      });

      addToast({
        title: t('groupsDevices.toast.successUpdate.title'),
        message: t('groupsDevices.toast.successUpdate.message'),
        type: 'success',
      });

      setTimeout(() => {
        groupsDevicesActions.setOpenModal(false);
      }, 500);
    },
  });

  const addDevicesToGroup = async ({
    id,
    data,
  }: {
    id: number;
    data: Partial<IEditGroups>;
  }): Promise<void | unknown> => {
    try {
      await patchOrgDeviceGroupId({
        id,
        data,
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_GROUP_ADD_DEVICE_TO_GROUP,
        eventDescription: 'End add devices to device group',
      });

      addToast({
        title: t('groupsDevices.toast.successAddDevices.title'),
        message: t('groupsDevices.toast.successAddDevices.message'),
        type: 'success',
      });

      queryClient.invalidateQueries({ queryKey: ['orgdevicegroup'] });

      setTimeout(() => {
        groupsDevicesActions.setOpenAddDevicesModal(false);
      }, 500);
    } catch (err: unknown) {
      Sentry.captureException(err);
      addToast({
        title: t('groupsDevices.toast.errorAddDevices.title'),
        message: t('groupsDevices.toast.errorAddDevices.message'),
        type: 'error',
      });

      return err;
    }
  };

  return { createMutation, deleteMutation, updateMutation, addDevicesToGroup };
};

interface IUseGroupListBody {
  showFeature: (feature: string) => boolean;
  features: Features;
  createMutation: UseMutationResult<
    OrgDeviceGroupPostResponse,
    Error,
    Partial<ICreateGroup>,
    unknown
  >;
  deleteMutation: UseMutationResult<void, Error, number>;
  updateMutation: UseMutationResult<
    void,
    Error,
    { id: number; data: Partial<IEditGroups> },
    unknown
  >;
  addDevicesToGroup: ({
    id,
    data,
  }: {
    id: number;
    data: Partial<IEditGroups>;
  }) => Promise<void | unknown>;
  groupList: IGroupsDevice[] | undefined;
  groupsDevicesState: GroupsDevicesStoreState;
  optionsList: IGroupsUser[];
  user: User | undefined;
  OPTIONS: { id: string; text: string }[];
}

export const useGroupListBody = (): IUseGroupListBody => {
  const { getOrgDeviceGroup } = useGroupListService();
  const { getOrganizationGroups } = useGroupService();
  const { createMutation, deleteMutation, updateMutation, addDevicesToGroup } =
    useGroupMutations();

  const { user } = useAuth();

  const { state: groupsDevicesState, actions: groupsDevicesActions } =
    useGroupsDevicesStore();
  const { showFeature } = useGLFlags();
  const { features } = usePrivileges();

  const { actions: paginationActions } = useGLPagination();

  const { getDeviceGroup } = useDeviceGroupsUtils();

  function getChildGroups(
    group: IGroupsDevice,
    groups: IGroupsDevice[],
  ): IGroupsDevice[] | undefined {
    const children = groups.filter((g) => g.parent_id === group.id);

    if (children.length === 0) {
      return [];
    }

    return children.map((child) => ({
      ...child,
      children: getChildGroups(child, groups),
    }));
  }

  function formatGroupList(
    groups: IGroupsDevice[] | undefined,
  ): IGroupsDevice[] | undefined {
    if (!groups) {
      return;
    }

    const AllGroupsLevelZero = groups?.filter(
      (group) => group.parent_id === null,
    );
    const groupsFormattedWithChildren = [];

    for (const group of AllGroupsLevelZero) {
      groupsFormattedWithChildren.push({
        ...group,
        children: getChildGroups(group, groups),
      });
    }

    return groupsFormattedWithChildren;
  }

  const { data: groupList, isLoading: groupListIsLoading } = useQuery({
    queryKey: ['orgdevicegroup'],
    queryFn: async () => {
      const devicesGroup = await getOrgDeviceGroup();

      return formatGroupList(devicesGroup?.rows);
    },
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5,
  });

  const { data: { rows: optionsList = [] } = {} } = useQuery({
    queryKey: ['get-groups-users'],
    queryFn: () => getOrganizationGroups(),
    refetchOnWindowFocus: true,
    staleTime: 1000 * 60 * 5,
  });

  const OPTIONS = transformOptions(optionsList);

  async function getDataFromGroupList(id: number): Promise<void> {
    const dataFromDeviceGroup = await getDeviceGroup(id);

    const coordinatorsUnique = dataFromDeviceGroup.coordinators.reduce(
      (acc, current) => {
        const x = acc.find((item) => item.id === current.id);

        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      [] as GetOrgDeviceGroupIdResponse['coordinators'],
    );

    const coordinators = coordinatorsUnique.map((coordinator) => ({
      id: String(coordinator.id),
      text: coordinator.name,
    }));

    const viewersUnique = dataFromDeviceGroup.viewers.reduce(
      (acc, current) => {
        const x = acc.find((item) => item.id === current.id);

        if (!x) {
          return acc.concat([current]);
        } else {
          return acc;
        }
      },
      [] as GetOrgDeviceGroupIdResponse['viewers'],
    );

    const viewers = viewersUnique.map((viewer) => ({
      id: String(viewer.id),
      text: viewer.name,
    }));

    groupsDevicesActions.setGroupsDeviceCardInfo({
      ...groupsDevicesState.groupsDevicesCardInfo,
      coordinators,
      viewers,
    });
  }

  useEffect(() => {
    paginationActions.setIsLoading(groupListIsLoading);
  }, [groupListIsLoading]);

  useEffect(() => {
    if (
      groupsDevicesState.openModal &&
      groupsDevicesState.groupsDevicesCardInfo.id
    ) {
      getDataFromGroupList(Number(groupsDevicesState.groupsDevicesCardInfo.id));
    }
  }, [groupsDevicesState.openModal]);

  return {
    showFeature,
    features,
    createMutation,
    deleteMutation,
    updateMutation,
    addDevicesToGroup,
    groupList,
    groupsDevicesState,
    optionsList,
    user,
    OPTIONS,
  };
};
