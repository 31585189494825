import { useI18n } from '@group-link-one/grouplink-components';

interface IUseWaterColumns {
  columnName: string;
  hasActions?: boolean;
}

export const useWaterColumns = (): IUseWaterColumns[] => {
  const { t } = useI18n();

  return [
    {
      columnName: t('eventList.columns.created_at'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.sent'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.latency'),
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.device_id'),
    },
    {
      columnName: `${t('eventList.columns.consumption')} (m³)`,
      hasActions: false,
    },
    {
      columnName: t('eventList.columns.alarms'),
      hasActions: false,
    },
  ];
};
