import {
  IRewritePassword,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useToast } from '@group-link-one/grouplink-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useForgotPasswordService } from '../../../Services/forgotPasswordService/useForgotServices';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { AxiosError } from 'axios';

interface IUseNewPassword {
  rewrite: ({ password }: IRewritePassword) => Promise<void>;
}

export const useNewPassword = (): IUseNewPassword => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { rewritePassword } = useForgotPasswordService();
  const { addToast } = useToast();
  const { search } = useLocation();

  const navigate = useNavigate();

  const extractTokenFromSearch = (): string => {
    const params = new URLSearchParams(search);
    return params.get('token') || '';
  };

  const rewrite = async ({ password }: IRewritePassword): Promise<void> => {
    try {
      await rewritePassword({
        token: extractTokenFromSearch(),
        password,
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.CREATE_NEW_PASSWORD,
        eventDescription: 'End Create new password',
      });

      addToast({
        title: t('updatePasswordToast.title'),
        message: t('updatePasswordToast.description'),
        type: 'success',
      });

      navigate('/login');

      return;
    } catch (error) {
      if (
        error instanceof AxiosError &&
        error?.response?.status === 400 &&
        error?.response?.data.message === 'token_expired| Token expired'
      ) {
        addToast({
          title: t('updatePasswordToast.error.title'),
          message: t('updatePasswordToast.error.description'),
          type: 'error',
        });

        navigate('/forgot-password');

        return;
      }

      addToast({
        title: t('toast.error.title'),
        message: t('toast.error.message'),
        type: 'error',
      });

      return;
    }
  };

  useEffect(() => {
    if (!extractTokenFromSearch()) {
      navigate('*');
    }
  }, []);

  return {
    rewrite,
  };
};
