import { useI18n, useToast } from '@group-link-one/grouplink-components';
import {
  DetailsDeviceStoreActions,
  DetailsDeviceStoreState,
  useDetailsDeviceStore,
} from '../store/details-device-store';
import { useTagsService } from '@/Services/tagsService/useTagsService';
import { useModalAddTagContent } from '../ModalAddTag/Content/useModalAddTagContent';
import { useDeviceListAllDevices } from '../Content/useDeviceListContent';
import { queryClient } from '@/Services/queryClient';
import { useExtractURLSearch } from '@/hooks/useExtractURLSearch';

interface ModalAddTag {
  t: (key: string) => string;
  onUnassignTag: () => Promise<void | unknown>;
  deviceListState: DetailsDeviceStoreState;
  deviceListActions: DetailsDeviceStoreActions;
}

export const useModalUnassignTag = (): ModalAddTag => {
  const { refetchDeviceList } = useDeviceListAllDevices()
  const { addToast } = useToast();
  const { assignDeviceToTag } = useTagsService();
  const { t } = useI18n();
  const { state: deviceListState, actions: deviceListActions } =
    useDetailsDeviceStore();
  const { tagSelected } = useModalAddTagContent();

  async function onUnassignTag(): Promise<void | unknown> {
    try {
      await assignDeviceToTag({
        device_ids: [deviceListState.device?.device_id!],
        operations: [
          {
            key: tagSelected.key!,
            value: null,
          },
        ],
        preserve_existing_tags: true,
        strict: false,
        is_value_unique: false,
      });

      deviceListActions.setDevice({
        ...deviceListState.device!,
        tags: (deviceListState.device?.tags! || []).filter(
          (tag) => tag.key !== tagSelected.key,
        ),
      });

      deviceListActions.setModalUnassignTagIsOpen(false);
      refetchDeviceList();

      addToast({
        title: t('common.success'),
        message: t(
          'deviceList.detailsModal.tags.modal.toast.removeTag.description',
        ),
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: t('common.error'),
        message: t(
          'deviceList.detailsModal.tags.modal.toast.errorWhenCreate.description',
        ),
        type: 'error',
      })
      return error;
    }
  }

  return {
    t,
    deviceListState,
    deviceListActions,
    onUnassignTag,
  };
};
