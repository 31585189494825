import { FBAnalyticsEvent } from '@/Context/FBAnalytics/types/FBAnalyticsEvent.types';
import { useI18n } from '@group-link-one/grouplink-components';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { useGLFlags } from '@group-link-one/gl-utils';

interface TagCardProps {
  hasPrivileges: (e: string[]) => boolean;
  logEventAnalytics: (event: FBAnalyticsEvent) => void;
  t: (key: string) => string;
}

export const useTagCard = (): TagCardProps => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();

  const { showFeature } = useGLFlags();

  return {
    logEventAnalytics,
    showFeature,
    t,
  };
};
