import {
  GetCustomRoleResponse,
  IJoinUserOrganization,
  PaginateResponseProps,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import {
  External_role,
  GetInvitesResponse,
  IJoinOrganizationPost,
  InviteUserBody,
  IRequestParams,
  IUserResponse,
  ListOfRecordWithId,
} from './useOrganizationUsers.types';

interface GetUsersCountResponse {
  count: number;
}

export enum LinkConfig {
  default,
  energy,
  smart,
  util,
}

interface JoinOrganizationData extends IJoinUserOrganization {
  link_config: keyof typeof LinkConfig;
}

type Response = {
  status: number;
};

interface InviteUserResponse extends ListOfRecordWithId {
  status: number;
}

interface IOganizationUserServices {
  inviteUser: (data: InviteUserBody) => Promise<InviteUserResponse>;
  getUsers: (
    params: IRequestParams,
  ) => Promise<PaginateResponseProps<IUserResponse[]>>;
  getUserById: (id: number) => Promise<IUserResponse>;
  getCustomRoles: () => Promise<GetCustomRoleResponse>;
  getInvites: () => Promise<GetInvitesResponse>;
  getUsersCount: (params: IRequestParams) => Promise<GetUsersCountResponse>;
  activateUser: (id: number) => Promise<Response>;
  deactivateUser: (id: number) => Promise<Response>;
  deleteInvite: (id: number) => Promise<Response>;
  joinOrganization: (
    data: JoinOrganizationData,
  ) => Promise<IJoinOrganizationPost>;
  updateUserRole: (
    id: number,
    set_external_role: keyof typeof External_role,
    api_profile_access_ids: number[],
  ) => Promise<Response>;
}

export const useOrganizationUsersService = (): IOganizationUserServices => {
  const { httpPrivate, http } = useAxiosHTTPRequest();

  const joinOrganization = async (
    data: JoinOrganizationData,
  ): Promise<IJoinOrganizationPost> => {
    return await http.post('/join-organization/join-organization', data);
  };

  const getCustomRoles = async (): Promise<GetCustomRoleResponse> => {
    return (await httpPrivate.get('/organization-user/list-profiles')).data;
  };

  const inviteUser = async (
    data: InviteUserBody,
  ): Promise<InviteUserResponse> => {
    return await httpPrivate.post('/organization-user/invite', data);
  };

  const getUsersCount = async ({
    active,
    search,
    ipp,
  }: IRequestParams): Promise<GetUsersCountResponse> => {
    const response = await httpPrivate.get('/organization-user/count', {
      params: {
        active,
        search,
        ipp,
      },
    });

    return response.data;
  };

  const getUsers = async ({
    active,
    next_page_token,
    ipp,
    search,
  }: IRequestParams): Promise<PaginateResponseProps<IUserResponse[]>> => {
    const response = await httpPrivate.get('/organization-user', {
      params: {
        active,
        next_page_token,
        ipp,
        search,
      },
    });

    return response.data;
  };

  const getUserById = async (id: number): Promise<IUserResponse> => {
    const response = await httpPrivate.get('/organization-user/' + id);

    return response.data;
  };

  const getInvites = async (): Promise<GetInvitesResponse> => {
    const response = await httpPrivate.get('/organization-user/invite');

    return response.data;
  };

  const activateUser = async (id: number): Promise<Response> => {
    return await httpPrivate.patch(`/organization-user/activate/${id}`);
  };

  const deactivateUser = async (id: number): Promise<Response> => {
    return await httpPrivate.patch(`/organization-user/deactivate/${id}`);
  };

  const deleteInvite = async (id: number): Promise<Response> => {
    return await httpPrivate.delete(`/organization-user/invite/${id}`);
  };

  const updateUserRole = async (
    id: number,
    set_external_role: keyof typeof External_role,
    api_profile_access_ids: number[],
  ): Promise<Response> => {
    return await httpPrivate.patch(`/organization-user/role/${id}`, {
      set_external_role,
      api_profile_access_ids,
    });
  };

  return {
    inviteUser,
    getUsers,
    getUserById,
    getCustomRoles,
    getInvites,
    getUsersCount,
    activateUser,
    deactivateUser,
    deleteInvite,
    joinOrganization,
    updateUserRole,
  };
};
