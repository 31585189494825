import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import {
  GLDateRangeType,
  GLRelativeFilterTimeProps,
  GLRelativeListOption,
  GLRelativeListOptionID,
  TabProps,
  useAuditStore,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useMediaQuery } from 'usehooks-ts';

interface IUseAudit {
  tabs: TabProps[];
  lastPageToken: {
    'all-audits': {
      token: string | undefined;
      page: number | undefined;
    };
  };
  relativeFilterTimeOptions: GLRelativeFilterTimeProps;
}

export const useAudit = (): IUseAudit => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const isMobile = useMediaQuery('(max-width: 1024px)');
  const { actions: auditActions, state: auditState } = useAuditStore();

  const tabs: TabProps[] = [
    {
      id: 1,
      active: false,
      cacheKey: 'all-audits',
      count: undefined,
      name: t('audit.list.tabs.one'),
      onClick: () => {},
    },
    // {
    //   id: 2,
    //   active: false,
    //   cacheKey: "user-interaction",
    //   count: undefined,
    //   name: "User interaction",
    //   onClick: () => {},
    // },
  ];

  const lastPageToken = {
    'all-audits': {
      token: undefined,
      page: undefined,
    },
    // "user-interaction": {
    //   token: undefined,
    //   page: undefined,
    // },
  };

  const options: GLRelativeListOptionID[] = [
    'last_5_minutes',
    'last_15_minutes',
    'last_30_minutes',
    'last_1_hour',
    'last_3_hours',
    'last_6_hours',
    'last_12_hours',
    'last_24_hours',
    'last_3_days',
    'last_7_days',
    'last_15_days',
    'last_30_days',
  ];

  const relativeFilterTimeOptions: GLRelativeFilterTimeProps = {
    currentRelativeTime: auditState.currentRelativeTime,
    currentDateRange: auditState.currentDateRange,
    rootOptions: {
      width: 'fit-content',
      style: {
        paddingRight: isMobile ? 5 : 0,
        background: isMobile ? 'transparent' : 'inherit',
        alignItems: isMobile ? 'flex-end' : 'initial',
        zIndex: 9999,
      },
    },
    boxOptions: {
      position: 'bottom-right',
      style: {
        zIndex: 9999,
      },
      width: !isMobile ? '600px' : 'calc(100vw - 80px)',
    },
    inputOptions: {
      placeholder: t('audit.filters.dateFilter.placeholder'),
    },
    options,
    setCurrentRelativeTime: (option?: GLRelativeListOption) => {
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.AUDIT_RELATIVE_DATE_FILTER,
        eventDescription: 'Relative Date Filter',
        param1: JSON.stringify(option),
      });
      auditActions.setCurrentRelativeTime(option);
    },

    setCurrentDateRange: (range?: GLDateRangeType) => {
      auditActions.setCurrentDateRange(range);
    },

    onRangeChange: async (range?: GLDateRangeType) => {
      if (!range?.from || !range?.to) {
        return;
      }
      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.AUDIT_DATE_RANGE_FILTER,
        eventDescription: 'Date Range Filter',
        param1: JSON.stringify(range?.from),
        param2: JSON.stringify(range?.to),
      });
      auditActions.setRange(range);
    },
  };

  return {
    tabs,
    lastPageToken,
    relativeFilterTimeOptions,
  };
};
