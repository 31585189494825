import {
  IJoinUserOrganization,
  useI18n,
  useToast,
} from '@group-link-one/grouplink-components';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useOrganizationUsersService } from '../../../Services/organizationUsersService/useOrganizationUsers';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import axios from 'axios';
import * as Sentry from '@sentry/react';

interface IUseSignUp {
  joinUser: ({
    name,
    last_name,
    password,
  }: IJoinUserOrganization) => Promise<void>;
}

export const useSignUp = (): IUseSignUp => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { search } = useLocation();
  const navigate = useNavigate();
  const { joinOrganization } = useOrganizationUsersService();
  const { addToast } = useToast();

  const extractTokenFromSearch = (): string | null => {
    const params = new URLSearchParams(search);
    return params.get('token');
  };

  const joinUser = async ({
    name,
    last_name,
    password,
  }: IJoinUserOrganization): Promise<void> => {
    try {
      await joinOrganization({
        token: extractTokenFromSearch(),
        name,
        last_name,
        password,
        link_config: 'util',
      });

      addToast({
        title: t('signUp.toast.account_created.title'),
        message: t('signUp.toast.account_created.message'),
        type: 'success',
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.SIGN_UP,
        eventDescription: 'Sign Up',
        param1: JSON.stringify({
          name,
          last_name,
        }),
      });

      navigate('/login');

      return;
    } catch (error) {
      Sentry.captureException(error);
      if (
        axios.isAxiosError(error) &&
        error.response?.data.code === 'invite_already_used'
      ) {
        addToast({
          title: t('signUp.toast.expired_invite.title'),
          message: t('signUp.toast.expired_invite.message'),
          type: 'error',
        });
      }
    }
  };

  useEffect(() => {
    if (!extractTokenFromSearch()) {
      navigate('*');
    }
  }, []);

  return {
    joinUser,
  };
};
