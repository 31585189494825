import {
  GLBox,
  GLButton,
  GLModal,
  GLTextField,
} from '@group-link-one/grouplink-components';

import { useModalEditValue } from './useModalEditValue';

export const ModalEditValue = (): JSX.Element => {
  const {
    tagsStoreActions,
    tagsStoreState,
    register,
    errors,
    handleSubmit,
    onSubmitTagValue,
    t,
  } = useModalEditValue();

  return (
    <GLModal
      open={!!tagsStoreState.activeTagValueToEdit}
      title={t(
        'tags.content.modal.manageTag.valuesContent.table.card.actionModal.edit.title',
      )}
      description={t(
        'tags.content.modal.manageTag.valuesContent.table.card.actionModal.edit.description',
      )}
      hasButtonClose
      hasOutsideClick
      maxWidth="500px"
      onClose={() => tagsStoreActions.setActiveTagValueToEdit(undefined)}
      content={
        <form onSubmit={handleSubmit(onSubmitTagValue)}>
          <GLBox>
            <GLBox width={350}>
              <GLTextField
                placeholder="Type a value to tag"
                failure={!!errors.tagValue?.message}
                {...register('tagValue')}
              />
            </GLBox>

            <GLButton
              align="center"
              text={t('common.save')}
              variant="fill"
              weight={600}
              type="submit"
              style={{
                width: 'fit-content',
                paddingInline: 30,
              }}
            />
          </GLBox>
        </form>
      }
    />
  );
};
