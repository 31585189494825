import {
  IGroupsUser,
  PaginateResponseProps,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import { GroupService } from './useGroupService.types';

export const useGroupService = (): GroupService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const getOrganizationGroups = async (): Promise<
    PaginateResponseProps<IGroupsUser[]>
  > => {
    const response = await httpPrivate.get('/org-group');

    return response.data;
  };

  return {
    getOrganizationGroups,
  };
};
