import { Analytics, getAnalytics, logEvent } from 'firebase/analytics';
import { FirebaseApp } from 'firebase/app';
import { FBAnalyticsEventValue } from './types/FBAnalyticsValues.types';
import { useAuth } from '@group-link-one/gl-utils';
import CryptoJS from 'crypto-js';
import { FBAnalyticsEvent } from './types/FBAnalyticsEvent.types';
import React, { createContext, useContext, useEffect, useState } from 'react';
import {
  FBAnalyticsContextProps,
  FBAnalyticsProviderProps,
} from './types/FBAnalyticsContext.types';
import * as Sentry from '@sentry/react';

let analytics: Analytics | null;

function initializeAnalytics(FBApp: FirebaseApp): Analytics {
  analytics = getAnalytics(FBApp);
  return analytics;
}

const FBAnalyticsContext = createContext<FBAnalyticsContextProps | null>(null);

const FBAnalyticsProvider: React.FC<{
  FBApp: FirebaseApp;
  children: React.ReactNode;
}> = ({ children, FBApp }: FBAnalyticsProviderProps) => {
  const { user } = useAuth();
  const [analyticsSetter, setAnalytics] = useState<Analytics | null>(null);

  useEffect(() => {
    if (!analyticsSetter) {
      const instance = getAnalytics(FBApp);
      setAnalytics(instance);
    }
  }, [FBApp, analyticsSetter]);

  function logEventAnalytics({
    eventName,
    eventDescription,
    param1,
    param2,
  }: FBAnalyticsEvent): void {
    if (!analyticsSetter) {
      return;
    }

    const userHash: string = user?.email
      ? CryptoJS.SHA256(user.email).toString()
      : 'unknown';

    const analyticsEventValue: FBAnalyticsEventValue = {
      userHash,
      description: eventDescription,
      param1,
      param2,
    };

    try {
      logEvent(analyticsSetter, eventName, analyticsEventValue);
    } catch (error: unknown) {
      Sentry.captureException(error);
    }
  }

  return (
    <FBAnalyticsContext.Provider
      value={{
        logEventAnalytics,
      }}
    >
      {children}
    </FBAnalyticsContext.Provider>
  );
};

export const useFBAnalytics = (): FBAnalyticsContextProps => {
  const context = useContext(FBAnalyticsContext);
  if (!context) {
    throw new Error(
      'useFBAnalytics deve ser usado dentro de um FBAnalyticsProvider.',
    );
  }
  return context;
};

export { initializeAnalytics, FBAnalyticsProvider };
