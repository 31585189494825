import { GL_COLORS, GLBox } from '@group-link-one/grouplink-components';
import styled from 'styled-components';

export const HealthCheckBigNumbersContainer = styled(GLBox)`
  > div {
    /* flex: 1 !important; */
  }

  .health-check-button-hide-show {
    background-color: ${GL_COLORS.BACKGROUND_SECONDARY};
    border: 1px solid ${GL_COLORS.BORDER_COLOR};
    border-right: none;
    display: flex;
    align-items: center;
    justify-content: center;

    position: absolute;

    top: -20px;
    right: -21px;

    padding: 10px;
    border-radius: 8px 0 0 8px;

    transition: all 0.4s;

    &.outside {
      right: -60px;
      transform: rotate(180deg);
    }
  }
`;
