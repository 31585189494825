import { i18n, useI18n, useToast } from '@group-link-one/grouplink-components';
import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';

import { useTagsService } from '../../../../Services/tagsService/useTagsService';
import {
  CreateTagBody,
  UpdateTagBody,
} from '../../../../Services/tagsService/useTagsService.type';
import { useTagsContent } from '../../Content/useTagsContent';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../../stores/tags.store';
import {
  FieldErrors,
  useForm,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormSetValue,
} from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMediaQuery } from 'usehooks-ts';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

const createTagSchema = yup.object().shape({
  keyName: yup
    .string()
    .required(i18n.t('tags.content.modal.createNewTag.errors.keyName'))
    .transform((value) => value.replace(/ /g, '_'))
    .matches(
      /^[a-z0-9_-]+$/,
      i18n.t('tags.content.modal.createNewTag.errors.keyNameMatches'),
    ),
  label: yup
    .string()
    .required(i18n.t('tags.content.modal.createNewTag.errors.label')),
  description: yup.string().notRequired(),
  isStrict: yup.boolean().notRequired(),
});

const updateTagSchema = yup.object().shape({
  keyName: yup.string().notRequired(),
  label: yup
    .string()
    .required(i18n.t('tags.content.modal.createNewTag.errors.label')),
  description: yup.string().notRequired(),
  isStrict: yup.boolean().notRequired(),
});

type CreateTagForm = {
  keyName: string | undefined;
  label: string;
  description?: string;
  isStrict?: boolean;
};

type UpdateTagForm = {
  label: string;
  description?: string;
  isStrict?: boolean;
};

type OnSubmitData = {
  keyName?: string | undefined;
  label: string;
  description?: string;
  isStrict?: boolean;
};

interface UseModalCreateTagContent {
  actions: TagsStoreActions;
  state: TagsStoreState;
  onSubmit: (data: OnSubmitData) => Promise<void>;
  updateTagSelected: (data: UpdateTagForm) => Promise<void>;
  isLoading: boolean;
  t: (key: string) => string;
  verifyButtonText: () => string;
  handleSubmit: UseFormHandleSubmit<OnSubmitData, undefined>;
  errors: FieldErrors<{
    description?: yup.Maybe<string | undefined>;
    keyName?: yup.Maybe<string | undefined>;
    isStrict?: yup.Maybe<boolean | undefined>;
    label: string;
  }>;
  register: UseFormRegister<{
    keyName?: yup.Maybe<string | undefined>;
    description?: yup.Maybe<string | undefined>;
    isStrict?: yup.Maybe<boolean | undefined>;
    label: string;
  }>;
  setValue: UseFormSetValue<{
    keyName?: yup.Maybe<string | undefined>;
    description?: yup.Maybe<string | undefined>;
    isStrict?: yup.Maybe<boolean | undefined>;
    label?: string;
  }>;
  isMobile: boolean;
}

export const useModalCreateTagContent = (): UseModalCreateTagContent => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { actions, state } = useTagsStore();
  const { createTag, updateTag } = useTagsService();
  const { refetch } = useTagsContent();

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const { addToast } = useToast();

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
  } = useForm({
    resolver: yupResolver(
      state.modalType === 'creation' ? createTagSchema : updateTagSchema,
    ),
    values: {
      keyName: '',
      label: state.modalType === 'edition' ? state.activeTag?.key?.label : '',
      description:
        state.modalType === 'edition' ? state.activeTag?.key?.description : '',
      isStrict:
        state.modalType === 'edition' ? state.activeTag?.key?.is_strict : false,
    },
  });

  const { mutateAsync: createNewTag } = useMutation({
    mutationFn: async ({ description, keyName, label }: CreateTagForm) => {
      setIsLoading(true);
      const creationBody: CreateTagBody = {
        key_name: keyName || '',
        description: description || '',
        label: label || '',
        is_strict: state.isStrict || false,
      };

      await createTag(creationBody);
    },
    onSuccess: () => {
      addToast({
        type: 'success',
        title: t('tags.content.toasts.createTagSuccess.title'),
        message: t('tags.content.toasts.createTagSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_CREATE,
        eventDescription: 'End tag creation',
      });

      refetch();
      actions.setModalCreateTagIsOpen(false);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  const { mutateAsync: updateTagSelected } = useMutation({
    mutationFn: async ({ label, description }: UpdateTagForm) => {
      const editionBody: UpdateTagBody = {
        description: description || state.activeTag?.key.description || '',
        label: label || state.activeTag?.key.label || '',
        is_strict: state?.activeTag?.is_strict || false,
      };

      await updateTag({
        id: Number(state.activeTag?.key.id),
        data: editionBody,
      });
    },
    onSuccess: () => {
      addToast({
        type: 'success',
        title: t('tags.content.toasts.updateTagSuccess.title'),
        message: t('tags.content.toasts.updateTagSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_UPDATE,
        eventDescription: 'End tag edition',
      });
      refetch();
      actions.setModalCreateTagIsOpen(false);
      setIsLoading(false);
    },
    onError: () => {
      setIsLoading(false);
    },
  });

  function verifyButtonText(): string {
    return state.modalType === 'creation'
      ? t('tags.content.modal.createNewTag.btnCreate')
      : t('tags.content.modal.editTag.btnSave');
  }

  async function onSubmit({
    keyName,
    label,
    description,
    isStrict,
  }: OnSubmitData): Promise<void> {
    if (state.modalType === 'creation') {
      return await createNewTag({
        keyName,
        label,
        description,
        isStrict,
      });
    }

    return await updateTagSelected({
      label,
      description,
      isStrict,
    });
  }

  return {
    actions,
    state,
    onSubmit,
    updateTagSelected,
    isLoading,
    t,
    verifyButtonText,
    handleSubmit,
    errors,
    register,
    setValue,
    isMobile,
  };
};
