import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { useGLFlags } from '@group-link-one/gl-utils';
import { useAuth, User } from '@group-link-one/gl-utils';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { FBAnalyticsEvent } from '@/Context/FBAnalytics/types/FBAnalyticsEvent.types';

interface IOverview {
  user: User | undefined;
  navigate: NavigateFunction;
  logEventAnalytics: (event: FBAnalyticsEvent) => void;
  showFeature: (feature: string) => boolean;
}

export const useOverview = (): IOverview => {
  const { logEventAnalytics } = useFBAnalytics();
  const { user } = useAuth();

  const navigate = useNavigate();
  const { showFeature } = useGLFlags();

  return {
    user,
    showFeature,
    navigate,
    logEventAnalytics,
  };
};
