import {
  GLBox,
  GLButton,
  GLExtendedTextField,
} from '@group-link-one/grouplink-components';

import { useTagsRightContent } from './useTagsRightContent';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { useGLFlags } from '@group-link-one/gl-utils';

export const TagsRightContent = (): JSX.Element => {
  const { isMobile, actions, inputSearchRef, t, logEventAnalytics } =
    useTagsRightContent();

  const { showFeature } = useGLFlags();

  return (
    <GLBox justify="flex-end" gap={20}>
      {/*hasPrivileges(["action_btn_assign_to_devices"]) && (
        <GLButton
          text="Assign to devices"
          align="center"
          variant="fill"
          weight={600}
          padding
          style={{
            width: !isMobile ? "fit-content" : "100%",
          }}
          onClick={() => {
            actions.setModalAssignToDeviceIsOpen(true);
          }}
        />
      )*/}

      <GLExtendedTextField ref={inputSearchRef} />

      {showFeature('action_btn_create_new_device_tag') && (
        <GLButton
          text={t('tags.content.rightContent.btnCreateNewTag')}
          align="center"
          variant="fill"
          weight={600}
          padding
          style={{
            width: !isMobile ? 'fit-content' : '100%',
          }}
          onClick={() => {
            logEventAnalytics({
              eventName: FBAnalyticsEventTitles.TAGS_CREATE,
              eventDescription: 'Start tag creation',
            });
            actions.setModalType('creation');
            actions.setModalCreateTagIsOpen(true);
          }}
        />
      )}
    </GLBox>
  );
};
