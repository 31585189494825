import {
  IOrganizationData,
  IOrganizationReqParams,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import { RecordWithId } from '../organizationUsersService/useOrganizationUsers.types';

export interface IOrganizationsService {
  createOrganization: (data: IOrganizationData) => Promise<RecordWithId>;
  getOrganizations: (
    params: IOrganizationReqParams,
  ) => Promise<IOrganizationDataResponse>;
  updateOrganization: (
    id: number,
    data: Partial<IOrganizationData>,
  ) => Promise<RecordWithId>;
}

export type IOrganizationDataResponse = {
  rows: IOrganizationDataRows[];
};

export type IOrganizationDataRows = {
  id: number;
  org_name: string;
  org_description: string;
  occupational_area: string;
  mqtt_topic_name: string;
  use_case: string;
  has_multi_channel_devices: boolean;
  has_pre_activation_devices: boolean;
  max_wrong_pass_attempts: number;
  //  default_measurement_category: string;
  device_measurement_category: [{}];
  default_external_role: string;
  default_exclude_pre_activation: boolean;
};

export const useOrganizationsService = (): IOrganizationsService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const createOrganization = async (
    data: IOrganizationData,
  ): Promise<RecordWithId> => {
    return await httpPrivate.post('/organization', data);
  };

  const getOrganizations = async ({
    org_name,
    occupational_area,
    use_case,
  }: IOrganizationReqParams): Promise<IOrganizationDataResponse> => {
    const response = await httpPrivate.get('/organization', {
      params: {
        org_name: org_name && org_name,
        occupational_area,
        use_case: use_case ? use_case : 'sr',
      },
    });

    return response.data;
  };

  const updateOrganization = async (
    id: number,
    data: Partial<IOrganizationData>,
  ): Promise<RecordWithId> => {
    return await httpPrivate.patch(`/organization/${id}`, data);
  };

  return {
    createOrganization,
    getOrganizations,
    updateOrganization,
  };
};
