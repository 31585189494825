import {
  TabProps,
  useGLPagination,
  useI18n,
  useToast,
  useUsersStore,
} from '@group-link-one/grouplink-components';
import { useQueryClient } from '@tanstack/react-query';

import { useOrganizationUsersService } from '../../../Services/organizationUsersService/useOrganizationUsers';
import { External_role } from '../../../Services/organizationUsersService/useOrganizationUsers.types';

import {
  ICreateUserInvite,
  IUpdateCountWhenDeactivateUser,
  IUser,
  IUserCardInfo,
} from './types/types';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';

interface PreviousCount {
  previousActiveCount: number | undefined;
  previousInactiveCount: number | undefined;
}

interface UpdateUser {
  id: number;
  role: keyof typeof External_role;
  profile_access_id: number[];
}

interface UseOrganizationUsers {
  features: Features;
  createUserInvite: (data: ICreateUserInvite) => Promise<void>;
  deactivateUserById: (
    id: number,
    userCardInfo: IUserCardInfo,
  ) => Promise<void>;
  deleteInviteById: (id: number) => Promise<void>;
  activeUserById: (id: number) => Promise<void>;
  updateRole: (data: UpdateUser) => Promise<void>;
}

export const useOrganizationUsers = (): UseOrganizationUsers => {
  const { logEventAnalytics } = useFBAnalytics();
  const {
    inviteUser,
    deactivateUser,
    deleteInvite,
    activateUser,
    updateUserRole,
  } = useOrganizationUsersService();
  const { addToast } = useToast();
  const { t } = useI18n();
  const { actions: usersStoreActions } = useUsersStore();

  const queryClient = useQueryClient();

  const { features } = usePrivileges();
  const { state: paginationStoreState } = useGLPagination();

  function getPreviousCount(): PreviousCount {
    const previousActiveCount = queryClient.getQueryData<number>([
      'total-active-users-count',
      paginationStoreState.search,
    ]);

    const previousInactiveCount = queryClient.getQueryData<number>([
      'total-inactive-users-count',
      paginationStoreState.search,
    ]);

    return {
      previousActiveCount,
      previousInactiveCount,
    };
  }

  function setNewUsersInViewInTabAll({
    id,
    active,
  }: IUpdateCountWhenDeactivateUser): void {
    const statusActiveTab = paginationStoreState.tabs[0].active;

    const usersInView = queryClient.getQueryData<IUser[]>([
      'get-users',
      paginationStoreState.search,
      paginationStoreState.currentPage,
      statusActiveTab,
    ]);

    if (usersInView) {
      const newUsers = usersInView.map((userInView) => {
        if (userInView.id === id) {
          return {
            ...userInView,
            active,
          };
        }

        return userInView;
      });

      queryClient.setQueryData(
        [
          'get-users',
          paginationStoreState.search,
          paginationStoreState.currentPage,
          statusActiveTab,
        ],
        newUsers,
      );
    }
  }

  function removeUserFromList({ id }: IUpdateCountWhenDeactivateUser): void {
    const statusActiveTab = paginationStoreState.tabs.find(
      (tab: TabProps) => tab.id === paginationStoreState.activeTabId,
    )?.active;

    const usersInView = queryClient.getQueryData<IUser[]>([
      'get-users',
      paginationStoreState.search,
      paginationStoreState.currentPage,
      statusActiveTab,
    ]);

    if (usersInView) {
      const newUsers = usersInView.filter((userInView) => {
        return userInView.id !== id;
      });

      queryClient.setQueryData(
        [
          'get-users',
          paginationStoreState.search,
          paginationStoreState.currentPage,
          statusActiveTab,
        ],
        newUsers,
      );
    }
  }

  function updateCountWhenDeactivateUser({
    id,
  }: IUpdateCountWhenDeactivateUser): void {
    const { previousActiveCount, previousInactiveCount } = getPreviousCount();

    if (previousActiveCount) {
      queryClient.setQueryData(
        ['total-active-users-count', paginationStoreState.search],
        previousActiveCount - 1,
      );

      paginationStoreState.tabs[1].count = previousActiveCount - 1;
    }

    if (previousInactiveCount) {
      queryClient.setQueryData(
        ['total-inactive-users-count', paginationStoreState.search],
        previousInactiveCount + 1,
      );

      paginationStoreState.tabs[2].count = previousInactiveCount + 1;
    }

    if (paginationStoreState.activeTabId === 1) {
      setNewUsersInViewInTabAll({ id, active: false });
    } else {
      removeUserFromList({ id });
    }
  }

  function updateCountWhenActiveUser({
    id,
  }: IUpdateCountWhenDeactivateUser): void {
    const { previousActiveCount, previousInactiveCount } = getPreviousCount();

    if (previousActiveCount) {
      queryClient.setQueryData(
        ['total-active-users-count', paginationStoreState.search],
        previousActiveCount + 1,
      );

      paginationStoreState.tabs[1].count = previousActiveCount + 1;
    }

    if (previousInactiveCount) {
      queryClient.setQueryData(
        ['total-inactive-users-count', paginationStoreState.search],
        previousInactiveCount - 1,
      );

      paginationStoreState.tabs[2].count = previousInactiveCount - 1;
    }

    if (paginationStoreState.activeTabId === 1) {
      setNewUsersInViewInTabAll({ id, active: true });
    } else {
      removeUserFromList({ id });
    }
  }

  const createUserInvite = async ({
    name,
    last_name,
    email,
    external_roles,
  }: ICreateUserInvite): Promise<void> => {
    usersStoreActions.setIsLoading(true);

    inviteUser({
      greeting_name: `${name} ${last_name}`,
      emails: email,
      external_roles: [external_roles],
      // org_id: user?.org.id, // Não vamos mandar o org_id por enquanto.
      link_config: 'util',
    })
      .then((res) => {
        usersStoreActions.setOpenDetailedModal(false);

        if (res.status === 201) {
          addToast({
            title: t('users.toast.success.title'),
            message: t('users.toast.success.message'),
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.USERS_CREATE_INVITE,
            eventDescription: `User ${name} ${last_name} was invited to the organization`,
          });

          queryClient.invalidateQueries({
            queryKey: ['invites'],
          });
        }
      })
      .catch((err) => {
        usersStoreActions.setOpenDetailedModal(false);
        const { data } = err.response;

        switch (data.code) {
          case 'email_already_invited_resend':
            addToast({
              title: t('users.toast.error.title'),
              message: `${t('users.toast.error.messageOne')} ${email} ${t('users.toast.error.messageTwo')}`,
              type: 'success',
            });

            return;

          case 'email_already_invited_other_org':
            addToast({
              title: t('users.messages.userAlreadyInvitedTitle'),
              message: t('users.messages.userAlreadyInvitedDescription', {
                email,
              }),
              type: 'warning',
            });

            return;

          case 'email_already_in_use':
            addToast({
              title: t('users.messages.userInvitedRecentlyTitle'),
              message: t('users.messages.userInvitedRecentlyDescription', {
                email,
              }),
              type: 'error',
            });

            return;

          case 'email_already_invited_recently':
            addToast({
              title: t('users.messages.userAlreadyInvitedUsedTitle'),
              message: t('users.messages.userAlreadyInvitedUsedDescription', {
                email,
              }),
              type: 'warning',
            });

            return;
        }

        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });

        return;
      })
      .finally(() => {
        usersStoreActions.setIsLoading(false);
      });
  };

  const activeUserById = async (id: number): Promise<void> => {
    await activateUser(id)
      .then((res) => {
        if (res.status === 202) {
          addToast({
            title: t('users.messages.userActivatedTitle'),
            message: t('users.messages.userActivatedDescription'),
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.USERS_ACTIVATE,
            eventDescription: `User ${id} was activated`,
          });

          updateCountWhenActiveUser({
            id,
          });
        }

        if (res.status === 401) {
          addToast({
            title: t('users.messages.userNotActiveTitle'),
            message: t('users.messages.userNotActiveDescription'),
            type: 'error',
          });
        }
      })
      .catch(() => {
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });

        return;
      });
  };

  const deactivateUserById = async (
    id: number,
    userCardInfo: IUserCardInfo,
  ): Promise<void> => {
    deactivateUser(id)
      .then((response) => {
        if (response.status === 202) {
          addToast({
            title: t('users.messages.userDeactivatedTitle'),
            message: `${userCardInfo.name} ${t('users.messages.userDeactivatedDescription')}`,
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.USERS_DEACTIVATE,
            eventDescription: `User ${id} was deactivated`,
          });

          updateCountWhenDeactivateUser({ id });
        }

        if (response.status === 404) {
          addToast({
            title: t('users.messages.userNotFoundTitle'),
            message: t('users.messages.userNotFoundDescription'),
            type: 'error',
          });
        }
      })
      .catch((err) => {
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });
        return err;
      });
  };

  const deleteInviteById = async (id: number): Promise<void> => {
    usersStoreActions.setIsDeleteInviteLoading(true);

    deleteInvite(id)
      .then((res) => {
        if (res.status === 202) {
          addToast({
            title: t('users.messages.userInviteDeletedTitle'),
            message: t('users.messages.userInviteDeletedDescription'),
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.USERS_DELETE_INVITE,
            eventDescription: `User invite ${id} was deleted`,
          });

          queryClient.invalidateQueries({
            queryKey: ['invites'],
          });

          usersStoreActions.setOpenDeleteInviteModal(false);
        }
      })
      .catch(() => {
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });
      })
      .finally(() => {
        usersStoreActions.setIsDeleteInviteLoading(false);
      });
  };

  const updateRole = async ({
    id,
    role,
    profile_access_id,
  }: UpdateUser): Promise<void> => {
    usersStoreActions.setIsChangeRoleLoading(true);

    updateUserRole(id, role, profile_access_id)
      .then((res) => {
        if (res.status === 202) {
          addToast({
            title: t('users.toast.successRoleChange.title'),
            message: t('users.toast.successRoleChange.message'),
            type: 'success',
          });

          logEventAnalytics({
            eventName: FBAnalyticsEventTitles.USERS_UPDATE_ROLE,
            eventDescription: `User ${id} role was updated to ${role}`,
          });

          queryClient.invalidateQueries({
            queryKey: ['get-users'],
          });

          usersStoreActions.setRoleModal(false);
        }
      })
      .catch((err) => {
        addToast({
          title: t('toast.error.title'),
          message: t('toast.error.message'),
          type: 'error',
        });

        return err;
      })
      .finally(() => {
        usersStoreActions.setIsChangeRoleLoading(false);
      });
  };

  return {
    features,
    createUserInvite,
    deactivateUserById,
    deleteInviteById,
    activeUserById,
    updateRole,
  };
};
