import { useI18n, useToast } from '@group-link-one/grouplink-components';
import { useMutation } from '@tanstack/react-query';

import { queryClient } from '../../../Services/queryClient';
import { useTagsService } from '../../../Services/tagsService/useTagsService';
import { GetTagRow } from '../../../Services/tagsService/useTagsService.type';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../stores/tags.store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

interface ModalDeleteTagProps {
  t: (key: string) => string;
  actions: TagsStoreActions;
  state: TagsStoreState;
  onDelete: () => Promise<void>;
}

export const useModalDeleteTag = (): ModalDeleteTagProps => {
  const { logEventAnalytics } = useFBAnalytics();
  const { t } = useI18n();
  const { actions, state } = useTagsStore();
  const { deleteTag } = useTagsService();
  const { addToast } = useToast();

  function updateTagList(): void {
    const tagsCached: GetTagRow[] | undefined = queryClient.getQueryData([
      'get-tags',
      state.search,
    ]);

    if (tagsCached) {
      queryClient.setQueryData(
        ['get-tags', state.search],
        tagsCached.filter((tag) => tag.id !== state.activeTagId),
      );
    }
  }

  const { mutateAsync: onDelete } = useMutation({
    mutationFn: async () => {
      if (!state.activeTagId) {
        return;
      }

      await deleteTag(Number(state.activeTagId));
    },
    onSuccess: () => {
      addToast({
        type: 'success',
        title: t('tags.content.toasts.deleteTagSuccess.title'),
        message: t('tags.content.toasts.deleteTagSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.TAGS_DELETE,
        eventDescription: 'End Tag deleted',
      });

      updateTagList();

      actions.setModalDeleteTagIsOpen(false);
      actions.setActiveTagId(undefined);
    },
  });

  return {
    actions,
    state,
    t,
    onDelete,
  };
};
