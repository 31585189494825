export function getColorByVar(varName: string): string {
  const varColorName = String(varName)
    .replace('var(', '')
    .replace(')', '')
    .trim();

  const varColor = getComputedStyle(document.documentElement).getPropertyValue(
    varColorName,
  );

  return varColor;
}
