import {
  Colors,
  GLPieChartDataProps,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useMemo } from 'react';

import { useGetDailyReadings } from '../../DeviceList/ModalDeviceDetails/Graphs/useGetDailyReadings';
import { useDetailsDeviceStore } from '../../DeviceList/store/details-device-store';
import {
  HealthCheckStoreActions,
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../store/health-check-store';
import {
  DevicesActivatedLastReadingsChannel,
  GetDevicesActivatedDailyReadingsRow,
} from '@/Services/deviceListService/useDeviceListService.types';
import { useQuery } from '@tanstack/react-query';
import { useDeviceGroupsUtils } from '@/Pages/GroupList/useDeviceGroupsUtils';
import { GetOrgDeviceGroupIdResponse } from '@/Services/groupListService/useGroupListService.types';

type DailyReadingInfo = {
  with_readings: number;
  without_readings: number;
  readingsFormatted: GetDevicesActivatedDailyReadingsRow[];
};


interface IUseDeviceModal {
  healthCheckState: HealthCheckStoreState;
  healthCheckActions: HealthCheckStoreActions;
  communicationRate: string;
  dailyReadingInfo: DailyReadingInfo | undefined;
  pieChartData: GLPieChartDataProps[];
  t: (key: string) => string;
  currentDeviceChannel: DevicesActivatedLastReadingsChannel | undefined;
  groups: GetOrgDeviceGroupIdResponse | undefined | unknown;
  onClickSeeDetails: () => void;
}

export const useDeviceModal = (): IUseDeviceModal => {
  const { getDeviceGroup } = useDeviceGroupsUtils();
  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const { t } = useI18n();

  const { actions: detailsDeviceActions } = useDetailsDeviceStore();

  const { formatReadings } = useGetDailyReadings();

  const { data: groups } = useQuery({
    queryKey: [
      'getOrgDeviceGroupId',
      healthCheckState.deviceSelected?.groups[0],
    ],
    queryFn: async () => {
      if (!healthCheckState.deviceSelected) return;

      try {
        const response = await getDeviceGroup(
          healthCheckState.deviceSelected?.groups[0],
        );

        return response;
      } catch (error) {
        return error;
      }
    },
  });

  function onClickSeeDetails(): void {
    if (!healthCheckState.deviceSelected) {
      return;
    }
    detailsDeviceActions.setDevice(healthCheckState.deviceSelected);
    detailsDeviceActions.setModalIsOpen(true);
  }

  const currentDeviceChannel = healthCheckState.deviceSelected?.channels[0];

  const dailyReadingInfo = useMemo(() => {
    if (!healthCheckState.deviceSelectedDailyReadings) {
      return;
    }

    const { readingsFormatted } = formatReadings(
      healthCheckState.deviceSelectedDailyReadings.rows,
      healthCheckState.range.from,
      healthCheckState.range.to,
    );

    return {
      with_readings: readingsFormatted.filter((reading) => reading.reading > 0)
        .length,
      without_readings: readingsFormatted.filter(
        (reading) => reading.reading === 0,
      ).length,
      readingsFormatted,
    };
  }, [healthCheckState.deviceSelectedDailyReadings]);

  const pieChartData: GLPieChartDataProps[] = useMemo(() => {
    if (!dailyReadingInfo) {
      return [];
    }

    return [
      {
        id: 'with_readings',
        label: t('healthCheck.deviceSelected.communicationRate.withReadings'),
        value: dailyReadingInfo.with_readings,
        color: Colors.LIGHT_GREEN,
        hasTooltip: false,
        description: t(
          'healthCheck.deviceSelected.communicationRate.withReadingAbr',
        ),
      },
      {
        id: 'without_readings',
        label: t(
          'healthCheck.deviceSelected.communicationRate.withoutReadings',
        ),
        value: dailyReadingInfo.without_readings,
        color: Colors.ORANGE,
        hasTooltip: false,
        description: t(
          'healthCheck.deviceSelected.communicationRate.withoutReadingAbr',
        ),
      },
    ];
  }, [dailyReadingInfo]);

  const communicationRate = useMemo(() => {
    if (!healthCheckState.deviceSelectedDailyReadings) {
      return '0%';
    }

    const totalReadings = dailyReadingInfo?.readingsFormatted.length || 0;
    const readingsWithCommunication =
      dailyReadingInfo?.readingsFormatted.filter(
        (reading) => reading.reading > 0,
      ).length || 0;

    return ((readingsWithCommunication / totalReadings) * 100)
      .toFixed(1)
      .concat('%');
  }, [dailyReadingInfo]);

  return {
    healthCheckState,
    healthCheckActions,
    communicationRate,
    dailyReadingInfo,
    pieChartData,
    t,
    currentDeviceChannel,
    groups,
    onClickSeeDetails,
  };
};
