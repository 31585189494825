import { GLSettingsUsers } from '@group-link-one/grouplink-components';

import { UsersContent } from './Content/usersContent';
import { useOrganizationUsers } from './useOrganizationUsers';

export const UsersPage = (): JSX.Element => {
  const {
    features,
    activeUserById,
    createUserInvite,
    deactivateUserById,
    deleteInviteById,
    updateRole,
  } = useOrganizationUsers();

  return (
    <GLSettingsUsers
      flagsOptions={{
        showBtnCreate: features.users.showBtnCreate,
        showBtnChangeRole: features.users.showBtnChangeRole,
        showBtnSetCustomRole: features.users.showBtnSetCustomRole,
        showBtnSetAdminRole: features.users.showBtnSetAdminRole,
        showBtnSetMemberRole: features.users.showBtnSetMemberRole,
        showBtnDeactivatedUser: features.users.showBtnDeactivatedUser,
        showFilterSearch: features.users.showFilterSearch,
      }}
      onActiveUserById={activeUserById}
      onCreateUserInvite={createUserInvite}
      onDeactivateUserById={deactivateUserById}
      onDeleteInviteById={deleteInviteById}
      onChangeRole={updateRole}
    >
      <UsersContent />
    </GLSettingsUsers>
  );
};
