import {
  AlertType,
  BigNumberProps,
  Colors,
  GL_CHECK,
  GL_COLORS,
  GL_GLStation,
  GL_LOGIN,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useQuery } from '@tanstack/react-query';
import { format } from 'date-fns';
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'usehooks-ts';

import CheckImg from '../../../../images/DeviceList/icon-check.svg';
// import InternetImg from "../../../../images/DeviceList/icon-internet.svg";
import LoginImg from '../../../../images/DeviceList/icon-log-in.svg';
import StationImg from '../../../../images/DeviceList/icon-station.svg';
import { useDeviceListService } from '../../../../Services/deviceListService/useDeviceListService';
import { GetDeviceHealthCheckCountResponse } from '../../../../Services/deviceListService/useDeviceListService.types';
import {
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../../../HealthCheckMap/store/health-check-store';
import { Features, usePrivileges } from '@/hooks/usePrivilleges';

interface IUseHealthCheckBigNumbers {
  bigNumbers: BigNumberProps[];
  healthCheckState: HealthCheckStoreState;
  features: Features;
  isLoading: boolean;
  isSM: boolean;
  onClickHideShowBigNumbers: () => void;
}

export const useHealthCheckBigNumbers = (): IUseHealthCheckBigNumbers => {
  const isSM = useMediaQuery('(max-width: 660px)');

  const { getDevicesHealthCheckCount } = useDeviceListService();
  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const { t } = useI18n();
  const { features } = usePrivileges();

  const alertsOnly = useMemo(() => {
    if (healthCheckState.alertFilterValue?.length === 0) {
      return undefined;
    }

    if (healthCheckState.alertFilterValue?.length === 1) {
      if (healthCheckState.alertFilterValue[0].id === 'all') {
        return true;
      }

      if (healthCheckState.alertFilterValue[0].id === 'with-alert') {
        return true;
      }
      if (healthCheckState.alertFilterValue[0].id === 'without-alert') {
        {
          return false;
        }
      }

      return undefined;
    }
  }, [healthCheckState.alertFilterValue]);

  const alerts = useMemo(() => {
    if (healthCheckState.alertTypesSelected?.length === 0) {
      return undefined;
    }

    return healthCheckState.alertTypesSelected?.map((alert) => alert.id);
  }, [healthCheckState.alertTypesSelected]);

  const from = useMemo(
    () =>
      format(
        healthCheckState.range.from || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      ),
    [healthCheckState.range.from],
  );

  const until = useMemo(
    () =>
      format(
        healthCheckState.range.to || new Date(),
        "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'",
      ),
    [healthCheckState.range.to],
  );

  const userGroupSelected: number[] | undefined = useMemo(() => {
    if (!healthCheckState?.userGroupSelecteds) {
      return [];
    }
    const userGroupsId = healthCheckState?.userGroupSelecteds?.map(
      (userGroup) => Number(userGroup.id),
    );
    return userGroupsId;
  }, [healthCheckState?.userGroupSelecteds]);

  const { data: bigNumberCount, isLoading } = useQuery({
    queryKey: [
      'devicesHealthCheckCount',
      from,
      until,
      alertsOnly,
      alerts,
      userGroupSelected,
    ],
    queryFn: async () => {
      const response = await getDevicesHealthCheckCount({
        from,
        until,
        alerts_only: alertsOnly,
        alerts: alerts as AlertType[],
        group_ids: userGroupSelected,
      });

      return response;
    },
  });

  function calculeCommunicationRate(
    devicesHealthCheckCountParam: GetDeviceHealthCheckCountResponse | null,
  ): string | number {
    if (!devicesHealthCheckCountParam) {
      return 0;
    }

    // eslint-disable-next-line
    const total = calculeBigNumber(devicesHealthCheckCountParam, 'total');
    // eslint-disable-next-line
    const devicesWithReadings = calculeBigNumber(
      devicesHealthCheckCountParam,
      'devices_with_readings',
    );

    if (total === 0) {
      return 0;
    }

    return (
      ((Number(devicesWithReadings) / Number(total)) * 100).toFixed(2) + '%'
    );
  }

  function calculeBigNumber(
    devicesHealthCheckCountParam: GetDeviceHealthCheckCountResponse | null,
    type:
      | 'devices_with_readings'
      | 'devices_without_readings'
      | 'total'
      | 'communication_rate',
  ): string | number {
    if (!devicesHealthCheckCountParam) {
      return 0;
    }

    switch (type) {
      case 'devices_with_readings':
        return devicesHealthCheckCountParam.devices_with_readings;
      case 'devices_without_readings':
        return devicesHealthCheckCountParam.devices_without_readings;
      case 'total':
        return (
          devicesHealthCheckCountParam.devices_with_readings +
          devicesHealthCheckCountParam.devices_without_readings
        );
      case 'communication_rate':
        return calculeCommunicationRate(devicesHealthCheckCountParam);
    }
  }

  const bigNumbers: BigNumberProps[] = useMemo(() => {
    const allBigNumbers: BigNumberProps[] = [
      {
        iconLeft: (
          <GL_GLStation
            fillOne={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            fill={GL_COLORS.ULTRADARK_GREY_SECONDARY}
            size={24}
          />
        ),
        iconRight: <img src={StationImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('healthCheck.bigNumbers.totalDevices.title'),
        count: calculeBigNumber(
          healthCheckState.devicesHealthCheckCount,
          'total',
        ),
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.LIGHT_GREEN,
        textColor: 'ULTRADARK_GREY_SECONDARY',
        tooltipText: t(
          'healthCheck.bigNumbers.totalDevices.tooltipDescription',
        ),
        tooltipPosition: 'top-right',
        tooltipZIndex: 999,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isToAppear: features.healthCheck.bigNumbers.totalDevices,
      },
      {
        iconLeft: <GL_LOGIN fill={GL_COLORS.WHITE} size={24} />,
        iconRight: <img src={LoginImg} alt="" />,
        iconRightStyle: {
          bottom: -20,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('healthCheck.bigNumbers.withReadings.title'),
        count: calculeBigNumber(
          healthCheckState.devicesHealthCheckCount,
          'devices_with_readings',
        ),
        type: isSM ? 'sm' : 'lg',
        backgroundColor: Colors.NAVY,
        textColor: 'WHITE',
        tooltipText: t(
          'healthCheck.bigNumbers.withReadings.tooltipDescription',
        ),
        helpIconColor: 'WHITE',
        tooltipPosition: 'top-right',
        tooltipZIndex: 999,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isToAppear: features.healthCheck.bigNumbers.withReadings,
      },
      {
        iconLeft: <GL_CHECK fill={GL_COLORS.FONT_COLOR_VARIANT} size={24} />,
        iconRight: <img src={CheckImg} alt="" />,
        iconRightStyle: {
          bottom: 0,
          right: isSM ? -40 : 20,
          transform: isSM ? 'scale(0.85)' : 'none',
        },
        title: t('healthCheck.bigNumbers.noReadings.title'),
        count: calculeBigNumber(
          healthCheckState.devicesHealthCheckCount,
          'devices_without_readings',
        ),
        type: isSM ? 'sm' : 'lg',
        backgroundColor: GL_COLORS.ACCENT_COLOR,
        textColor: 'FONT_COLOR_VARIANT',
        tooltipText: t('healthCheck.bigNumbers.noReadings.tooltipDescription'),
        helpIconColor: 'WHITE',
        tooltipPosition: 'top-right',
        tooltipZIndex: 999,
        tooltipTextStyle: {
          whiteSpace: 'nowrap',
        },
        isToAppear: features.healthCheck.bigNumbers.withoutReadings,
      },
    ];

    return allBigNumbers.filter((bigNumber) => bigNumber.isToAppear);
  }, [healthCheckState.devicesHealthCheckCount]);

  function onClickHideShowBigNumbers(): void {
    healthCheckActions.setBigNumbersIsOpen(!healthCheckState.bigNumbersIsOpen);
  }

  useEffect(() => {
    healthCheckActions.setDevicesHealthCheckCount(bigNumberCount || null);
  }, [bigNumberCount]);

  return {
    bigNumbers,
    features,
    healthCheckState,
    isLoading,
    isSM,
    onClickHideShowBigNumbers,
  };
};
