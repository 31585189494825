export type ErrorType =
  | 'invalid_email_or_pass'
  | 'blocked_password'
  | 'default';

interface SmartRetailErrorsProps {
  error: ErrorType;
}

export class SmartRetailErrors {
  private error: ErrorType;

  constructor(props: SmartRetailErrorsProps) {
    this.error = props.error;
  }

  private errorsMap(): {
    [key in ErrorType]: {
      title: string;
      message: string;
    };
  } {
    return {
      invalid_email_or_pass: {
        title: 'Login failed',
        message: 'Invalid email or password',
      },

      blocked_password: {
        title: 'Blocked account',
        message:
          "Your account has been blocked after several failed login attempts. Please use the 'Forgot Password?' option to log in again",
      },

      default: {
        title: 'Error',
        message: 'An error occurred',
      },
    };
  }

  public getError(): {
    title: string;
    message: string;
  } {
    const error =
      this.error && this.errorsMap()[this.error]
        ? this.errorsMap()[this.error]
        : this.errorsMap().default;

    return error;
  }
}
