import {
  AlertType,
  formatValueSize,
  GL_ALARM,
  GL_ARROW_TRIANGLE_LEFT_FILL,
  GL_CHEVRON_DOWN,
  GL_COLORS,
  GL_MONITOR,
  // GL_PENCIL,
  GL_PIN,
  GL_TAG,
  GL_SMARTPHONE,
  GL_WIFI,
  GLBox,
  GLButton,
  GLDropdown,
  // GLTextField,
  GLTooltip,
  GLTypography,
  GL_DELETE,
  GLCardTemplate,
} from '@group-link-one/grouplink-components';
import { format } from 'date-fns';
import { motion } from 'framer-motion';

import { getOptionsByApplication } from '../../utils/getOptionsByApplication';
import {
  AlertsContainer,
  DeviceNameContainer,
  InfosContainer,
  TitleContainer,
} from './Style';
import { useDeviceDetailsTitleContent } from './useDeviceDetailsTitleContent';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { If } from '@/utils/GLStatement/If';

export function DeviceDetailsTitleContent(): JSX.Element {
  const {
    device,
    langActive,
    userUseCase,
    features,
    currentChannelValue,
    currentChannelData,
    detailsDeviceState,
    detailsDeviceActions,
    t,
    alertsMap,
    editingDeviceNameValue,
    hasAlarms,
    hasMoreThanOneChannel,
    hasToShowTagsInfo,
    isEditingDeviceName,
    is1190px,
    isMobile,
    is768Px,
    editingRemoteIdValue,
    onDeviceNameClick,
    onAddressClick,
    onChannelChange,
    formatActivationPlatform,
    logEventAnalytics,
    setURLParams,
  } = useDeviceDetailsTitleContent();

  const activateByText = `${currentChannelData?.activated_by} | ${currentChannelData?.activated_by_email}`;

  const boxInfoStyle: React.CSSProperties = {
    borderRadius: 15,
    backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
    padding: '15px 20px',
  };

  return (
    <GLBox
      width="100%"
      animation="fade-in-down"
      align="center"
      direction="column"
      transition={{
        delay: 0.3,
        duration: 1.4,
      }}
    >
      <TitleContainer>
        <GLBox
          layout
          direction="column"
          justify="flex-start"
          gap={5}
          width="auto"
        >
          <DeviceNameContainer
            layout
            className={isEditingDeviceName ? 'editing' : ''}
            onClick={() => onDeviceNameClick('device_name')}
            tabIndex={0}
            role="button"
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                onDeviceNameClick('device_name');
              }
            }}
          >
            <GLTypography
              as="p"
              text={editingDeviceNameValue || ''}
              color="FONT_COLOR"
              weight={600}
              fontSize={is768Px ? 5.5 : 6.5}
              style={{
                whiteSpace: !is768Px ? 'nowrap' : 'normal',
                outline: 'transparent',
              }}
            />
          </DeviceNameContainer>

          <GLTypography
            as="span"
            text={`ID ${device?.device_id || ''}`}
            color="DISABLED_FONT_COLOR"
            weight={600}
            fontSize={3}
            style={{ whiteSpace: 'nowrap' }}
          />
        </GLBox>

        <GLBox layout>
          <GLTooltip
            text={
              device &&
              device?.meta?.display_address?.length &&
              device?.meta.display_address.length > 70
                ? device.meta.display_address
                : undefined
            }
          >
            <GLButton
              text={
                formatValueSize({
                  size: 70,
                  value: device?.meta.display_address || '',
                }) || '--'
              }
              variant="outline"
              align="left"
              border={false}
              weight={600}
              colorIconDynamically={true}
              icon={
                <GL_PIN
                  size={16}
                  fill={
                    getOptionsByApplication({
                      type: device?.application,
                    }).color
                  }
                />
              }
              color={
                getOptionsByApplication({
                  type: device?.application,
                }).color
              }
              style={{ width: 'fit-content', textAlign: 'left' }}
              onClick={onAddressClick}
            />
          </GLTooltip>
        </GLBox>
      </TitleContainer>

      <If condition={hasAlarms}>
        <GLBox align="center" gap={20}>
          <GLTypography
            text="SYSTEM ALARMS"
            color="FONT_COLOR_DARK_GREY"
            as="span"
            fontSize={3.5}
            weight={600}
            style={{ whiteSpace: 'nowrap' }}
          />

          <GL_ARROW_TRIANGLE_LEFT_FILL
            size={12}
            fill={GL_COLORS.DISABLED_FONT_COLOR}
          />

          <AlertsContainer>
            {currentChannelData && currentChannelData.alerts.map((item, index) => (
              <motion.div
                key={index}
                initial={{
                  opacity: 0,
                  x: -20,
                }}
                animate={{
                  opacity: 1,
                  x: 0,
                }}
                transition={{
                  duration: 0.5,
                  delay: index * 0.15,
                }}
              >
                <GLBox
                  key={index}
                  align="center"
                  justify="center"
                  width="fit-content"
                  style={{
                    borderRadius: 20,
                    padding: '10px 15px',
                    border: `2px solid ${GL_COLORS.BORDER_COLOR}`,
                    backgroundColor: GL_COLORS.DANGER,
                    minWidth: 100,
                  }}
                >
                  <GL_ALARM size={16} fill={GL_COLORS.WHITE} />

                  <GLTypography
                    text={alertsMap[item as AlertType] || ''}
                    color="WHITE"
                    as="span"
                    fontSize={3.5}
                    weight={600}
                    style={{ whiteSpace: 'nowrap' }}
                  />
                </GLBox>
              </motion.div>
            ))}
          </AlertsContainer>
        </GLBox>
      </If>

      <If condition={hasToShowTagsInfo}>
        <GLBox align="center" gap={20}>
          <If condition={features.deviceList.detailsModal.showTags}>
            <GLTypography
              text="Tags"
              color="FONT_COLOR_DARK_GREY"
              as="span"
              fontSize={3.5}
              weight={600}
              style={{ whiteSpace: 'nowrap' }}
            />

            <GL_ARROW_TRIANGLE_LEFT_FILL
              size={12}
              fill={GL_COLORS.DISABLED_FONT_COLOR}
            />

            {device && device?.tags?.length > 0 && (
              <AlertsContainer>
                {device?.tags?.map((item, index) => (
                  <motion.div
                    key={index}
                    initial={{
                      opacity: 0,
                      x: -20,
                    }}
                    animate={{
                      opacity: 1,
                      x: 0,
                    }}
                    transition={{
                      duration: 0.5,
                      delay: index * 0.15,
                    }}
                  >
                    <GLBox>
                      <GLBox
                      key={index}
                      align="center"
                      justify="center"
                      width="fit-content"
                      style={{
                        gap: 10,
                        borderRadius: 20,
                        padding: '5px 15px',
                        border: `2px solid ${GL_COLORS.BORDER_COLOR}`,
                        backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                      }}
                    >
                      <GL_TAG
                        size={18}
                        fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                      />

                        <GLBox direction="row" align="center">
                          <GLBox direction="row">
                            <GLTypography
                              text={item.key}
                              color="FONT_COLOR_DARK_GREY"
                              as="span"
                              fontSize={3.5}
                              style={{ whiteSpace: 'nowrap' }}
                            />
                          </GLBox>

                          <GLTypography text="|" />

                          <GLBox direction="row">
                            <GLTypography
                              text={item.value}
                              color="FONT_COLOR_DARK_GREY"
                              as="span"
                              fontSize={3.5}
                              style={{ whiteSpace: 'nowrap' }}
                            />
                          </GLBox>

                          <GLCardTemplate.Actions
                            showOnHover
                            disappearOnHover={true}
                          >
                            <GLBox
                              style={{
                                cursor: 'pointer',
                              }}
                              onClick={() => {
                                detailsDeviceActions.setModalUnassignTagIsOpen(
                                  true,
                                );

                                setURLParams({
                                  q: ""
                                })

                                detailsDeviceActions.setTagSelected({
                                  key: item.key,
                                  value: item.value,
                                });
                              }}
                            >
                              <GL_DELETE
                                fill={GL_COLORS.FONT_COLOR_DARK_GREY}
                                size={15}
                              />
                            </GLBox>
                          </GLCardTemplate.Actions>
                        </GLBox>

                      </GLBox>
                    </GLBox>
                  </motion.div>
                ))}
              </AlertsContainer>
            )}
          </If>

          <If condition={features.deviceList.detailsModal.createTag}>
            <motion.div
              initial={{
                opacity: 0,
                x: -20,
              }}
              animate={{
                opacity: 1,
                x: 0,
              }}
              transition={{
                duration: 0.5,
                delay: 0 * 0.15,
              }}
            >
              <GLBox
                align="center"
                justify="center"
                width={'150px'}
                style={{
                  gap: 10,
                  padding: '5px 15px',
                  borderRadius: 20,
                  border: `2px dashed ${GL_COLORS.BORDER_COLOR}`,
                  backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
                }}
              >
                <GL_TAG size={18} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />

                <GLTypography
                  text={t('deviceList.detailsModal.tags.addTag')}
                  style={{
                    cursor: 'pointer',
                  }}
                  fontSize={3.5}
                  onClick={() => {
                    logEventAnalytics({
                      eventName: FBAnalyticsEventTitles.DEVICE_LIST_ADD_TAG,
                      eventDescription: 'Start Add Tag',
                    });
                    detailsDeviceActions.setModalAddTagIsOpen(true);
                  }}
                />
              </GLBox>
            </motion.div>
          </If>
        </GLBox>
      </If>

      <GLBox
        align={isMobile ? 'flex-start' : 'flex-end'}
        justify={isMobile ? 'flex-start' : 'space-between'}
        direction={isMobile ? 'column' : 'row'}
        gap={20}
      >
        <InfosContainer direction="column">
          <GLBox>
            <If condition={features.deviceList.detailsModal.showInfoRemoteId}>
              <GLBox
                width="fit-content"
                align="center"
                layout
                onClick={() => onDeviceNameClick('remote_id')}
                style={{
                  ...boxInfoStyle,
                }}
              >
                <GLTypography
                  text="REMOTE ID"
                  color="FONT_COLOR_DARK_GREY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                />
                <GLTypography
                  text={editingRemoteIdValue || '--'}
                  color="TITLE_COLOR_SECONDARY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                />
              </GLBox>
            </If>

            <If condition={userUseCase === 'util_water'}>
              <If condition={features.deviceList.detailsModal.showInfoOffset}>
                <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    text="OFFSET"
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                  <GLTypography
                    text={String(currentChannelData?.device_offset) || ''}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox>
              </If>

              <If condition={features.deviceList.detailsModal.showInfoPulso}>
                <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    text={`${
                      getOptionsByApplication({
                        type: detailsDeviceState.device?.application,
                      }).unit || ''
                    } / PULSO`}
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                    style={{ textTransform: 'uppercase' }}
                  />
                  <GLTypography
                    text={String(currentChannelData?.device_pulse_factor) || ''}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox>
              </If>
            </If>

            <If condition={userUseCase === 'util_light'}>
                <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    text="Tipo de lâmpada"
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                  <GLTypography
                    text={
                      detailsDeviceState.device?.meta.light_device_category ||
                      ''
                    }
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox>

                {/* <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    text="Voltagem"
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                  <GLTypography
                    text={streetLightValues.rms_voltage || ""}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox>

                <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                  <GLTypography
                    text="Tensão"
                    color="FONT_COLOR_DARK_GREY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                  <GLTypography
                    text={streetLightValues.rms_current || ""}
                    color="TITLE_COLOR_SECONDARY"
                    as="span"
                    fontSize={is1190px ? 3 : 3.5}
                    weight={600}
                  />
                </GLBox> */}
            </If>

            <If condition={features.deviceList.detailsModal.showInfoActivatedAt}>
              <GLBox layout width="fit-content" style={{ ...boxInfoStyle }}>
                <GLTypography
                  text={t('deviceList.detailsModal.activatedAt')}
                  color="FONT_COLOR_DARK_GREY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                  style={{ textTransform: 'uppercase' }}
                />
                <GLTypography
                  text={
                    device
                      ? format(
                          new Date(currentChannelData?.activated_at || ''),
                          'dd/MM/yyyy',
                          {
                            locale: langActive,
                          },
                        )
                      : ''
                  }
                  color="TITLE_COLOR_SECONDARY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                />
              </GLBox>
            </If>
          </GLBox>

          <GLBox direction="row">
            <If condition={features.deviceList.detailsModal.showInfoActivatedBy}>
              <GLBox
                layout
                width="fit-content"
                style={{ ...boxInfoStyle, alignSelf: 'flex-start' }}
              >
                <GLTypography
                  text={t('deviceList.card.activatedBy')}
                  color="FONT_COLOR_DARK_GREY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                  style={{ textTransform: 'uppercase' }}
                />
                <GLTypography
                  text={activateByText || ''}
                  color="TITLE_COLOR_SECONDARY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                  style={{ wordBreak: 'break-all' }}
                />
              </GLBox>
            </If>

            <If condition={features.deviceList.detailsModal.showInfoPlatform}>
              <GLBox
                layout
                width="fit-content"
                style={{ ...boxInfoStyle, alignSelf: 'flex-start' }}
              >
                <GLTypography
                  text={t('deviceList.detailsModal.activationPlatform')}
                  color="FONT_COLOR_DARK_GREY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                  style={{ textTransform: 'uppercase' }}
                />

                {device?.meta.activation_mode === 'app' ? (
                  <GL_SMARTPHONE fill={GL_COLORS.FONT_COLOR} />
                ) : device?.meta.activation_mode === 'server_side' ? (
                  <GL_WIFI fill={GL_COLORS.FONT_COLOR} />
                ) : (
                  <GL_MONITOR fill={GL_COLORS.FONT_COLOR} />
                )}

                <GLTypography
                  text={formatActivationPlatform()}
                  color="TITLE_COLOR_SECONDARY"
                  as="span"
                  fontSize={is1190px ? 3 : 3.5}
                  weight={600}
                  style={{ wordBreak: 'break-all' }}
                />
              </GLBox>
            </If>
          </GLBox>
        </InfosContainer>

        <If condition={hasMoreThanOneChannel}>
          <GLDropdown.Root width="130px">
            <GLDropdown.Input
              width="100%"
              icon={
                <GL_CHEVRON_DOWN
                  size={12}
                  fill={GL_COLORS.DISABLED_FONT_COLOR}
                />
              }
              value={currentChannelValue}
              valueOptions={{
                whiteSpace: 'nowrap',
                fontWeight: 600,
              }}
            />

            <GLDropdown.BoxOptions width="100%">
              {detailsDeviceState.device?.channels?.map((channel, index) => (
                <GLDropdown.Option
                  key={index}
                  text={`Channel ${channel.channel + 1}`}
                  value={`Channel ${channel.channel + 1}`}
                  hasCheckInActive={false}
                  onClick={(value) => {
                    onChannelChange(value);
                  }}
                />
              ))}
            </GLDropdown.BoxOptions>
          </GLDropdown.Root>
        </If>
      </GLBox>
    </GLBox>
  );
}
