import { GLDetailedModal } from '@group-link-one/grouplink-components';

import { ImpersonateModalContent } from './Content/ModalContent/ImpersonateModal';
import { useImpersonateContent } from './Content/ModalContent/useImpersonateContent';

export function ImpersonateModal(): JSX.Element {
  const { state, actions, impersonateContentActions } = useImpersonateContent();

  return (
    <GLDetailedModal
      content={<ImpersonateModalContent />}
      open={state.modalSteperContentIsOpen}
      setIs={(open) => {
        actions.setModalSteperContentIsOpen(open);
        if (!open) {
          actions.resetAll();
          impersonateContentActions.resetAll();
        }
      }}
      justifyContent="flex-start"
      width="100%"
    />
  );
}
