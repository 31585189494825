import {
  GL_BUILDING,
  GL_USER,
  GLModalSteperContentStoreActions,
  GLModalSteperContentStoreState,
  i18n,
} from '@group-link-one/grouplink-components';
import { create } from 'zustand';

interface ImpersonateStore {
  state: GLModalSteperContentStoreState;
  actions: GLModalSteperContentStoreActions;
}

export enum IMPERSONATE_STEP_NAMES_TO_KEYS {
  ORGANIZATION = 0,
  USER = 1,
}

export type ImpersonateStepNames = keyof typeof IMPERSONATE_STEP_NAMES_TO_KEYS;

export const useImpersonateStore = create<ImpersonateStore>((set) => ({
  state: {
    modalSteperContentIsOpen: false,
    stepActive: 0,
    steperIsOnTop: false,
    steps: [
      {
        key: 'organization',
        Icon: <GL_BUILDING />,
        title: i18n.t('impersonate.modal.steps.organization.step'),
        wasVisited: true,
      },
      {
        key: 'user',
        Icon: <GL_USER />,
        textToHere: i18n.t('impersonate.modal.button.continue'),
        title: i18n.t('impersonate.modal.steps.user.step'),
        wasVisited: false,
      },
    ].map((_, i) => ({ ..._, id: i })),
    modalType: 'creation',
    isLoading: false,
    textToCreate: i18n.t('impersonate.modal.button.impersonate'),
    textToEdit: i18n.t(
      'messageCenter.list.modal.creationModal.steps.four.headerButtonEdit',
    ),
  },
  actions: {
    setModalSteperContentIsOpen: (isOpen: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          modalSteperContentIsOpen: isOpen,
        },
      }));
    },

    setModalType: (type: GLModalSteperContentStoreState['modalType']) => {
      set((state) => ({
        state: {
          ...state.state,
          modalType: type,
        },
      }));
    },

    setSteperIsOnTop: (isOnTop: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          steperIsOnTop: isOnTop,
        },
      }));
    },

    setStepActive: (step: number) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive: step,
        },
      }));
    },

    setStepWasVisited: (stepName) => {
      set((state) => ({
        state: {
          ...state.state,
          steps: state.state.steps.map((step) => {
            if (
              step.id ===
              IMPERSONATE_STEP_NAMES_TO_KEYS[stepName as ImpersonateStepNames]
            ) {
              return {
                ...step,
                wasVisited: true,
              };
            }

            return step;
          }),
        },
      }));
    },

    setIsLoading: (isLoading: boolean) => {
      set((state) => ({
        state: {
          ...state.state,
          isLoading,
        },
      }));
    },

    toStepOnClickInEdit: (stepName) => {
      set((state) => ({
        state: {
          ...state.state,
          stepActive:
            IMPERSONATE_STEP_NAMES_TO_KEYS[stepName as ImpersonateStepNames],
        },
      }));
    },

    resetAll: () => {
      set((state) => ({
        state: {
          ...state.state,
          modalSteperContentIsOpen: false,
          stepActive: 0,
          steperIsOnTop: false,
          modalType: 'creation',
          isLoading: false,
        },
      }));
    },
  },
}));
