import { GetDevicesActivatedDailyReadingsRow } from '../Services/deviceListService/useDeviceListService.types';

export function convertDailyReading(
  readings?: GetDevicesActivatedDailyReadingsRow[],
): GetDevicesActivatedDailyReadingsRow[] {
  if (!readings) {
    return [];
  }

  let modifiedReadings: GetDevicesActivatedDailyReadingsRow[] = [...readings];

  modifiedReadings = modifiedReadings.map((reading, index) => {
    if (index === 0) {
      return { ...reading, reading: 0 };
    }

    const readingValue =
      reading.reading && readings[index - 1].reading
        ? reading.reading - readings[index - 1].reading > 0
          ? reading.reading - readings[index - 1].reading
          : 0
        : 0;

    return {
      ...reading,
      reading: Number(readingValue.toFixed(3)),
    };
  });

  return modifiedReadings;
}
