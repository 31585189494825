import { RecordWithId } from '../organizationUsersService/useOrganizationUsers.types';
import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import {
  AssignTagToDeviceBody,
  CreateTagBody,
  CreateTagResponse,
  CreateTagValueParams,
  GetTagByIdParams,
  GetTagByIdResponse,
  GetTagParams,
  GetTagResponse,
  GetTagsValuesParams,
  TagValueRow,
  UpdateTagParams,
  UpdateTagValuesParams,
  UseTagsService,
} from './useTagsService.type';

export const useTagsService = (): UseTagsService => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const createTag = async (data: CreateTagBody): Promise<CreateTagResponse> => {
    return httpPrivate.post('/device-tags', data);
  };

  const getTags = async ({
    key,
    value,
  }: GetTagParams): Promise<GetTagResponse> => {
    const response = await httpPrivate.get('/device-tags', {
      params: {
        key,
        value,
      },
    });

    return response.data;
  };

  const getTagById = async ({
    key,
    tag_key_id,
  }: GetTagByIdParams): Promise<GetTagByIdResponse> => {
    const response = await httpPrivate.get('/device-tags', {
      params: {
        key,
        tag_key_id,
      },
    });

    return response.data;
  };

  const updateTag = async ({
    id,
    data,
  }: UpdateTagParams): Promise<RecordWithId> => {
    return httpPrivate.patch(`/device-tags/${id}`, data);
  };

  const deleteTag = async (id: number): Promise<void> => {
    return httpPrivate.delete(`/device-tags/${id}`);
  };

  const createTagValue = async (
    data: CreateTagValueParams,
  ): Promise<RecordWithId> => {
    return httpPrivate.post('/device-tags/value', data);
  };

  const getTagValue = async ({
    key,
    tag_key_id,
  }: GetTagsValuesParams): Promise<TagValueRow> => {
    const response = await httpPrivate.get('/device-tags/value', {
      params: {
        key,
        tag_key_id,
      },
    });

    return response.data;
  };

  const deleteTagValue = async (id: number): Promise<void> => {
    return httpPrivate.delete(`/device-tags/value/${id}`);
  };

  const updateTagValue = async ({
    id,
    data,
  }: UpdateTagValuesParams): Promise<RecordWithId> => {
    return httpPrivate.patch(`/device-tags/value/${id}`, data);
  };

  const assignDeviceToTag = async (
    data: AssignTagToDeviceBody,
  ): Promise<void> => {
    return httpPrivate.post('/device-tags/manage', data);
  };

  return {
    createTag,
    getTags,
    getTagById,
    deleteTag,
    updateTag,
    createTagValue,
    getTagValue,
    deleteTagValue,
    updateTagValue,
    assignDeviceToTag,
  };
};
