import {
  IGroupsUser,
  PaginateResponseProps,
} from '@group-link-one/grouplink-components';

import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import {
  ICreateGroupsBody,
  IRequestParams,
  OrganizationGroupsService,
} from './useOrganizationUsers.types';
import { RecordWithId } from '../organizationUsersService/useOrganizationUsers.types';

export const useOrganizationGroupsService = (): OrganizationGroupsService => {
  const { httpPrivate } = useAxiosHTTPRequest();
  const getOrganizationGroups = async ({
    id,
    name,
    is_default,
  }: IRequestParams): Promise<PaginateResponseProps<IGroupsUser[]>> => {
    const response = await httpPrivate.get('/org-group', {
      params: {
        id,
        name,
        is_default,
      },
    });

    return response.data;
  };

  const createOrganizationGroup = async (
    data: ICreateGroupsBody,
  ): Promise<RecordWithId> => {
    return await httpPrivate.post('/org-group', data);
  };

  const updateOrganizationGroup = async (
    id: number,
    data: Partial<ICreateGroupsBody>,
  ): Promise<void> => {
    return await httpPrivate.patch(`/org-group/${id}`, data);
  };

  const deleteOrganizationGroup = async (id: number): Promise<void> => {
    return await httpPrivate.delete(`/org-group/${id}`);
  };

  return {
    getOrganizationGroups,
    createOrganizationGroup,
    updateOrganizationGroup,
    deleteOrganizationGroup,
  };
};
