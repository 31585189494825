import { useI18n } from '@group-link-one/grouplink-components';

import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../stores/tags.store';

interface ModalAssignToDeviceProps {
  t: (key: string) => string;
  actions: TagsStoreActions;
  state: TagsStoreState;
}

export const useModalAssignToDevice = (): ModalAssignToDeviceProps => {
  const { t } = useI18n();
  const { actions, state } = useTagsStore();

  return {
    actions,
    state,
    t,
  };
};
