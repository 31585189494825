import { useI18n, useToast } from '@group-link-one/grouplink-components';

import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import {
  DeviceListStoreActions,
  DeviceListStoreState,
  useDeviceListStore,
} from '../store/device-list-store';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import * as Sentry from '@sentry/react';

interface IUseModalDecomission {
  t: (key: string) => string;
  deviceListState: DeviceListStoreState;
  deviceListActions: DeviceListStoreActions;
  onCancel: () => void;
  onDecomission: () => Promise<void | unknown>;
}

export const useModalDecomission = (): IUseModalDecomission => {
  const { logEventAnalytics } = useFBAnalytics();
  const { state: deviceListState, actions: deviceListActions } =
    useDeviceListStore();

  const { t } = useI18n();

  const { deviceDecomission } = useDeviceListService();
  const { addToast } = useToast();

  function onCancel(): void {
    deviceListActions.setModalDecomissionIsOpen(false);
  }

  async function onDecomission(): Promise<void | unknown> {
    if (!deviceListState.deviceId) {
      return;
    }

    try {
      deviceListActions.setIsDecomissionLoading(true);
      await deviceDecomission(deviceListState.deviceId);

      addToast({
        title: t('deviceList.decomissionModal.onSuccess.title'),
        message: t('deviceList.decomissionModal.onSuccess.description'),
      });

      logEventAnalytics({
        eventName: FBAnalyticsEventTitles.DEVICE_LIST_DECOMISSION,
        eventDescription: 'End Decomission Device',
      });

      onCancel();
    } catch (error) {
      Sentry.captureException(error);
      addToast({
        title: t('deviceList.decomissionModal.onError.title'),
        message: t('deviceList.decomissionModal.onError.description'),
        type: 'error',
      });

      return error;
    } finally {
      deviceListActions.setIsDecomissionLoading(false);
    }
  }

  return {
    t,
    deviceListState,
    deviceListActions,
    onCancel,
    onDecomission,
  };
};
