import {
  GLGroupsDevice,
  IGroupsDevice,
} from '@group-link-one/grouplink-components';

import { AddDevicesBody } from './AddDevicesBody/AddDevicesBody';
import { useGroupListBody } from './useGroupListBody';

export function GroupListBody(): JSX.Element {
  const {
    groupList,
    showFeature,
    features,
    user,
    groupsDevicesState,
    deleteMutation,
    addDevicesToGroup,
    createMutation,
    OPTIONS,
    updateMutation,
  } = useGroupListBody();

  return (
    <GLGroupsDevice
      groups={groupList || []}
      user={user}
      flagsOptions={{
        showEdit: features.deviceGroup.showEdit,
        showManage: features.deviceGroup.showManage,
        showDelete: features.deviceGroup.showDelete,
      }}
      onCreateGroup={async (data) => {
        await createMutation.mutateAsync(data);
      }}
      onDeleteGroupById={(data) => deleteMutation.mutateAsync(data)}
      onListGroups={async () => {}}
      onUpdateGroup={(id, data) => updateMutation.mutateAsync({ id, data })}
      onSaveDevicesOnGroup={async (group: IGroupsDevice) => {
        addDevicesToGroup({
          id: Number(group.id),
          data: {
            device_ids: groupsDevicesState.devices_ids.map(Number),
          },
        });
      }}
      hasPermissionToCreate={showFeature('action_btn_create_new_group')}
      coordinatorOptions={OPTIONS}
      transferListContent={<AddDevicesBody />}
      viewerOptions={OPTIONS}
    />
  );
}
