import { GLSettingsOverview } from '@group-link-one/grouplink-components';

import { useOverview } from './useOverview';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import { SettingsRoutes } from '@/layouts/usePageLayout';

export const Overview = (): JSX.Element => {
  const { navigate, showFeature, logEventAnalytics, user } = useOverview();

  return (
    <GLSettingsOverview
      hasPrivileges={showFeature('show_settings_overview_apps_card')}
      user={user!}
      count={String(0)}
      editProfileButtonAction={() => {
        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.OVERVIEW_REDIRECT_TO_MY_ACCOUNT,
          eventDescription: 'Redirect to My Account',
        });
        navigate(SettingsRoutes.MY_ACCOUNT);
      }}
      navigateToApps={() => {}}
      navigateToLoginSecurity={() => {
        logEventAnalytics({
          eventName: FBAnalyticsEventTitles.OVERVIEW_REDIRECT_TO_LOGIN_SECURITY,
          eventDescription: 'Redirect to Login Security',
        });
        navigate(SettingsRoutes.LOGIN_SECURITY);
      }}
    />
  );
};
