import { useAuth } from '@group-link-one/gl-utils';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { useFirestore } from '../Context/FBFirestore/FBFirestoreProvider';
import { env } from '../env';

interface CallbackToModalLogout {
  callbackFnToModalLogout: () => void;
}

export function useCallbackToModalLogout(): CallbackToModalLogout {
  const navigate = useNavigate();
  const { clear } = useAuth();
  const { resetAllUsersStoraged } = useFirestore();

  function callbackFnToModalLogout(): void {

    localStorage.removeItem(`@utilities-${env.VITE_NODE_ENV}`);

    if (resetAllUsersStoraged) {
      resetAllUsersStoraged();
    }
    navigate('/login');
    localStorage.removeItem('isImpersonated');

    clear()

  }

  return {
    callbackFnToModalLogout,
  };
}
