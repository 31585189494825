import {
  GL_COLORS,
  GL_PENCIL,
  GL_SEARCH,
  GL_TAG,
  GL_TRASHCAN,
  GLBox,
  GLButton,
  GLCardTemplate,
  GLCircleButton,
  GLDefaultColumn,
  GLTable,
  GLTextField,
  GLTypography,
} from '@group-link-one/grouplink-components';
import { AnimatePresence } from 'framer-motion';

import { If } from '../../../../utils/GLStatement/If';
import {
  ANIMATION_CONTENT_DELAY,
  ButtonStyled,
  TagCardContainer,
  TagContentContainer,
  WIDTH_AFTER_ELEMENT,
} from './ModalAssignValuesToTagContentStyle';
import { useModalAssignValuesToTagContent } from './useModalAssignValuesToTagContent';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';

export function ModalAssignValuesToTagContent(): JSX.Element {
  const {
    handleSubmit,
    tableContentHeight,
    refParentTable,
    register,
    errors,
    tags,
    tagsStoreState,
    tagColumnsTable,
    tagValues,
    onManageTagClick,
    onSubmitTagValue,
    onEditTagValue,
    onDeleteTagValue,
    showFeature,
    logEventAnalytics,
    inputSearchRef,
    t,
  } = useModalAssignValuesToTagContent();

  return (
    <GLBox
      height="100%"
      gap={0}
      justify="space-between"
      style={{
        marginTop: 30,
      }}
    >
      <GLBox direction="column" width="30%" height="100%">
        <GLTextField
          icon={<GL_SEARCH fill={GL_COLORS.FONT_COLOR_DARK_GREY} />}
          placeholder={t('tags.content.modal.manageTag.search')}
          ref={inputSearchRef}
        />

        <GLBox
          direction="column"
          height="100%"
          hasOverflowY
          width={`calc(100% + ${WIDTH_AFTER_ELEMENT}px)`}
          style={{
            marginTop: 20,
            scrollbarWidth: 'none',
            paddingRight: 20,
          }}
          gap={10}
        >
          {tags?.map((tag) => (
            <GLCardTemplate.Provider key={tag.id}>
              <TagCardContainer
                style={{ width: `calc(100% - ${WIDTH_AFTER_ELEMENT / 2}px)` }}
                className={
                  tagsStoreState?.activeTagId === tag.id
                    ? 'tag-card-active'
                    : ''
                }
                whileHover={{ scale: 1 }}
              >
                <GLCardTemplate.Header
                  handleActionOnClick={() => onManageTagClick(tag)}
                >
                  <GLBox
                    animate={{
                      width: tagsStoreState?.activeTagId === tag.id ? 4 : 0,
                    }}
                    width={0}
                    height="97%"
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: '0.5px',
                      borderRadius: 12,
                      backgroundColor: GL_COLORS.ACCENT_COLOR,
                    }}
                  />

                  <GLCircleButton
                    bgColor={GL_COLORS.PURPLE}
                    text={<GL_TAG fill={GL_COLORS.WHITE} />}
                  />

                  <GLDefaultColumn
                    title={t('tags.content.modal.manageTag.card.keyName')}
                    value={tag.key || ''}
                  />

                  <GLDefaultColumn
                    title={t('tags.content.modal.manageTag.card.label')}
                    value={tag.label}
                  />
                </GLCardTemplate.Header>
              </TagCardContainer>
            </GLCardTemplate.Provider>
          ))}
        </GLBox>
      </GLBox>

      <AnimatePresence mode="wait">
        {tagsStoreState.activeTagId && (
          <TagContentContainer
            key={`${tagsStoreState.activeTagId}-tag-values`}
            direction="column"
            height="100%"
            width="calc(70% - 40px)"
            className={tagsStoreState.activeTagId ? 'tag-content-active' : ''}
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: {
                duration: 0.6,
                delay: ANIMATION_CONTENT_DELAY,
                ease: [0.42, 0, 0.58, 1], // Cubic bezier for smooth animation
              },
            }}
            exit={{ opacity: 0 }}
          >
            <GLBox
              direction="column"
              height="100%"
              gap={30}
              style={{
                padding: 40,
                position: 'relative',
                zIndex: 1,
                backgroundColor: GL_COLORS.BACKGROUND_SECONDARY,
              }}
            >
              <GLDefaultColumn
                title={t(
                  'tags.content.modal.manageTag.valuesContent.form.label',
                )}
                value={''}
                valueFontSize={7}
                valueWeight={600}
              />

              {showFeature('show_form_create_value_to_tag') && (
                <form onSubmit={handleSubmit(onSubmitTagValue)}>
                  <GLBox>
                    <GLBox width={350}>
                      <GLTextField
                        placeholder={t(
                          'tags.content.modal.manageTag.valuesContent.form.search',
                        )}
                        failure={!!errors.tagValue?.message}
                        {...register('tagValue')}
                      />
                    </GLBox>

                    <GLButton
                      align="center"
                      text={t(
                        'tags.content.modal.manageTag.valuesContent.form.btnAdd',
                      )}
                      variant="fill"
                      weight={600}
                      type="submit"
                      style={{
                        width: 'fit-content',
                        paddingInline: 30,
                      }}
                    />
                  </GLBox>
                </form>
              )}

              <GLBox ref={refParentTable} height="100%">
                <GLTable
                  columns={tagColumnsTable}
                  cardContainerStyle={{
                    maxHeight: tableContentHeight,
                  }}
                  content={
                    <>
                      <If
                        condition={!!tagValues && tagValues.values.length > 0}
                      >
                        <GLBox direction="column" gap={0}>
                          {tagValues?.values?.map((tagValue) => (
                            <GLCardTemplate.Provider key={tagValue.id}>
                              <GLCardTemplate.Root
                                borderRadius="0px"
                                style={{ width: '100%' }}
                                whileHover={{ scale: 1 }}
                              >
                                <GLCardTemplate.Header>
                                  <GLCardTemplate.Column
                                    width={20}
                                    align="flex-start"
                                    justify="flex-start"
                                  >
                                    <GLTypography
                                      text={tagValue.value || ''}
                                      fontSize={4}
                                      weight={400}
                                    />
                                  </GLCardTemplate.Column>

                                  <GLCardTemplate.Actions
                                    absolute
                                    width={20}
                                    disappearOnHover={false}
                                    showOnHover={false}
                                    justify="flex-end"
                                    style={{
                                      right: 20,
                                    }}
                                  >
                                    {showFeature(
                                      'show_btn_edit_device_value',
                                    ) && (
                                      <ButtonStyled
                                        onClick={() => {
                                          logEventAnalytics({
                                            eventName:
                                              FBAnalyticsEventTitles.TAGS_MANAGE_UPDATE_VALUE,
                                            eventDescription:
                                              'Start Edit tag value',
                                          });
                                          onEditTagValue(tagValue);
                                        }}
                                      >
                                        <GL_PENCIL
                                          fill={GL_COLORS.ACCENT_COLOR}
                                          size={16}
                                        />
                                      </ButtonStyled>
                                    )}

                                    {showFeature(
                                      'show_btn_delete_device_value',
                                    ) && (
                                      <ButtonStyled
                                        hoverColor={
                                          GL_COLORS.HOVER_COLOR_DANGER
                                        }
                                        onClick={() => {
                                          logEventAnalytics({
                                            eventName:
                                              FBAnalyticsEventTitles.TAGS_MANAGE_DELETE_VALUE,
                                            eventDescription:
                                              'Start Delete tag value',
                                          });
                                          onDeleteTagValue(tagValue);
                                        }}
                                      >
                                        <GL_TRASHCAN
                                          fill={GL_COLORS.DANGER}
                                          size={16}
                                        />
                                      </ButtonStyled>
                                    )}
                                  </GLCardTemplate.Actions>
                                </GLCardTemplate.Header>
                              </GLCardTemplate.Root>
                            </GLCardTemplate.Provider>
                          ))}
                        </GLBox>
                      </If>

                      <If
                        condition={!!tagValues && tagValues.values.length === 0}
                      >
                        <GLBox
                          direction="column"
                          gap={10}
                          align="center"
                          justify="center"
                          height="100%"
                        >
                          <GLTypography
                            text="No values to show"
                            fontSize={6}
                            weight={600}
                          />
                        </GLBox>
                      </If>
                    </>
                  }
                />
              </GLBox>
            </GLBox>

            <div className="tags-content-overlay left"></div>
            <div className="tags-content-overlay right"></div>
          </TagContentContainer>
        )}
      </AnimatePresence>
    </GLBox>
  );
}
