import { GLBox, GLTextSwitcher } from '@group-link-one/grouplink-components';
import { AnimatePresence } from 'framer-motion';

import { useAddDevicesBody } from './useAddDevicesBody';

export function AddDevicesBody(): JSX.Element {
  const {
    tabOptions,
    tabActive,
    contentToShowByTab,
    setTabActive,
    isToShowCsvTransfer
  } = useAddDevicesBody();

  return (
    <GLBox direction="column">
      {isToShowCsvTransfer && (
        <GLTextSwitcher
          options={tabOptions}
          optionDefault={0}
          onSelect={(id) => setTabActive(id)}
        />
      )}

      <AnimatePresence mode="wait">
        {contentToShowByTab[`tab-${tabActive}`] && (
          <GLBox
            key={`tab-${tabActive}`}
            initial={{
              opacity: 0,
              x: tabActive === 1 ? '-100%' : '100%',
            }}
            animate={{ opacity: 1, x: 0 }}
            exit={{
              opacity: 0,
              x: tabActive === 1 ? '-100%' : '100%',
            }}
            transition={{
              duration: 0.6,
            }}
          >
            {contentToShowByTab[`tab-${tabActive}`]}
          </GLBox>
        )}
      </AnimatePresence>
    </GLBox>
  );
}
