import { useAuth } from '@group-link-one/gl-utils';
import { GLLoadingPage } from '@group-link-one/grouplink-components';
import { Navigate } from 'react-router-dom';

import { useFirestore } from '../Context/FBFirestore/FBFirestoreProvider';
import { useGLFlags } from '@group-link-one/gl-utils';

interface VerifyAuthenticatedRouteProps {
  needAuth?: boolean;
  element: React.ReactElement;
  userHasPrivileges?: boolean;
}

export const VerifyRoute = ({
  needAuth,
  userHasPrivileges,
  element,
}: VerifyAuthenticatedRouteProps): JSX.Element => {
  const { accessToken, isPending, storeIsReady } = useAuth();
  const { getUserByEmail } = useFirestore();
  const { flagsAlreadyLoaded, user } = useGLFlags();

  const isAccessTokenMissing = !accessToken;
  const isLoading = isPending || !flagsAlreadyLoaded || !getUserByEmail() || !user || !storeIsReady;

  if (needAuth && isAccessTokenMissing && storeIsReady) return <Navigate to="/" replace />;
  if (needAuth && isLoading) return <GLLoadingPage />;

  if (needAuth && userHasPrivileges !== true)
    return <Navigate to="/" replace />;

  if (!needAuth && !isAccessTokenMissing) {
    return <Navigate to="/" replace />;
  }

  return element;
};
