import { useI18n } from '@group-link-one/grouplink-components';
import {
  TagsStoreActions,
  TagsStoreState,
  useTagsStore,
} from '../stores/tags.store';

interface IModalCreateTag {
  actions: TagsStoreActions;
  state: TagsStoreState;
  verifyButtonText: () => string;
}

export const useModalCreateTag = (): IModalCreateTag => {
  const { t } = useI18n();
  const { actions, state } = useTagsStore();

  function verifyButtonText(): string {
    return state.modalType === 'creation'
      ? t('tags.content.modal.createNewTag.title')
      : t('tags.content.modal.editTag.title');
  }

  return {
    actions,
    state,
    verifyButtonText,
  };
};
