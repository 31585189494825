import { GLDetailedModal } from '@group-link-one/grouplink-components';

import { ModalAssignValuesToTagContent } from './Content/ModalAssignValuesToTagContent';
import { useModalAssignValuesToTag } from './useModalAssignValuesToTag';

export function ModalAssignValuesToTag(): JSX.Element {
  const { state, actions, t } = useModalAssignValuesToTag();

  return (
    <GLDetailedModal
      open={state.modalAssignValuesToTagIsOpen}
      // open={true}
      title={t('tags.content.modal.manageTag.title')}
      width="100%"
      heigth="100%"
      alignItems="flex-start"
      justifyContent="flex-start"
      onClose={() => {
        actions.setModalAssignValuesToTagIsOpen(false);
        actions.reset();
      }}
      content={<ModalAssignValuesToTagContent />}
    />
  );
}
