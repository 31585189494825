import { GLMainContent } from '@group-link-one/grouplink-components';

import { TagsContent } from './Content/TagsContent';
import { ModalAssignValuesToTag } from './GLModalAssignValuesToTag/ModalAssignValuesToTag';
import { ModalDeleteValue } from './GLModalAssignValuesToTag/ModalDeleteValue/ModalDeleteValue';
import { ModalEditValue } from './GLModalAssignValuesToTag/ModalEditValue/ModalEditValue';
import { ModalAssignToDevice } from './ModalAssignToDevice/ModalAssignToDevice';
import { ModalCreateTag } from './ModalCreateTag/ModalCreateTag';
import { ModalDeleteTag } from './ModalDeleteTag/ModalDeleteTag';
import { TagsRightContent } from './RightContent/TagsRightContent';
import { useTags } from './useTags';

export const Tags = (): JSX.Element => {
  const { t } = useTags();

  return (
    <>
      <GLMainContent
        subtitle={t('tags.title')}
        title={t('tags.subtitle')}
        content={<TagsContent />}
        footerText=""
        hasHeaderContent
        hasList
        isOnTopHeaderContent
        rightContent={<TagsRightContent />}
      />

      <ModalAssignToDevice />
      <ModalAssignValuesToTag />
      <ModalCreateTag />
      <ModalDeleteTag />
      <ModalEditValue />
      <ModalDeleteValue />
    </>
  );
};
