import { GLLoadingPage } from '@group-link-one/grouplink-components';
import { useEffect } from 'react';

import { useCheckStatus } from './useCheckStatus';

export const CheckStatus = (): JSX.Element => {
  const { sendCheckStatus } = useCheckStatus();

  useEffect(() => {
    sendCheckStatus();
  }, []);

  return <GLLoadingPage />;
};
