import { GLBox, GLButton, GLModal } from '@group-link-one/grouplink-components';

import { useModalRemoveValue } from './useModalDeleteValue';

export const ModalDeleteValue = (): JSX.Element => {
  const { t, tagsStoreActions, tagsStoreState, onRemoveValue } =
    useModalRemoveValue();

  return (
    <GLModal
      open={!!tagsStoreState.activeTagValueToDelete}
      title={t(
        'tags.content.modal.manageTag.valuesContent.table.card.actionModal.delete.title',
      )}
      description={t(
        'tags.content.modal.manageTag.valuesContent.table.card.actionModal.delete.description',
      )}
      hasButtonClose
      type="remove"
      hasOutsideClick
      maxWidth="500px"
      onClose={() => tagsStoreActions.setActiveTagValueToDelete(undefined)}
      content={
        <GLBox>
          <GLButton
            text={t('common.cancel')}
            variant="outline"
            size="primary"
            align="center"
            border
            onClick={() =>
              tagsStoreActions.setActiveTagValueToDelete(undefined)
            }
          />
          <GLButton
            text={`${t('common.yes')}, ${t('common.delete')}`}
            variant="danger_fill"
            weight={600}
            size="primary"
            align="center"
            border={false}
            onClick={onRemoveValue}
            // isLoading={deviceListState.isDecomissionLoading}
            // disabled={deviceListState.isDecomissionLoading}
            // onClick={onDecomission}
          />
        </GLBox>
      }
    />
  );
};
