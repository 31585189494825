import { useAuth } from '@group-link-one/gl-utils';
import {
  useEventListStore,
  useGLModalLogout,
} from '@group-link-one/grouplink-components';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';

import { useFirestore } from '../../Context/FBFirestore/FBFirestoreProvider';
import useAxiosHTTPRequest from '../useAxiosHTTPRequest';
import * as Sentry from '@sentry/react';

interface ICheckStatusService {
  status: string;
  access_token: string;
}

type logOutFn = {
  session_id?: string | undefined;
};

interface IAuthServices {
  logOut: ({ session_id }: logOutFn) => Promise<void>;
  checkStatus: (tmp_session: string) => Promise<ICheckStatusService>;
}

export const useAuthServices = (): IAuthServices => {
  const { handleOpenModalLogout } = useGLModalLogout();
  const { http } = useAxiosHTTPRequest();
  const { logOut, accessToken } = useAuth();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { actions: eventListActions } = useEventListStore();

  const { resetAllUsersStoraged } = useFirestore();

  async function handleLogout(accessTokenParam?: string): Promise<void> {
    localStorage.removeItem('isImpersonated');

    try {
      if (!accessTokenParam) {
        return;
      }
      await logOut('');

      resetAllUsersStoraged();
      queryClient.clear();
      eventListActions.resetAll();
      navigate('/');
    } catch (error) {
      Sentry.captureException(error);
      if (error instanceof AxiosError) {
        if (error.response?.status === 401) {
          handleOpenModalLogout();
        }
      }
    }
  }

  async function checkStatus(
    tmp_session: string,
  ): Promise<ICheckStatusService> {
    return await http.get('/oauth2/check-status', {
      params: {
        tmp_session,
      },
    });
  }

  const { mutateAsync: logoutFn } = useMutation({
    mutationFn: async ({ session_id }: logOutFn): Promise<void> => {
      if (!session_id) {
        return handleLogout(accessToken);
      }
      handleLogout(session_id);
    },
  });

  return {
    logOut: logoutFn,
    checkStatus,
  };
};
