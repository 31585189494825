import {
  formatDateDistanceText,
  Notification,
  NotificationType,
  useActiveLanguageDateFns,
} from '@group-link-one/grouplink-components';
import { useEffect, useState } from 'react';

import { useFirestore } from '../Context/FBFirestore/FBFirestoreProvider';

interface NotificationDocument {
  created_at: {
    seconds: number;
    nanoseconds: number;
  };
  title: string;
  description: string;
  type: NotificationType;
  is_active: boolean;
}

interface FormatNotifications {
  id: string;
  titleOptions: {
    text: string;
  };
  createdAtMiddle: string;
  description: {
    text: string;
  };
  type: NotificationType;
}

interface UseNotifications {
  allNotifications: Notification[];
  fetchNotifications: () => Promise<void>;
}

export const useNotifications = (): UseNotifications => {
  const { getDocument } = useFirestore();
  const [allNotifications, setAllNotifications] = useState<Notification[]>([]);
  const languageActive = useActiveLanguageDateFns();

  function filterNotificationsActive(
    notification: NotificationDocument,
  ): boolean {
    return notification.is_active;
  }

  function formatNotifications(
    notification: NotificationDocument,
  ): FormatNotifications {
    return {
      id: `${notification.created_at.seconds}${notification.created_at.nanoseconds}`,
      titleOptions: {
        text: notification.title,
      },
      createdAtMiddle: formatDateDistanceText(
        new Date(notification.created_at.seconds * 1000).toString(),
        languageActive,
      ),
      description: {
        text: notification.description,
      },
      type: notification.type,
    };
  }

  const fetchNotifications = async (): Promise<void> => {
    const notifications =
      await getDocument<NotificationDocument>('notifications');

    const formattedNotifications: Notification[] = notifications
      .filter(filterNotificationsActive)
      .map(formatNotifications);

    setAllNotifications(formattedNotifications);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  return {
    allNotifications,
    fetchNotifications,
  };
};
