import { AuditRow, GLCard } from '@group-link-one/grouplink-components';

import { useAuditCard } from './useAuditCard';

interface AuditCardProps {
  item: AuditRow;
}

export const AuditCard = ({ item }: AuditCardProps): JSX.Element => {
  const { translatingCategory, isMobile } = useAuditCard();

  return (
    <GLCard
      variant="audit-card"
      isMobile={isMobile}
      authorName={item.customer.name}
      authorEmail={item.customer.email}
      created_at={item.created_at}
      category={translatingCategory(item.category)}
      action={item.message}
      key={item.id}
    />
  );
};
