import { useAuth, useGLFlags } from '@group-link-one/gl-utils';
import {
  AlertType,
  GL_COLORS,
  GLSelectMultipleOptions,
  useHandleFilterDeviceGroup,
  useI18n,
} from '@group-link-one/grouplink-components';
import { useJsApiLoader } from '@react-google-maps/api';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { format } from 'date-fns';
import { subHours } from 'date-fns/subHours';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounceCallback, useMediaQuery } from 'usehooks-ts';

import { env } from '../../../env';
import { useDeviceListService } from '../../../Services/deviceListService/useDeviceListService';
import {
  Application,
  DeviceMapResponse,
  GetDeviceMapParams,
  GetDevicesActivatedLastReadingsResponse,
} from '../../../Services/deviceListService/useDeviceListService.types';
import { useThemeActiveStore } from '../../../store/theme';
import { mapDarkStyles, mapStyles } from '../../../utils/mapStyles';
import {
  HealthCheckStoreActions,
  HealthCheckStoreState,
  useHealthCheckStore,
} from '../store/health-check-store';
import { ClustererOptions } from '@react-google-maps/marker-clusterer';

import ClusterIcon from '@/images/HealthCheck/cluster-icon';
import { getOptionsByApplication } from '@/Pages/DeviceList/utils/getOptionsByApplication';
import { UseCase } from '@/Pages/EventList/Content/Columns/AllColumns';
import { useFBAnalytics } from '@/Context/FBAnalytics/FBAnalyticsProvider';
import { FBAnalyticsEventTitles } from '@/Context/FBAnalytics/types/FBAnalyticsTitles.types';
import * as Sentry from '@sentry/react';
import { useTagsService } from '@/Services/tagsService/useTagsService';

const center = {
  lat: -23.5932056,
  lng: -46.6780125,
};

const clustererStylesDefault = {
  textColor: '#fff',
  textSize: 16,
  fontFamily: 'Figtree',
  fontWeight: 'semi-bold',
};

interface IUseHealthCheckMap {
  t: (key: string) => string;
  center: { lat: number; lng: number };
  map: google.maps.Map | null;
  groupsChildrensAvailables: GLSelectMultipleOptions[] | undefined;
  clustererOptions: ClustererOptions;
  deviceSelected: GetDevicesActivatedLastReadingsResponse | undefined;
  currentDevices: DeviceMapResponse[] | undefined;
  markerIcon: google.maps.Icon | undefined;
  mapStyle: google.maps.MapTypeStyle[];
  healthCheckState: HealthCheckStoreState;
  healthCheckActions: HealthCheckStoreActions;
  isLoaded: boolean;
  isMobile: boolean;
  onLoad: (mapInstance: google.maps.Map) => void;
  onBoundsChanged: () => void;
  onUnmount: () => void;
  userGroupsFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectUserGroup: (userGroupsSelecteds: GLSelectMultipleOptions[], type?: 'parent' | 'child') => void;
  tagOptionsFormatted: GLSelectMultipleOptions[] | undefined
  onSelectTag: (tagSelecteds: GLSelectMultipleOptions[]) => void;
  tagValuesFormatted: GLSelectMultipleOptions[] | undefined;
  onSelectTagValue: (tagValueSelecteds: GLSelectMultipleOptions[]) => void;
}

export const useHealthCheckMap = (): IUseHealthCheckMap => {
  const { logEventAnalytics } = useFBAnalytics();
  const { userGroups } = useAuth();
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: env.VITE_REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const { state: themeActiveState, actions: themeActiveActions } =
    useThemeActiveStore();

  const [groupsChildrensAvailables, setGroupsChildrensAvailables] =
    useState<GLSelectMultipleOptions[] | undefined>(undefined);

  const isMobile = useMediaQuery('(max-width: 1024px)');

  const [mapStyle, setMapStyle] = useState(
    themeActiveState.isDarkMode ? mapDarkStyles : mapStyles,
  );
  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [markerIcon, setMarkerIcon] = useState<google.maps.Icon | undefined>(
    undefined,
  );
  const [userLocation, setUserLocation] = useState<{
    lat: number;
    lng: number;
  } | null>(null);

  const { getTags, getTagValue } = useTagsService();
  const { getDeviceMap } = useDeviceListService();
  const queryClient = useQueryClient();
  const { t } = useI18n();
  const { showFeature } = useGLFlags();
  const { user } = useAuth();

  const { state: healthCheckState, actions: healthCheckActions } =
    useHealthCheckStore();

  const { formatGroupList, onSelectUserGroup: onHandleSelectUserDeviceGroup } = useHandleFilterDeviceGroup();

  const { data: tags } = useQuery({
    queryKey: ['get-tags'],
    queryFn: async () => {
      if (!showFeature('show_device_tags_page')) return;
      const response = await getTags({
        key: undefined,
      });

      return response.rows;
    },
  });

  const { data: tagValues } = useQuery({
    queryKey: ['get-tags-values', healthCheckState.tagSelecteds],
    queryFn: async () => {
      if (!healthCheckState.tagSelecteds) return;

      const response = await getTagValue({
        key: healthCheckState.tagSelecteds[0].id,
      });

      return response.values;
    },
  });

  const onLoad = useCallback(
    function callback(mapInstance: google.maps.Map) {
      setMap(mapInstance);

      const svgIcon = {
        url:
          'data:image/svg+xml;utf-8,' +
          encodeURIComponent(`
          <svg width="36" height="36" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="18" fill="#00FFAA" stroke="white" stroke-width="2"/>
          </svg>
        `),
        scaledSize: new window.google.maps.Size(22, 22),
      };

      setMarkerIcon(svgIcon);
    },
    [center],
  );

  const generateClusterIcon = (): string => {
    const applicationByUseCase: Record<UseCase, Application> = {
      util_kwh: "GLUtilitiesEnergy",
      util_light: "GLUtilitiesLight",
      util_water: "GLUtilitiesWater",
    }

    const options = getOptionsByApplication({
      type: applicationByUseCase[user?.use_case as UseCase],
    });

    if (!options) {
      return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
        ClusterIcon(GL_COLORS.ACCENT_COLOR),
      )}`;
    }

    return `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(
      ClusterIcon(options.color),
    )}`;
  };

  const onUnmount = useCallback(function callback() {
    setMap(null);
  }, []);

  const from = useMemo(() => {
    const startDate = subHours(new Date(), 72);
    return format(startDate, 'yyyy-MM-dd') + 'T00:00:00.00Z';
  }, []);

  const until = useMemo(() => {
    const endDate = new Date();
    return format(endDate, 'yyyy-MM-dd') + 'T23:59:59.00Z';
  }, []);

  const tagOptionSelected: string | undefined = useMemo(() => {
    if (!healthCheckState?.tagSelecteds) {
      return undefined;
    }
    return healthCheckState?.tagSelecteds[0]?.text;
  }, [healthCheckState?.tagSelecteds]);

  const tagValueOptionSelected: string | undefined = useMemo(() => {
    if (!healthCheckState?.tagValuesSelecteds) {
      return undefined;
    }
    return healthCheckState?.tagValuesSelecteds[0]?.text;
  }, [healthCheckState?.tagValuesSelecteds]);

  const alertsOnly = useMemo(() => {
    if (healthCheckState.alertFilterValue?.length === 0) {
      return undefined;
    }

    if (healthCheckState.alertFilterValue?.length === 1) {
      if (healthCheckState.alertFilterValue[0].id === 'with-alert') {
        logEventAnalytics({
          eventName:
            FBAnalyticsEventTitles.HEALTH_CHECK_MAP_FILTER_BY_DEVICES_WITH_ALERTS,
          eventDescription: 'Health check map filter by devices with alerts',
          param1: JSON.stringify({ withAlerts: true }),
        });
        return true;
      }
      if (healthCheckState.alertFilterValue[0].id === 'without-alert') {
        logEventAnalytics({
          eventName:
            FBAnalyticsEventTitles.HEALTH_CHECK_MAP_FILTER_BY_DEVICES_WITH_ALERTS,
          eventDescription: 'Health check map filter by devices with alerts',
          param1: JSON.stringify({ withAlerts: false }),
        });
        return false;
      }
    }

    return undefined;
  }, [healthCheckState.alertFilterValue]);

  const alerts = useMemo(() => {
    if (healthCheckState.alertTypesSelected?.length === 0) {
      return undefined;
    }

    return healthCheckState.alertTypesSelected?.map((alert) => alert.id);
  }, [healthCheckState.alertTypesSelected]);

  function isGeoFilterCached(
    geo_filter: HealthCheckStoreState['geo_filter'],
    userGroupSelected: number[] | undefined,
    tagOptionSelected: string | undefined,
    tagValueOptionSelected: string | undefined,
  ): false | DeviceMapResponse[] {
    const allDevicesCached = queryClient.getQueriesData<DeviceMapResponse[]>({
      queryKey: [
        'activateds-devices',
        from,
        until,
        alertsOnly,
        alerts,
        userGroupSelected,
        tagOptionSelected,
        tagValueOptionSelected
      ],
    });

    if (!allDevicesCached || !geo_filter) {
      return false;
    }

    const devicesFiltered = allDevicesCached.find(([queryKey]) => {
      const geo_filter_cached =
        queryKey[6] as HealthCheckStoreState['geo_filter'];

      if (!geo_filter_cached) {
        return false;
      }

      const isInside =
        geo_filter_cached.min_lat <= geo_filter.min_lat &&
        geo_filter_cached.max_lat >= geo_filter.max_lat &&
        geo_filter_cached.min_long <= geo_filter.min_long &&
        geo_filter_cached.max_long >= geo_filter.max_long;

      return isInside;
    });

    if (devicesFiltered && devicesFiltered[1]) {
      if (userGroupSelected && userGroupSelected.length > 0) {
        const devicesFilteredByGroup = devicesFiltered[1].filter((device) => {
          return device.groups.some((group) =>
            userGroupSelected.includes(group),
          );
        });

        return devicesFilteredByGroup;
      }

      return devicesFiltered[1];
    }

    return false;
  }

  const userGroupSelected: number[] | undefined = useMemo(() => {
    let userGroupsId: number[] | undefined = undefined;

    if (healthCheckState.userParentGroupSelecteds && healthCheckState.userParentGroupSelecteds?.length > 0) {
      userGroupsId = healthCheckState?.userParentGroupSelecteds?.map(
        (userGroup) => Number(userGroup.id),
      );
    }

    if (healthCheckState.userGroupSelecteds && healthCheckState.userGroupSelecteds?.length > 0) {
      userGroupsId = healthCheckState?.userGroupSelecteds?.map(
        (userGroup) => Number(userGroup.id),
      );
    }

    logEventAnalytics({
      eventName: FBAnalyticsEventTitles.HEALTH_CHECK_MAP_FILTER_BY_GROUPS,
      eventDescription: 'Health check map filter by groups',
      param1: JSON.stringify(healthCheckState?.userGroupSelecteds),
    });
    return userGroupsId;
  }, [healthCheckState?.userGroupSelecteds, healthCheckState?.userParentGroupSelecteds]);

  const { data: currentDevices, isLoading: currentDevicesIsLoading } = useQuery<
    DeviceMapResponse[]
  >({
    queryKey: [
      'activateds-devices',
      from,
      until,
      alertsOnly,
      alerts,
      userGroupSelected,
      tagOptionSelected,
      tagValueOptionSelected,
      healthCheckState.geo_filter,
    ],
    queryFn: async () => {
      if (!healthCheckState.geo_filter) {
        return [];
      }

      const devicesCacheds = isGeoFilterCached(
        healthCheckState.geo_filter,
        userGroupSelected,
        tagOptionSelected,
        tagValueOptionSelected
      );

      if (devicesCacheds) {
        return devicesCacheds;
      }

      const params: GetDeviceMapParams = {
        from,
        until,
        alerts_only: alertsOnly,
        alerts: alerts as AlertType[],
        geo_filter: healthCheckState.geo_filter,
      };

      if (userGroupSelected && userGroupSelected.length > 0) {
        params.groups = userGroupSelected;
      }

      if (tagOptionSelected && tagOptionSelected.length > 0) {
        params.tag_key = tagOptionSelected;
      }

      if (tagValueOptionSelected && tagValueOptionSelected.length > 0) {
        params.tag_value = tagValueOptionSelected;
      }

      const response = await getDeviceMap(params);

      return response;
    },
  });

  const currentDevicesWithoutDuplicates = useMemo(() => {
    const allDevicesCached = queryClient.getQueriesData<DeviceMapResponse[]>({
      queryKey: [
        'activateds-devices',
        from,
        until,
        alertsOnly,
        alerts,
        userGroupSelected,
        tagOptionSelected,
        tagValueOptionSelected
      ],
    });

    if (!allDevicesCached) {
      return currentDevices;
    }

    const deviceMap = new Map<number, DeviceMapResponse>();

    allDevicesCached.forEach(([, devicesCached]) => {
      devicesCached?.forEach((device) => {
        deviceMap.set(device.device_id, device);
      });
    });

    return Array.from(deviceMap.values());
  }, [currentDevices]);

  const onBoundsChanged = useDebounceCallback(async () => {
    if (!map) {
      return;
    }

    const zoomMap = map.getZoom();

    if (zoomMap && zoomMap < 10) {
      return;
    }

    const boundsVisible = map.getBounds();

    if (boundsVisible) {
      const northEast = boundsVisible.getNorthEast();
      const southWest = boundsVisible.getSouthWest();

      healthCheckActions.setGeoFilter({
        min_lat: southWest.lat(),
        max_lat: northEast.lat(),
        min_long: southWest.lng(),
        max_long: northEast.lng(),
      });
    }
  }, 500);

  const getUserLocation = useCallback(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setUserLocation({ lat: latitude, lng: longitude });
        },
        (error) => {
          Sentry.captureException(error);
        },
      );
    } else {
      Sentry.captureException('Geolocation is not supported by this browser.');
    }
  }, []);

  const clustererOptions: ClustererOptions = useMemo(
    () => ({
      maxZoom: 20,
      styles: [
        ...Array.from({ length: 20 }, (_, i) => i + 1).map((i) => ({
          url: generateClusterIcon(),
          className: 'cluster-icon',
          height: 40 * i,
          width: 40 * i,
          ...clustererStylesDefault,
        })),
      ],
    }),
    [],
  );

  const userGroupsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return formatGroupList(userGroups);
    }, [userGroups]);

    const tagOptionsFormatted: GLSelectMultipleOptions[] | undefined =
    useMemo(() => {
      return (
        tags &&
        (tags.map((tag) => ({
          id: String(tag.key),
          text: tag.label,
        })) as GLSelectMultipleOptions[])
      );
    }, [tags]);

    const tagValuesFormatted: GLSelectMultipleOptions[] | undefined =
      useMemo(() => {
        return (
          tagValues &&
          (tagValues.map((tagValue) => ({
            id: String(tagValue.id),
            text: tagValue.value,
          })) as GLSelectMultipleOptions[])
        );
    }, [tagValues]);

  function onSelectUserGroup(
    userGroupsSelecteds: GLSelectMultipleOptions[],
    type?: 'parent' | 'child',
  ): void {
    onHandleSelectUserDeviceGroup({
      userGroupsSelecteds,
      type,
      setUserGroupSelecteds: healthCheckActions.setUserGroupSelecteds,
      setUserParentGroupsSelecteds: healthCheckActions.setUserParentGroupSelecteds,
      setGroupsChildrensAvailables,
    })
  }

  function onSelectTag(tagSelecteds: GLSelectMultipleOptions[]): void {
    healthCheckActions.setTagSelecteds(tagSelecteds);
  }

  function onSelectTagValue(
    tagValueSelecteds: GLSelectMultipleOptions[],
  ): void {
    healthCheckActions.setTagValuesSelecteds(tagValueSelecteds);
  }

  useEffect(() => {
    if (map) {
      const zoomTimer = setTimeout(() => {
        map.setZoom(10);
      }, 400);
      return () => clearTimeout(zoomTimer);
    }
  }, [map]);

  useEffect(() => {
    setMapStyle(themeActiveState.isDarkMode ? mapDarkStyles : mapStyles);
  }, [themeActiveState.isDarkMode]);

  useEffect(() => {
    themeActiveActions.listenDarkMode();
  }, [themeActiveActions]);

  useEffect(() => {
    if (
      healthCheckState.deviceSelected &&
      map &&
      healthCheckState.deviceModalIsOpen
    ) {
      const latLng = new window.google.maps.LatLng(
        healthCheckState.deviceSelected.meta.latitude,
        healthCheckState.deviceSelected.meta.longitude,
      );
      map.panTo(latLng);

      const currentZoom = map.getZoom();

      const zoomTimer = setTimeout(() => {
        if (currentZoom && currentZoom >= 18) {
          return;
        }

        map.setZoom(18);
      }, 600);
      return () => clearTimeout(zoomTimer);
    }

    // if (healthCheckState.deviceSelected && map && !healthCheckState.deviceModalIsOpen) {
    //   map.setZoom(16);
    // }
  }, [
    healthCheckState.deviceSelected,
    healthCheckState.deviceModalIsOpen,
    map,
  ]);

  useEffect(() => {
    if (map && userLocation) {
      const latLng = new google.maps.LatLng(userLocation.lat, userLocation.lng);
      map.panTo(latLng);
    }
  }, [map, userLocation]);

  useEffect(() => {
    getUserLocation();
  }, []);

  useEffect(() => {
    healthCheckActions.setIsFetchingDevices(currentDevicesIsLoading);
  }, [currentDevicesIsLoading]);

  useEffect(() => {
    if (isMobile) {
      healthCheckActions.setBigNumbersIsOpen(false);
    }
  }, []);

  return {
    t,
    center,
    map,
    clustererOptions,
    groupsChildrensAvailables,
    deviceSelected: healthCheckState.deviceSelected,
    currentDevices: currentDevicesWithoutDuplicates,
    markerIcon,
    mapStyle,
    healthCheckState,
    healthCheckActions,
    isLoaded,
    isMobile,
    onBoundsChanged,
    userGroupsFormatted,
    tagOptionsFormatted,
    tagValuesFormatted,
    onSelectUserGroup,
    onSelectTag,
    onSelectTagValue,
    onLoad,
    onUnmount,
  };
};
