import { useLocation, useNavigate } from 'react-router-dom';

type ExtractURLSearch = {
  getURLParam: (param: string) => string | null;
  setURLParams: (newParams: Record<string, string>) => void;
};

export const useExtractURLSearch = (): ExtractURLSearch => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();

  const getURLParam = (param: string): string | null => {
    const params = new URLSearchParams(search);
    return params.get(param);
  };

  const setURLParams = (newParams: Record<string, string>): void => {
    const params = new URLSearchParams(window.location.search);

    Object.entries(newParams).forEach(([key, value]) => {
      params.set(key, value);
    });

    navigate({ pathname, search: params.toString() });
  };

  return {
    getURLParam,
    setURLParams,
  };
};
