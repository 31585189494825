import { GL_COLORS, GLBox } from '@group-link-one/grouplink-components';
import styled from 'styled-components';

export const DeviceListAllDevicesContainer = styled(GLBox)``;

export const DeviceListEmpty = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  border-radius: 16px;

  gap: 10px;

  flex: 1;

  padding: 20px;

  > div {
    width: 80px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;

    border-radius: 50%;

    background-color: ${GL_COLORS.BACKGROUND_PRIMARY};
  }
`;
