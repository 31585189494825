import { GLBox, GLButton, GLModal } from '@group-link-one/grouplink-components';

import { useModalDeleteTag } from './useModalDeleteTag';

export const ModalDeleteTag = (): JSX.Element => {
  const { state, actions, t, onDelete } = useModalDeleteTag();

  return (
    <GLModal
      title={t('tags.content.modal.deleteTag.title')}
      description={t('tags.content.modal.deleteTag.description')}
      hasButtonClose
      open={state.modalDeleteTagIsOpen}
      content={
        <>
          <GLBox>
            <GLButton
              text={t('common.cancel')}
              variant="outline"
              size="primary"
              align="center"
              weight={600}
              border
              onClick={() => actions.setModalDeleteTagIsOpen(false)}
            />

            <GLButton
              text={t('common.yes') + ', ' + t('common.delete')}
              variant="danger_fill"
              weight={600}
              size="primary"
              align="center"
              border={false}
              //isLoading={deviceListState.isDecomissionLoading}
              //disabled={deviceListState.isDecomissionLoading}
              onClick={() => onDelete()}
            />
          </GLBox>
        </>
      }
      onClose={() => actions.setModalDeleteTagIsOpen(false)}
    />
  );
};
